import React from "react";
import { Box } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Tasks from "../components/Tasks/Tasks";
import { useDispatch } from "react-redux";
import { resetTask } from "../actions";
import NunitoText from "../components/general/NunitoText";
import ReusableButton from "../components/general/ReusableButton";
export default function AddTask() {
  document.title = "Add Task";
  const dispatch = useDispatch();
  return (
    <Box className="wrapperBox">
      <div className="firstRowContainer dashboardAndSelfBtnDiv">
        <ReusableButton
          text="Dashboard"
          fontSize={14}
          bgColor="#F16774"
          height={36}
          width={125}
          br={18}
          to="/dashboard"
          iconType="home"
        />
        <ChevronRightIcon />
        <ReusableButton
          text="Tasks"
          fontSize={14}
          bgColor="#F16774"
          height={36}
          br={18}
          onClick={() => dispatch(resetTask())}
        />
      </div>
      <div className="formContainer">
        <NunitoText
          value="Add Tasks"
          fontSize={40}
          fontWeight={700}
          italic
          color="#144A94"
          align="left"
        />
        <Tasks />
      </div>
    </Box>
  );
}
