import React from 'react'
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import { useState } from 'react'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableTextField from '../general/ReusableTextField'
import { getParticipantData } from '../../functions/getData'
import LightTable from '../table/LightTable'
import { showWarningSwal } from '../../functions/alert'

export default function CustomeElemModal({setOnElm , selected  , id , handleSubmit , title}) {
    // const [partstring , setpartString] = useState("")
    const [index , setIndex] = useState('')
    const [Participant , setParticipant] = useState()
    const [reason , setReason] = useState('')
    const [header, setHeader] = useState([])
    const submit=()=>{
        if(index===''){
            return  showWarningSwal('you must insert an index number')
          }
        if(reason===''){
            return  showWarningSwal('you must insert a reason for elimination')
        }
    handleSubmit(id,[{
        index:index,
        reason:reason
    }], reason , 'custom')
    
    }
    const searchParticipants = ()=>{
        if(index===''){
          return  showWarningSwal('you must insert an index number')
        }
        getParticipantData(index).then(res=>{
            if(res.status===200){
                let tempData = res.data
                let formattedheader = []
                 //mapping header object to convert to array
                for (const key in res.headers) {
                    if (res.headers.hasOwnProperty(key)) {
                     formattedheader.push({id:res.headers[key] , label:key})
                    }
                  }
                  for (const key in res.data) {
                    if (res.data.hasOwnProperty(key)) {
                        if(res.data[key]===null||res.data[key]===''){
                            console.log(res.data[key] , key)
                            tempData[key] = ' '
                        }
                        
                    }
                  }
                  console.log(tempData)
                  //get computed contries
                  setParticipant(tempData)
                  setHeader(formattedheader)
               }
            console.log(res)
        })
    }
    
    return  <Box className='popUpModal horizontalScrollable ' overflowX={'scroll'} style={{ width: '80%' }}>
    <NunitoText value={title} fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
    
    
    <Grid display={'flex'} width={'80%'} margin={'0 auto'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'} marginBottom={'3%'} marginTop={'3%'}> 
        <ReusableTextField  setState={setIndex} state={index} type={'withLabel'} height={60} width={'90%'} fullWidth placeholder={'Enter the index number of the student'}/>
        <ReusableButton  text={'Check'}  fontSize={22} height={60} width={380} bgColor='#5E75C3'  onClick={()=>{searchParticipants()}} />
    </Grid>
   { Participant&&<Grid className='tableContainer' container position={'relative'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        <LightTable insideModal={true} hasPagination={false}  hasSelect={false} selected={[]} selecting={[]} setSelecting={()=>{}} setSelected={()=>{}}  fixed={[]} data={[Participant]} headers={header}/>
        <Grid width={'80%'} marginBottom={'2%'}>
        <ReusableTextField type={'default'} fullWidth height={'200px'} placeholder={'Integrity Issue Description'} setState={setReason} state={reason} width={'100%'} multiline={8} />
        </Grid>
    </Grid>}
    <Grid justifyContent={'space-between'} container width={'80%'} alignSelf={'center'}>
        <ReusableButton  text={'Confirm'}  fontSize={22} height={60} width={300} bgColor='#5E75C3' onClick={()=>{
            submit()
        }} marginBottom={20} />
        <ReusableButton  text={'Cancel'}  fontSize={22} height={60} width={300} bgColor='#E83042' onClick={()=>{
        setOnElm(false)
        }} marginBottom={20} />
    </Grid>
</Box>
}