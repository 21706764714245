import { FaCheckCircle, FaExclamationCircle, FaInfoCircle, FaTimes } from 'react-icons/fa'
import { IoIosWarning } from 'react-icons/io'
export const showNotification = (variant, message, enqueueSnackbar, closeSnackbar) => {
    const iconStyle = { fontSize: 36, marginInline: 12 }
    const colorsAndIcon = {
        success: { color: '#3EC49D', icon: c => <FaCheckCircle style={{ ...iconStyle, color: c }} /> },
        error: { color: '#F3797E', icon: c => <IoIosWarning style={{ ...iconStyle, color: c }} /> },
        warning: { color: '#F3A867', icon: c => <FaExclamationCircle style={{ ...iconStyle, color: c }} /> },
        info: { color: '#67A8F3', icon: c => <FaInfoCircle style={{ ...iconStyle, color: c }} /> },
        default: { color: '#707070', icon: c => <FaInfoCircle style={{ ...iconStyle, color: c }} /> }
    }
    let color = colorsAndIcon[variant]?.color || colorsAndIcon.default.color
    let icon = colorsAndIcon[variant]?.icon(color) || colorsAndIcon.default.icon(color)
    const containerStyle = {
        display: 'flex', justifyContent: 'space-between',
        height: 96, width: 500, borderRadius: 10,
        backgroundColor: '#FFF', boxShadow: '0px 3px 6px rgba(0,0,0,0.16)'
    }
    const divStyle = { display: 'flex', alignItems: 'center' }
    const borderStyle = { borderLeft: `5px solid ${color}`, borderRadius: 3, height: 76, marginLeft: 8 }
    const closeIconStyle = { fontSize: 24, cursor: 'pointer', margin: '8px 8px 0 0' }
    enqueueSnackbar(message, {
        persist: variant === 'warning',
        content: (key, message) => (
            <div id={key} style={containerStyle}>
                <div style={divStyle}>
                    <div style={borderStyle} />
                    {icon}
                    {message}
                </div>
                <FaTimes style={closeIconStyle} onClick={() => closeSnackbar(key)} />
            </div>
        )
    })
}