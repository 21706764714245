import React, { useState } from 'react'
import { Grid, IconButton } from '@mui/material'
import { gradeOptions } from '../functions/general'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import NunitoText from './general/NunitoText'
import ReusableAutocompleteWithID from './general/ReusableAutocompleteWithID'
import ReusableButton from './general/ReusableButton'
import AddPageWrapper from './general/AddPageWrapper'
import TitleBar from './general/TitleBar'
const difficultyOptions = ['easy', 'intermediate', 'hard', 'advanced'].map(d => ({ id: d, name: d }))
const rowContainerStyle = (index, length) => ({
    marginTop: index ? 10 : 40, marginBottom: index === length - 1 ? 40 : 10
})
const iconWrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
const iconStyle = { fontSize: 36, color: '#000', cursor: 'pointer' }
const recommendationContainerStyle = { backgroundColor: '#FFF', border: '1px solid #707070', borderRadius: 16, paddingBlock: 20 }
const gradeOrDifficultyWrapperStyle = {
    display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingInline: '30px 60px'
}
export default function Recommendations({ count, recommendations, update }) {
    const [expand, setExpand] = useState(false)
    const addRecommendation = count => update(count, 'addRecommendation', 'recommendations')
    const removeRecommendation = count => update(count, 'deleteRecommendation', 'recommendations')
    return (
        <>
            <TitleBar title='Recomendations' state={expand} setState={setExpand}>
                <ReusableButton text='Add Recommendation' fontSize={16} bgColor='#5E75C3' marginRight={40}
                    onClick={() => addRecommendation([count, recommendations.length])} iconType='add' allowSpam />
            </TitleBar>
            {expand && <AddPageWrapper>
                {recommendations.map(({ grade, difficulty }, index) => (
                    <Grid key={index} container alignItems="center" style={rowContainerStyle(index, recommendations.length)}>
                        <Grid item xs={0.5} style={iconWrapperStyle}>
                            <IconButton onClick={() => removeRecommendation([count, index])}>
                                <CloseIcon style={iconStyle} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={11}>
                            <Grid container style={recommendationContainerStyle}>
                                <Grid item xs={6} style={gradeOrDifficultyWrapperStyle}>
                                    <NunitoText value='Recommended Grade:' fontSize={20} marginBottom={10}
                                        fontWeight={600} italic />
                                    <ReusableAutocompleteWithID type='default' placeholder='Grade' width='100%' bgColor='#F2F2F2'
                                        state={grade} setState={update}
                                        count={[count, index]} head='recommendationText' target='grade' 
                                        options={gradeOptions.map(g => ({ id: g.id, option: g.name }))} />
                                </Grid>
                                <Grid item xs={6} style={gradeOrDifficultyWrapperStyle}>
                                    <NunitoText value='Recommended Difficulty:' fontSize={20} marginBottom={10}
                                        fontWeight={600} italic />
                                    <ReusableAutocompleteWithID type='default' placeholder='Difficulty' width='100%' bgColor='#F2F2F2'
                                        state={difficulty} setState={update}
                                        count={[count, index]} head='recommendationText' target='difficulty' 
                                        options={difficultyOptions.map(d => ({ id: d.id, option: d.name }))} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={0.5} style={iconWrapperStyle}>
                            <IconButton onClick={() => addRecommendation([count, index + 1])}>
                                <AddIcon style={iconStyle} />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
            </AddPageWrapper>}
        </>
    )
}