import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { Box, Grid } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import AssignMarksTable from '../components/table/AssignMarksTable'
import { useLocation } from 'react-router-dom'
import { makeSureIsArray } from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getDifficultyAndPoints } from '../functions/getData'
import { patchDifficultyAndPoints } from '../functions/patchData'
import { showNotification } from '../functions/snackbar'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ReusableAutocomplete from '../components/general/ReusableAutocomplete'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import Widget from '../components/general/Widget'
// import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
// import { useSnackbar } from 'notistack'
import { isAdmin } from '../functions/checkrole'
import { useSnackbar } from 'notistack'
const massAssignDivStyle = { padding: 10, border: '1px solid #707070', borderRadius: 10, marginRight: 20 }
const divStyle = { display: 'flex', alignItems: 'center', border: '1px solid #707070', borderRadius: 10, padding: 10 }
const massAssignPointsDivStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center' }
const headers = [
    { id: 'name', label: 'Task Name', key: 'name', },
    { id: 'answer_structure', label: 'Open-Ended Task', key: 'address' },
    { id: 'task_difficulty', label: 'Difficulty', key: 'postal' },
    { id: 'section', label: 'Section', key: 'phone' },
    { id: 'task_marks', label: 'Points(Correct)', key: 'province' },
    { id: 'task_wrong', label: 'Points(Wrong)', key: 'email' },
    { id: 'task_blank', label: 'Points(Blank)', key: 'province' },
    { id: 'min_marks', label: 'Minimum Points', key: 'email' },
    { id: 'marks', label: 'Maximum Points', key: 'email' }
]
export default function AssignDifficulty() {
    document.title = 'Assign Difficulty'
    const isMounted = useRef(false)
    const [competitionName, setCompetitionName] = useState('')
    const [competitionID, setCompetitionID] = useState('')
    const [collectionName, setCollectionName] = useState('')
    const [roundName, setRoundName] = useState('')
    const [levelID, setLevelID] = useState('')
    const [levelName, setLevelName] = useState('')
    const [taskTable, setTaskTable] = useState()
    const [massAssignCorrect, setMassAssignCorrect] = useState('')
    const [massAssignWrong, setMassAssignWrong] = useState('')
    const [massAssignBlank, setMassAssignBlank] = useState('')
    const [massAssignMin, setMassAssignMin] = useState('')
    const [massAssignMax, setMassAssignMax] = useState('')
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState([])
    const [search, setSearch] = useState('')
    const [sectionOptions, setSectionOptions] = useState([])
    const [difficulty, setDifficulty] = useState('')
    const [difficultyOptions, setDifficultyOptions] = useState([])
    const [sections, setSections] = useState([])
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    let location = useLocation()
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const fuckLah = (query, competition) => {
        setCompetitionName(competition.competition_name)
        setCompetitionID(competition.id)
        const [competition_id, round_id, level_id] = query
        console.log({ competition_id, round_id, level_id })
        const round = competition.rounds.find(r => r.id === Number(round_id))
        setRoundName(round.name)
        const level = round.levels.find(l => l.id === Number(level_id))
        setLevelID(level.id)
        setLevelName(level.name)
        const collection = level.collections
        setCollectionName(collection.name)
        let arr = []
        let sectionsArr = []
        for (let i = 0; i < collection.section.length; i++) {
            const section = collection.section[i]
            sectionsArr.push({ id: i + 1, name: `Section ${i + 1}` })
            for (const task of section) {
                let obj = task
                obj.task_difficulty = obj.task_difficulty ?? ''
                obj.section = i + 1
                arr.push(obj)
            }
        }
        setSectionOptions(sectionsArr)
        setSections(sectionsArr.map(s => s.id))
        setTaskTable(arr)
    }
    const massAssign = (target, value) => {
        console.log(value)
        if (!selected.length || value === '') return showWarningSwal('Please select at least one task and type in something to mass assign.')
        let newTaskTable = window.structuredClone(taskTable)
        switch (target) {
            case 'task_difficulty':
                for (const id of selected) {
                    let count = taskTable.findIndex(t => t.id === id)
                    newTaskTable[count][target] = value
                    let difficulty = difficultyOptions.find(d => d.name === value)
                    if (newTaskTable[count].answer_structure !== 'open' && value !== '') {
                        newTaskTable[count].task_marks.forEach(a => {a.marks<=0?a.marks = difficulty.correct_marks:a.marks = a.marks})
                        newTaskTable[count].task_wrong<=0?newTaskTable[count].task_wrong = difficulty.wrong_marks:newTaskTable[count].task_wrong=newTaskTable[count].task_wrong
                        newTaskTable[count].task_blank<=0? newTaskTable[count].task_blank= difficulty.blank_marks:newTaskTable[count].task_blank=newTaskTable[count].task_blank
                    }
                }
                break;
            case 'task_correct':
                for (const id of selected) {
                    let count = taskTable.findIndex(t => t.id === id)
                    for (let i = 0; i < newTaskTable[count].task_marks.length; i++) {
                        newTaskTable[count].task_marks[i].marks = value
                    }
                }
                break;
            case 'task_wrong':
            case 'task_blank':
                for (const id of selected) {
                    newTaskTable[taskTable.findIndex(t => t.id === id)][target] = value
                }
                break;
            case 'marks':
            case 'min_marks':
                for (const id of selected) {
                    let count = taskTable.findIndex(t => t.id === id)
                    if (newTaskTable[count].answer_structure === 'open') {
                        for (let i = 0; i < newTaskTable[count].task_marks.length; i++) {
                            newTaskTable[count].task_marks[i][target] = value
                        }
                    }
                }
                break;
            default:
        }
        setTaskTable(newTaskTable)
    }
    const updateTaskTable = (count, target, value) => {
        let newTaskTable = window.structuredClone(taskTable)
        if (['min_marks', 'marks'].includes(target)) {
            newTaskTable[count[0]].task_marks[count[1]][target] = value
        } else newTaskTable[count][target] = value
        if (['task_difficulty'].includes(target) && newTaskTable[count].answer_structure !== 'open' && value !== '') {
            let difficulty = difficultyOptions.find(d => d.name === value)
            console.log(difficulty)
            if(difficulty){
                newTaskTable[count].task_marks.forEach(a => {a.marks<=0?a.marks = difficulty.correct_marks:a.marks = a.marks})
                newTaskTable[count].task_wrong<=0?newTaskTable[count].task_wrong = difficulty.wrong_marks:newTaskTable[count].task_wrong=newTaskTable[count].task_wrong
                newTaskTable[count].task_blank<=0? newTaskTable[count].task_blank= difficulty.blank_marks:newTaskTable[count].task_blank=newTaskTable[count].task_blank
                // newTaskTable[count].task_marks&&newTaskTable[count].task_marks.forEach(a => a.marks = difficulty.correct_marks)
                // newTaskTable[count].task_wrong = difficulty.wrong_marks
                // newTaskTable[count].task_blank = difficulty.blank_marks
        }
        }
        setTaskTable(newTaskTable)
    }
    const getData = useCallback(signal => {
        setLoadingTable(true)
        let query = location.search.slice(1).split('&').map(q => q.split('=')[1])
        getDifficultyAndPoints(`?competition_id=${query[0]}&round_id=${query[1]}&level_id=${query[2]}`, signal).then(d => {
            console.log(d , query[0])
            setDifficultyOptions(d.difficultyList.difficulty)
            fuckLah(query, d.competitionTask)
            setFirstLoaded(false)
            setLoadingTable(false)
        }).catch(e => {
            if (e.name !== 'AbortError') {
                setLoadingTable(false)
                setFirstLoaded(false)
            }
            console.log(e)
        })
    }, [location.search])
    useEffect(() => {
        isMounted.current = true
        let queries = location.search.slice(1).split('&')
        if (!(['competition_id', 'round_id', 'level_id'].every((el, i) => el === queries[i].split('=')[0]) && queries.length === 3)) {
            return showWarningSwal('Invalid address')
        }
        getData(signal)
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [location.search, controller, signal, getData])
    const onClickSave = () => {
        let warningMessage = ''
        if (!taskTable) warningMessage += 'Task table is empty.<br>'
        if (warningMessage) return showWarningSwal(warningMessage)
        let payload = {
            "competition_id": competitionID,
            "level_id": levelID,
            "tasks": []
        }
        setLoading(true)
        for (const task of taskTable) {
            const { id, task_difficulty, task_wrong, task_blank, task_marks } = task
            let obj = {
                "task_id": id,
                "difficulty": task_difficulty,
                "wrong_marks": task_wrong,
                "blank_marks": task_blank,
                "answers": task_marks&&task_marks.map(t => ({
                    id: t.id,
                    min_marks: t.min_marks,
                    marks: t.marks
                }))
            }
            payload.tasks.push(obj)
        }
        console.log(payload)
        patchDifficultyAndPoints(payload, signal).then(d => {
            showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
            getData()
            setLoading(false)
        }).catch(e => {
            setLoading(false)
            console.log(e)
        })
    }
    console.log(taskTable)
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <div className='firstRowContainer dashboardAndSelfBtnDiv'>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                <ChevronRightIcon />
                <ReusableButton text='Assign Difficulty' fontSize={14} bgColor='#F16774' height={36} br={18} />
            </div>
            <Grid className='tableContainer' container>
                <NunitoText value='Assign Difficulty and Points' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container>
                    <Widget title='Competition' content={competitionName} marginRight={28} />
                    <Widget title='Collection' content={collectionName} marginRight={28} />
                    <Widget title='Round' content={roundName} marginRight={28} />
                    <Widget title='Level' content={levelName} marginRight={28} />
                </Grid>
                <Grid container style={{ marginBlock: '20px 5px' }}>
                    <div style={{
                        ...massAssignDivStyle,
                        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'
                    }}>
                        <NunitoText value='Mass Assign Difficulty' fontSize={20} fontWeight={600} marginTop={4} marginBottom={4} />
                        <ReusableAutocomplete type='default' width={240} height={50} placeholder='Difficulty'
                            state={difficulty} setState={setDifficulty} borderColor='#707070'
                            options={difficultyOptions?difficultyOptions.map(d => ({ id: d.id, option: d.name })):[]} />
                        <ReusableButton text='Mass Assign Difficulty' fontSize={15} bgColor='#5E75C3'
                            height={42} onClick={() => massAssign('task_difficulty', difficulty)} />
                    </div>
                    <div style={massAssignDivStyle}>
                        <NunitoText value='Mass Assign Points' fontSize={20} fontWeight={600} marginTop={4} marginBottom={4} />
                        <Grid container>
                            <div style={{ ...divStyle, marginRight: 10 }}>
                                <div style={{ ...massAssignPointsDivStyle, marginRight: 10 }}>
                                    <ReusableTextField type='default' width={130} height={50} bgColor='#F2F2F2' marginBottom={10}
                                        placeholder='Correct' state={massAssignCorrect} setState={setMassAssignCorrect} number />
                                    <ReusableButton text='Mass Assign' fontSize={15} bgColor='#6FC9B6'
                                        height={42} onClick={() => massAssign('task_correct', massAssignCorrect)} />
                                </div>
                                <div style={{ ...massAssignPointsDivStyle, marginRight: 10 }}>
                                    <ReusableTextField type='default' width={130} height={50} bgColor='#F2F2F2' marginBottom={10}
                                        placeholder='Wrong' state={massAssignWrong} setState={setMassAssignWrong} number
                                        prefix='-' />
                                    <ReusableButton text='Mass Assign' fontSize={15} bgColor='#F3797E'
                                        height={42} onClick={() => massAssign('task_wrong', massAssignWrong)} />
                                </div>
                                <div style={massAssignPointsDivStyle}>
                                    <ReusableTextField type='default' width={130} height={50} bgColor='#F2F2F2' marginBottom={10}
                                        placeholder='Blank' state={massAssignBlank} setState={setMassAssignBlank} number
                                        prefix='-' />
                                    <ReusableButton text='Mass Assign' fontSize={15} bgColor='#707070'
                                        height={42} onClick={() => massAssign('task_blank', massAssignBlank)} />
                                </div>
                            </div>
                            <div style={divStyle}>
                                <div style={{ ...massAssignPointsDivStyle, marginRight: 10 }}>
                                    <ReusableTextField type='default' width={160} height={50} bgColor='#F2F2F2' marginBottom={10}
                                        placeholder='Minumum' state={massAssignMin} setState={setMassAssignMin} number />
                                    <ReusableButton text='Mass Assign Min' fontSize={15} bgColor='#5E75C3'
                                        height={42} onClick={() => massAssign('min_marks', massAssignMin)} />
                                </div>
                                <div style={massAssignPointsDivStyle}>
                                    <ReusableTextField type='default' width={160} height={50} bgColor='#F2F2F2' marginBottom={10}
                                        placeholder='Maximum' state={massAssignMax} setState={setMassAssignMax} number />
                                    <ReusableButton text='Mass Assign Max' fontSize={15} bgColor='#5E75C3'
                                        height={42} onClick={() => massAssign('marks', massAssignMax)} />
                                </div>
                            </div>
                        </Grid>
                    </div>
                </Grid>
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={setSearch} justSearch />
                    <div style={{ marginRight: 40, display: 'flex', alignItems: 'center' }}>
                        <ReusableAutocompleteWithID type='default' width={400} placeholder='Section'
                            state={sections} setState={setSections} borderColor='#707070' required multiple
                            options={sectionOptions.map(s => ({ id: s.id, option: s.name }))} marginRight={20} />
                        {isAdmin() && <ReusableButton text='Save All' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => onClickSave()} iconType='save' />}
                    </div>
                </Grid>
                <NunitoText value='Search only in "Task Name" column' fontSize={20} fontWeight={400} italic color='#144A94' />
                {loadingTable && <Loader height={600} />}
                {!loadingTable && taskTable && !!makeSureIsArray(taskTable).length && <AssignMarksTable headers={headers}
                    data={taskTable} error search={search}
                    sections={sections} selected={selected} setSelected={setSelected} setState={updateTaskTable}
                    difficultyOptions={difficultyOptions}
                    fixed={['name', 'answer_structure', 'task_difficulty', 'section']}
                />}
                {/* {!loadingTable && firstLoaded && userTable && !makeSureIsArray(userTable.data).length && <NoData height={600} />} */}
            </Grid>
            {/* {taskTable && taskTable.map((task, i) => (
                <Grid key={i} container>
                    <Grid container>index: {i}</Grid>
                    <Grid container>{JSON.stringify(task)}</Grid>
                </Grid>
            ))} */}
        </Box >
    )
}