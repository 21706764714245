import React, { useState } from 'react'
import { Box, Grid, Tabs, Tab } from '@mui/material'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { invalidName, invalidEmail } from '../../functions/general'
import { showWarningSwal, showInfoSwal } from '../../functions/alert'
import { getBase64 } from '../../functions/upload'
import { addOrganization } from '../../functions/postData'
import NunitoText from '../general/NunitoText'
import ReusableCheckbox from '../general/ReusableCheckbox'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
import { showNotification } from '../../functions/snackbar'


import Loader from '../general/Loader'
const rowContainerStyle = { paddingInline: 34 }
const firstRowContainerStyle = { ...rowContainerStyle, marginTop: 20, marginBottom: 30 }
const secondRowContainerStyle = { ...rowContainerStyle, marginBottom: 30 }
const thirdRowContainerStyle = { ...rowContainerStyle }
const fourthRowContainerStyle = { ...rowContainerStyle, marginTop: 10 }

const checkboxDivStyle = { marginTop: -10 }
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
const imageStyle = { maxHeight: 500, maxWidth: 760, marginBottom: 20 }
const previewStyle = {
   display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F2F2F2',
   height: 250, width: 760, marginBottom: 20
}
function EachForm({ forms, setForms, count }) {
   const { name, address, email, country, phoneNumber, personInCharge, useSame, billingAddress, hasImage, loadingImage, image } = forms[count]
   const countryOptions = useSelector(state => state.countryOptions)
   const updateForm = (identifier, value) => {
      let newForms = [...forms]
      newForms[count][identifier] = value
      setForms(newForms)
   }
   const openFile = () => document.getElementById(`file-upload-${count}`).click()
   const handleFileInputChange = (file) => {
      updateForm('hasImage', true)
      updateForm('loadingImage', true)
      getBase64(file)
         .then(result => {
            updateForm('image', result)
            updateForm('loadingImage', false)
         })
         .catch(err => {
            console.log(err);
            alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
            updateForm('loadingImage', false)
         });
   }
   return (
      <Grid container justifyContent='center'>
         <Grid container justifyContent="space-between" style={firstRowContainerStyle}>
            <ReusableTextField type='withLabel' width={400} height={60} bgColor='#F2F2F2' placeholder='Organization Name*'
               state={name} setState={updateForm} required onBlur target='name' />
            <ReusableTextField type='withLabel' width={500} height={60} bgColor='#F2F2F2' placeholder='Organization Address*'
               state={address} setState={updateForm} required target='address' />
            <ReusableTextField type='withLabel' width={400} height={60} bgColor='#F2F2F2' placeholder='Email*'
               state={email} setState={updateForm} required target='email' />
         </Grid>
         <Grid container justifyContent="space-between" alignItems='center' style={secondRowContainerStyle}>
            <ReusableTextField type='withLabel' width={400} height={60} bgColor='#F2F2F2'
               placeholder={`Phone Number${country && `(+${countryOptions.find(c => c.id === country).Dial})`}*`}
               state={phoneNumber} setState={updateForm} required target='phoneNumber' />
            <ReusableAutocompleteWithID type='withLabel' width={500} height={60} placeholder='Country*'
               state={country} setState={updateForm} borderColor='#707070' required
               options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))} target='country' />
            <ReusableTextField type='withLabel' width={400} height={60} bgColor='#F2F2F2' placeholder='Person-in-charge*'
               state={personInCharge} setState={updateForm} required target='personInCharge' />
         </Grid>
         <Grid container justifyContent="space-between" style={thirdRowContainerStyle}>
            <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Billing Address'
               state={useSame ? address : billingAddress} setState={updateForm}
               readOnly={useSame} disabled={useSame} target='billingAddress' />
            <ReusableTextField type='withLabel' width={760} height={60} bgColor='#F2F2F2' placeholder='Select Image'
               state={image} setState={updateForm} onClick={openFile} readOnly target='image' />
         </Grid>
         <input id={`file-upload-${count}`} type="file" style={{ display: 'none' }}
            onChange={e => handleFileInputChange(e.target.files[0])} />
         <Grid container justifyContent='space-between' style={fourthRowContainerStyle}>
            <div style={checkboxDivStyle}>
               <ReusableCheckbox type='modal' label='Same as Organization Address' state={useSame} setState={updateForm}
                  fontSize={16} target='useSame' />
            </div>
            {hasImage ?
               !loadingImage && image
                  ? <img src={image} alt="Organization Logo" style={imageStyle} />
                  : <Loader height={250} />
               : <div style={previewStyle}>
                  <p>No preview available</p>
               </div>}
         </Grid>
      </Grid>
   )
}
const boxStyle = { width: 1500, maxHeight: '90%' }
const addGroupButtonDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -45, marginRight: 20 }
const divStyle = {
   borderRadius: 10, margin: '20px 20px 0 20px',
   boxShadow: '0px 1px 6px rgb(0,0,0,0.2), 0 1px 6px rgb(0,0,0,0.2)'
}
const tabPropsStyle = { height: 0 }
const tabStyle = (value, i) => ({
   color: value !== i && '#707070',
   backgroundColor: value !== i && '#E7E6E6',
   border: value === i && '1px solid #F0F0F0',
   boxShadow: value === i && '0px 1px 6px rgb(0,0,0,0.2), 0px 1px 6px rgb(0,0,0,0.2)',
   borderRadius: '10px 10px 0 0',
   textTransform: 'none',
   marginRight: 10, height: 40
})

const closeIconStyle = { position: 'relative', left: 12, height: 18, width: 18, color: '#707070' }
const buttonsContainerStyle = { paddingInline: 100, marginBlock: 30 }
export default function AddOrganizationModal({ setLoading, setOnAddOrganization, afterAddingOrganization }) {
   const [forms, setForms] = useState([{
      id: 1, name: '', address: '', email: '', phoneNumber: '', country: '', personInCharge: '',
      billingAddress: '', useSame: false, image: '', hasImage: false, loadingImage: false
   }])
   const [value, setValue] = useState(0)
   const handleChange = (e, newValue) => setValue(newValue)
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()

   const addForm = () => {
      if (forms.length >= 8) return showInfoSwal('Maximum number of tab is 8')
      setForms([...forms, {
         id: forms.map(f => f.id).reduce((a, b) => Math.max(a, b), 0) + 1,
         name: '', address: '', email: '', phoneNumber: '', country: '', personInCharge: '',
         billingAddress: '', useSame: false, image: '', hasImage: false, loadingImage: false
      }])
      setValue(forms.length)
   }
   const deleteForm = (e, id) => {
      e.stopPropagation()
      let newForms = forms.filter(f => f.id !== id)
      if (!newForms.length) return setOnAddOrganization(false)
      let index = forms.findIndex(f => f.id === id)
      if (value === forms.length - 1 || index < value) setValue(value - 1)
      setForms(newForms)
   }
   const onSubmit = () => {
      let payload = []
      let msg = ''
      for (let i = 0; i < forms.length; i++) {
         const { name, address, email, country, phoneNumber, personInCharge, useSame, billingAddress, image } = forms[i]
         let warningMessage = ''
         // if (invalidName(name)) warningMessage += 'Invalid Organization Name.<br>'
         if (address === '') warningMessage += 'Please enter an address.<br>'
         if (invalidEmail(email)) warningMessage += 'Invalid Email.<br>'
         if (!phoneNumber.toString().length) {
            warningMessage += 'Phone number must be number.<br>'
         } else {
            if (phoneNumber.split('/').some(el => isNaN(el) || !el.toString().length)) {
               warningMessage += 'All phone number must be number.<br>'
            }
            if (!/^[\d/]*$/.test(phoneNumber.trim())) warningMessage += 'Add extra phone number using "/".<br>'
         }
         if (invalidName(personInCharge)) warningMessage += 'Invalid Person in charge.<br>'
         if (!country) warningMessage += 'Please select a country.<br>'
         if (!warningMessage) {
            payload.push({
               name: name.toUpperCase(), country_id: country,
               address: address, billing_address: useSame ? address : billingAddress,
               phone: phoneNumber.split('/').map(o => o.trim()), email: email,
               person_incharge: personInCharge, logo: image
            })
         } else {
            msg += '<div>'
            msg += `<div style="text-align:center; font-weight: 800">Error in Organization ${i + 1}</div>`
            msg += `<div style="text-align:left">${warningMessage}</div>`
            msg += '</div>'
         }
      }
      if (msg) return showWarningSwal(msg)
      setLoading(true)
      addOrganization(payload).then(d => {
         setLoading(false)
       

         afterAddingOrganization(() => setOnAddOrganization(false), d)
         showNotification('success', 'successfully added an orginzation', enqueueSnackbar, closeSnackbar)
      }).catch(e => {
         setLoading(false)
         console.log(e)
      })
   }
   return (
      <Box className='popUpModal horizontalScrollable' style={boxStyle}>
         <NunitoText value='ADD NEW ORGANISATION' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
         <div style={addGroupButtonDivStyle}>
            <ReusableButton text='Add another organization' fontSize={15} height={40} width={280} bgColor='#5E75C3' iconType='add'
               onClick={() => addForm()} allowSpam />
         </div>
         <div style={divStyle}>
            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: tabPropsStyle }}>
               {forms.map((f, i) => <Tab key={f.id} label={<div style={centerFlexStyle}>
                  Organization {i + 1}
                  <CloseIcon style={closeIconStyle} onClick={e => deleteForm(e, f.id)} />
               </div>} style={tabStyle(value, i)} />)}
            </Tabs>
            <EachForm forms={forms} setForms={setForms} count={value} />
         </div>
         <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
            <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
               onClick={() => setOnAddOrganization(false)} />
            <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
               onClick={() => onSubmit()} />
         </Grid>
      </Box>
   )
}