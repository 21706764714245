import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import NunitoText from "../general/NunitoText";
import ReusableButton from "../general/ReusableButton";
import { useState } from "react";
import ReusableAutocompleteWithID from "../general/ReusableAutocompleteWithID";
import {
  getMarkedParticipants,
  getAnswerParticipants,
} from "../../functions/getData";
import LightTable from "../table/LightTable";
import LoadingBackdrop from "../general/LoadingBackdrop";
import NoData from "../general/NoData";
import ReusableTextField from "../general/ReusableTextField";
import { IoClose } from "react-icons/io5";
import ReusableSelect from "../general/ReusableSelect";
import { updateParticipantsAnswers } from "../../functions/postData";
import { showNotification } from "../../functions/snackbar";
import { useSnackbar } from "notistack";

export default function ShowUniqueAnswerParticipantsModal({
  setOnElm,
  answerId,
  handleSubmit,
  answerText,
  onClose,
  onConfirm,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [newAnswer, setNewAnswer] = useState("");
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState();
  const [isMcq, setIsMcq] = useState(false);
  const [mcqPossibleAnswers, setMcqPossibleAnswers] = useState([
    { value: 1, option: "Answer 1" },
    { value: 2, option: "Answer 2" },
    { value: 3, option: "Answer 3" },
  ]);
  const [header, setHeader] = useState([
    { label: "Index Number", state: "", id: "index_no", header: "index_no" },
    { label: "Name", state: "name", id: "name", header: "name" },
    {
      label: "Integrity IAC",
      state: "integrity_update_enable",
      id: "integrity_update_enable",
      header: "integrity_update_enable",
    },
    { label: "School", state: [], id: "school", header: "school" },
    { label: "Country", state: [], id: "country", header: "country" },
    { label: "Grade", state: [], id: "grade", header: "grade" },
  ]);

  const submit = () => {
    let answerIdsArray = [];
    selected.map((indexNumber) => {
      const checkedParticipant = data.filter(
        (participant) => participant.index_no === indexNumber
      );
      answerIdsArray.push(checkedParticipant[0].participantAnswerId.toString());
    });

    const payload = { answer_id: answerIdsArray, new_answer: newAnswer };
    updateParticipantsAnswers(payload).then((res) => {
      if (res.error) {
        showNotification("warning", res.error, enqueueSnackbar, closeSnackbar);
      } else {
        showNotification(
          "success",
          res.message,
          enqueueSnackbar,
          closeSnackbar,
          onConfirm()
        );
      }
    });
  };

  const getParticipantsData = () => {
    getAnswerParticipants(answerId).then((participants) => {
      const structuredParticipants = participants.map((participant) => {
        return {
          country: participant?.country?.display_name,
          grade: participant?.grade,
          index_no: participant?.index_no,
          name: participant?.name,
          school: participant?.school?.name,
          participantAnswerId: participant?.participant_answer_id,
          integrity_update_enable:
            participant?.integrity_update_enable === true ? "No" : "Yes",
        };
      });
      setData(structuredParticipants);
    });
  };

  useEffect(() => {
    getParticipantsData();
  }, [answerId]);
  return (
    <>
      <LoadingBackdrop loading={data === undefined} />
      {data && (
        <Box
          className="popUpModal horizontalScrollable"
          style={{
            width: "80%",
            minHeight: "200px",
            maxHeight: "80%",
            justifyContent: "flex-start",
            padding: "10px 0",
          }}
        >
          <Button
            style={{
              position: "absolute",
              top: "5%",
              right: "4%",
            }}
            onClick={onClose}
          >
            <IoClose style={{ fontSize: 30, color: "gray" }} />
          </Button>
          <NunitoText
            value={"Unique Answer Participants"}
            fontSize={30}
            fontWeight={600}
            color="#5E75C3"
            marginTop={20}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <NunitoText
              value={"Participants answer: "}
              fontSize={20}
              fontWeight={600}
              color="#5E75C3"
              marginRight={20}
            />
            <NunitoText value={answerText} fontSize={20} fontWeight={600} />
          </Box>

          {data.length > 0 ? (
            <Grid
              className="tableContainer"
              container
              position={"relative"}
              width={"100%"}
              alignSelf={"center"}
            >
              <LightTable
                insideModal={true}
                hasPagination={true}
                hasSelect={true}
                selected={selected}
                selecting={[]}
                setSelecting={() => {}}
                setSelected={setSelected}
                fixed={[]}
                data={data}
                headers={header}
              />
            </Grid>
          ) : (
            <Grid marginBottom={"3%"}>
              <NoData />
            </Grid>
          )}

          <Box minHeight={"140px"}>
            {selected.length > 0 && (
              <Grid>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <NunitoText
                    value={"Enter new answer:"}
                    fontSize={20}
                    fontWeight={600}
                    // color="#5E75C3"
                    marginRight={20}
                  />
                  {isMcq ? (
                    <ReusableSelect
                      type="type2"
                      state={newAnswer}
                      setState={setNewAnswer}
                      btnBgColor="#5E75C3"
                      height={45}
                      width={300}
                      options={mcqPossibleAnswers}
                    />
                  ) : (
                    <ReusableTextField
                      type="default"
                      placeholder="Answer"
                      bgColor="#F2F2F2"
                      height={45}
                      state={newAnswer}
                      setState={setNewAnswer}
                      // count={[count, refLanguageCount]} head='languageText' target='taskTitle'
                    />
                  )}
                </Box>
                <ReusableButton
                  text={"Update Answers"}
                  fontSize={18}
                  height={45}
                  bgColor="#5E75C3"
                  onClick={() => {
                    submit();
                  }}
                  marginBottom={30}
                />
              </Grid>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
