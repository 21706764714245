import React from 'react'
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import { useState } from 'react'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableTextField from '../general/ReusableTextField'

export default function ElemParticipant({setOnElm , selected  , id , handleSubmit , title , mark , CheatingTable}) {
    // const [partstring , setpartString] = useState("")
   const [reasons , setReasons] = useState(selected.map(el=>{
    return el!==undefined?{index:el ,reason:''}:''
   }))
    const submit=()=>{
        if(mark){
    handleSubmit(id,reasons.filter((el)=>{
        return el!==''
    }),'',CheatingTable?'system':'map')
        }else{
            handleSubmit(id,selected.filter(el=>{
                return el!==undefined
            }),CheatingTable?'system':'map')
        }
    }

    const handleFormChange = (index, event) => {
        let data = [...reasons];
        data[index][event.target.name] = event.target.value;
        setReasons(data);
     }
     console.log(reasons , 'here')
    return  <Box className='popUpModal horizontalScrollable' style={{ width: 1000, minHeight: 400 }}>
    <NunitoText value={title} fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
    
    
    <NunitoText marginBottom={'2%'} value={!mark?`Are You Sure You Want to 'Unmark'  ${selected.length} Participant${selected.length>1?'s':''} as IAC?`:`please Add the Integrity issue description`} fontSize={20} fontWeight={600} />
   {mark&& <Grid>
        {reasons.map((el,index)=>{   
            return(
                <>
                {el!==''&&<Grid container width={'80%'} margin={'0 auto'} marginBottom={'2%'} justifyContent={'space-between'} display={'flex'} flexDirection={'row'}>
                    <ReusableTextField readOnly  type={'default'}  width={'300px'}  placeholder={selected[index]}/>
                    <input name='reason' placeholder='Integrity Issue Description' value={el.reason} onChange={event => handleFormChange(index, event)} style={{
                        borderRadius:'18px' , width:'300px' , textAlign:'center' , border:'1px solid' , borderColor:'#E8E8E8' , backgroundColor:'#E8E8E8'
                    }}
              />
                </Grid>}
                </>
            )
        })}
    </Grid>}
    <Grid justifyContent={'space-between'} container width={'80%'} alignSelf={'center'}>
        <ReusableButton  text={'Confirm'}  fontSize={22} height={60} width={300} bgColor='#5E75C3' onClick={()=>{
            submit()
        }} marginBottom={20} />
        <ReusableButton  text={'Cancel'}  fontSize={22} height={60} width={300} bgColor='#E83042' onClick={()=>{
        setOnElm(false)
        }} marginBottom={20} />
    </Grid>
</Box>
}