import React from 'react'
import { Grid, IconButton, Checkbox } from '@mui/material'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp'
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp'
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp'
import { getBase64 } from '../../functions/upload'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
const Preview = ({ width, count, image, updateAnswers }) => {
    const previewImage = () => {
        var newTab = window.open();
        newTab.document.title = 'Image Preview'
        newTab.document.body.innerHTML = `<div style="text-align:center;">
            <h1>Image preview</h1>
            <img src=${image} alt="Preview of image" width=600 />
        </div>`
    }
    const containerStyle = { width }
    return (
        <Grid container alignItems="center" style={containerStyle}>
            <ReusableButton text='Preview Image' height={44} bgColor='#144A94' fontSize={15}
                onClick={() => previewImage()} iconType='preview' />
            <ReusableButton text='Clear' height={40} bgColor='#E83D4D' fontSize={15} marginLeft={10}
                onClick={() => updateAnswers(count, 'image', '')} iconType='delete' />
        </Grid>
    )
}
const downIconBtnStyle = { marginLeft: 18 }
const upIconBtnStyle = { marginRight: 28 }
const iconStyle = { color: "#000" }
const Arrows = ({ count, task, updateAnswers }) => {
    const { task_answers } = task
    if (task_answers.length > 1) {
        if (!count) {
            //First answer with only downward arrow
            return (
                <IconButton edge="start" onClick={() => updateAnswers(count, 'moveDown')} style={downIconBtnStyle}>
                    <ArrowDownwardSharpIcon style={iconStyle} />
                </IconButton>
            )
        } else if (count === task_answers.length - 1) {
            //Last answer with only upward arrow
            return (
                <IconButton edge="start" onClick={() => updateAnswers(count, 'moveUp')} style={upIconBtnStyle}>
                    <ArrowUpwardSharpIcon style={iconStyle} />
                </IconButton>
            )
        } else {
            //Any answers in between would have both upward and downward arrows
            return (
                <div>
                    <IconButton edge="start" onClick={() => updateAnswers(count, 'moveUp')}>
                        <ArrowUpwardSharpIcon style={iconStyle} />
                    </IconButton>
                    <IconButton edge="start" onClick={() => updateAnswers(count, 'moveDown')}>
                        <ArrowDownwardSharpIcon style={iconStyle} />
                    </IconButton>
                </div>
            )
        }
    }
    //Don't show any arrow if there is only 1 answer
    return null
}
const AddAndRemoveAnswerButtons = ({ count, updateAnswers }) => {
    return (
        <>
            <IconButton color="inherit" onClick={() => updateAnswers(count, 'answerKeyAddAnswers')}>
                <AddCircleSharpIcon />
            </IconButton>
            {!!count && <IconButton color="inherit" onClick={() => updateAnswers(count, 'answerKeyDeleteAnswers')}>
                <RemoveCircleSharpIcon />
            </IconButton>}
        </>
    )
}
const arrowWrapperStyle = {
    display: 'flex', justifyContent: 'center', alignItems: 'center'
}
const titleStyle = { display: 'flex', justifyContent: 'center' }
const taskLabelContainerStyle = { display: 'flex', flexDirection: 'column', paddingInline: 30 }
const answerContainerStyle = { marginTop: 10 }
const centerStyle = { textAlign: 'center' }
const Title = value => <NunitoText value={value} fontSize={26} fontWeight={700} marginTop={20}
    borderBottom='1px solid' width={200} paddingBottom={5} />
export default function Answer({ count, answer, original, setOriginal, task, setTask,isread}) {
    const handleFileInputChange = file => {
        getBase64(file).then(result => updateAnswers(count, 'image', result)).catch(err => {
            console.log(err);
            alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
        })
    }
    const openFile = () => document.getElementById(`file-upload-${count}`).click()
    const { image_label, answer_type, answer_structure } = task
    const { id, task_labels, correct } = answer
    const { content, image } = task_labels[0]
    const HiddenInput = () => <input id={`file-upload-${count}`} type="file" style={{ display: 'none' }}
        onChange={e => handleFileInputChange(e.target.files[0])} />
    const AnswerLabelOrImageTextField = () => <ReusableTextField type='default' disabled={isread} 
        placeholder={image_label ? "Click to upload image" : `Enter ${answer_structure === 'open' ? 'Task' : 'Answer'} Label`}
        state={image_label ? image : content} setState={updateAnswers}
        count={count} target='content' onClick={image_label && openFile}
        readOnly={image_label||isread} onBlur={!image_label} />
    const updateAnswers = (count, identifier, value) => {
        let newTask = window.structuredClone(task)
        switch (identifier) {
            case 'answerKeyAddAnswers':
                newTask.task_answers.splice(count + 1, 0, {
                    id: null, answer: '', task_labels: [{ lang_id: 171, content: '', image: '' ,id:null}], correct: false
                })
                break;
            case 'correct':
               newTask.task_answers[count].correct = !newTask.task_answers[count].correct;
               newTask.task_answers[count].answer = newTask.task_answers[count].correct?'1':null;
               
                break;
            case 'answerKeyDeleteAnswers':
                newTask.task_answers.splice(count, 1)
                break;
            case 'moveUp':
                [newTask.task_answers[count], newTask.task_answers[count - 1]]
                    = [newTask.task_answers[count - 1], newTask.task_answers[count]]
                break;
            case 'moveDown':
                [newTask.task_answers[count], newTask.task_answers[count + 1]]
                    = [newTask.task_answers[count + 1], newTask.task_answers[count]]
                break;
            case 'content':
            case 'image':
                newTask.task_answers[count].task_labels[0][identifier] = value
                break;
            default: newTask.task_answers[count][identifier] = value
        }
        setTask(newTask)
    }
    if (['mcq'].includes(answer_type)) {
        if (['sequence'].includes(answer_structure)) {
            return <>
                {count === 0 && <Grid container alignItems="center">
                    <Grid item xs={2} style={titleStyle}>{Title('Answer ID')}</Grid>
                    <Grid item xs={1.5} style={titleStyle}>{Title('Sequence')}</Grid>
                    <Grid item xs={7.5} style={titleStyle}>{Title('Answer Label')}</Grid>
                </Grid>}
                <Grid container style={answerContainerStyle} alignItems="center">
                    <Grid item xs={2} style={centerStyle}>
                        <NunitoText value={id} fontSize={24} />
                    </Grid>
                    <Grid item xs={1.5} style={arrowWrapperStyle}>
                        <NunitoText value={(count + 1).toString()} fontSize={25} marginRight={20} />
                        <Arrows count={count} task={task} updateAnswers={updateAnswers} />
                    </Grid>
                    <Grid item xs={4.5 + ((image === '' || !image_label) && 3)} style={taskLabelContainerStyle}>
                        <AnswerLabelOrImageTextField />
                        <HiddenInput />
                    </Grid>
                    {image !== '' && image_label && <Grid item xs={3}>
                        <Preview count={count} image={image} updateAnswers={updateAnswers} />
                    </Grid>}
                    <Grid item xs={1}>
                        <AddAndRemoveAnswerButtons count={count} updateAnswers={updateAnswers} />
                    </Grid>
                </Grid>
            </>
        } else {
            //Default and Group are similar in MCQ
            return <>
                {count === 0 && <Grid container alignItems="center">
                    <Grid item xs={2} style={titleStyle}>{Title('Answer ID')}</Grid>
                    <Grid item xs={1.5} style={titleStyle}>{Title('Position')}</Grid>
                    <Grid item xs={5.5} style={titleStyle}>{Title('Answer Label')}</Grid>
                    { !isread&&<Grid item xs={1} />}
                    <Grid item xs={2} style={titleStyle}>{Title('Correct Answer')}</Grid>
                </Grid>}
                <Grid container style={answerContainerStyle} alignItems="center">
                    <Grid item xs={2} style={centerStyle}>
                        <NunitoText value={id} fontSize={24} />
                    </Grid>
                    <Grid item xs={1.5} style={arrowWrapperStyle}>
                        <Arrows count={count} task={task} updateAnswers={isread?()=>{}:updateAnswers} />
                    </Grid>
                    <Grid item xs={2.5 + ((image === '' || !image_label) && 3)} style={taskLabelContainerStyle}>
                        <AnswerLabelOrImageTextField isread={isread}/>
                        <HiddenInput />
                    </Grid>
                    {image !== '' && image_label && <Grid item xs={3}>
                        <Preview count={count} image={image} updateAnswers={updateAnswers} />
                    </Grid>}
                    { !isread&& <Grid item xs={1}>
                        <AddAndRemoveAnswerButtons count={count} updateAnswers={updateAnswers} />
                    </Grid>}
                    <Grid item xs={2} style={centerStyle}>
                        <Checkbox style={{ color: '#000' }} checked={correct}
                            onChange={isread?()=>{}:() => updateAnswers(count, 'correct', !correct)} />
                    </Grid>
                </Grid>
            </>
        }
    } else {
        if (['open'].includes(answer_structure)) {
            return <>
                {count === 0 && <Grid container alignItems="center">
                    <Grid item xs={2} style={titleStyle}>{Title('Answer ID')}</Grid>
                    <Grid item xs={1.5} style={titleStyle}>{Title('Position')}</Grid>
                    <Grid item xs={7.5} style={titleStyle}>{Title('Task Label')}</Grid>
                </Grid>}
                <Grid container style={answerContainerStyle} alignItems="center">
                    <Grid item xs={2} style={centerStyle}>
                        <NunitoText value={id} fontSize={24} />
                    </Grid>
                    <Grid item xs={1.5} style={arrowWrapperStyle}>
                        <Arrows count={count} task={task} updateAnswers={isread?()=>{}:updateAnswers} />
                    </Grid>
                    <Grid item xs={4.5 + ((image === '' || !image_label) && 3)} style={taskLabelContainerStyle}>
                        <AnswerLabelOrImageTextField />
                        <HiddenInput />
                    </Grid>
                    {image !== '' && image_label && <Grid item xs={3}>
                        <Preview count={count} image={image} updateAnswers={isread?()=>{}:updateAnswers} />
                    </Grid>}
                   { !isread&&<Grid item xs={1}>
                        <AddAndRemoveAnswerButtons count={count} updateAnswers={isread?()=>{}:updateAnswers} />
                    </Grid>}
                </Grid>
            </>
        } else {
            //Default and Group are similar as well
            return <>
                {count === 0 && <Grid container alignItems="center">
                    <Grid item xs={2} style={titleStyle}>{Title('Answer ID')}</Grid>
                    <Grid item xs={1.5} style={titleStyle}>{Title('Position')}</Grid>
                    <Grid item xs={5.5} style={titleStyle}>{Title('Answer Label')}</Grid>
                    <Grid item xs={2} style={titleStyle}>{Title('Answer')}</Grid>
                </Grid>}
                <Grid container style={answerContainerStyle} alignItems="center" justifyContent='space-between'>
                    <Grid item xs={2} style={centerStyle}>
                        <NunitoText value={id} fontSize={24} />
                    </Grid>
                    <Grid item xs={1.5} style={arrowWrapperStyle}>
                        <Arrows count={count} task={task} updateAnswers={isread?()=>{}:updateAnswers} />
                    </Grid>
                    <Grid item xs={2.5 + ((image === '' || !image_label) && 3)} style={taskLabelContainerStyle}>
                        <AnswerLabelOrImageTextField />
                        <HiddenInput />
                    </Grid>
                    {image !== '' && image_label && <Grid item xs={3}>
                        <Preview count={count} image={image} updateAnswers={isread?()=>{}:updateAnswers} />
                    </Grid>}
                    <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
                        {/* <div style={{
                              fontFamily: 'Nunito', fontSize: 20, color: '#8E7DD0', cursor: 'pointer',
                              textDecoration: 'underline', textAlign: 'center'
                          }} onClick={() => setAddAnswers(true)}>
                              {answers.length ? 'Edit' : 'Add'} Correct Answers
                          </div> */}
                        <ReusableTextField type='default' placeholder="Enter answer" disabled={isread} readOnly={isread} state={answer.answer} setState={updateAnswers}
                            count={count} target='answer' onBlur />
                    </Grid>
                    { !isread&&  <Grid item xs={1}>
                        <AddAndRemoveAnswerButtons count={count} updateAnswers={isread?()=>{}:updateAnswers} />
                    </Grid>}
                </Grid>
            </>
        }
    }
}