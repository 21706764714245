import React, { useState } from 'react'
import { Grid } from '@mui/material'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableTextField from '../general/ReusableTextField'
import DescriptionEditor from '../DescriptionEditor'
const containerStyle = { paddingBottom: 20 }
const marginStyle = { margin: '36px 60px' }
const fieldsContainerStyle = { marginBottom: 40 }
export default function TranslatedContent({ languages, updateLanguages, languageOptions }) {
    const [viewingLanguage, setViewingLanguage] = useState(languages.find(l => l.name !== 'English').id)
    const count = languages.findIndex(l => l.id === viewingLanguage)
    const language = languages.find(l => l.id === viewingLanguage)
    const { name, task_title, content } = language
    return (
        <Grid container style={containerStyle}>
            <TitleBar title={languageOptions.find(l => l.id === viewingLanguage)?.name || ''} fixed />
            <AddPageWrapper>
                <Grid container style={marginStyle}>
                    <Grid container alignItems='center' style={fieldsContainerStyle}>
                        <Grid item xs={3}>
                            <ReusableAutocompleteWithID type='withLabel' width='100%' placeholder='Language' state={viewingLanguage}
                                setState={setViewingLanguage} options={languageOptions.map(l => ({ id: l.id, option: l.name }))} />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={7}>
                            <ReusableTextField type='withLabel' placeholder='Task Title*' state={task_title} setState={updateLanguages}
                                fullWidth count={count} target='task_title' onBlur required bgColor='#F2F2F2' />
                        </Grid>
                    </Grid>
                    <DescriptionEditor state={content} setState={updateLanguages} height={500}
                        placeholder={`Task Content For ${name}`} count={count} target='content' />
                </Grid>
            </AddPageWrapper>
        </Grid>
    )
}