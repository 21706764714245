import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import HeavyTable from '../components/table/HeavyTable'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
    makeSureIsArray, makeOptions, checkIfAllSelectedRowsMatchStatus, checkIfAnySelectedRowIs, resetAll,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showNotification } from '../functions/snackbar'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
import { isAdmin, isAdminOrPartner, isPartnerOrAssistant, isAdminOrPartnerOrAssistant } from '../functions/checkrole'
import { getGroudStats, getSchool, getSchools } from '../functions/getData'
import Widget from '../components/general/Widget'
import { countries } from 'country-flag-icons'
const schoolHeader =[
    { id: 'grade', label: 'Grade' },
    { id: 'totalParticipants', label:'Contestants'}
]
const initialParams = '?limits=10'
export default function AwardStats() {
    document.title = 'Awards Stats'
    const isMounted = useRef(false)
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState(schoolHeader)
    const [schoolTable, setSchoolTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "School/Tuition", state: '', key: 'private', header: 'schooltype' },
        isAdmin() && { label: 'Country', state: '', key: 'country_id', header: 'countries' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ].filter(Boolean))
    const [filterOptions, setFilterOptions] = useState()
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [group , setGroup] = useState('')
    const [comp , setcomp] = useState('')
    const [contries , setcontries] = useState('')
    const [selecting, setSelecting] = useState([])
    let location = useLocation()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        loadData(setLoadingTable, setFirstLoaded, getSchools, params, setSchoolTable, setFilterOptions, false, signal)
    }
    // const onChangeViewOptions = value => changeViewOptions(schoolHeader(), setViewOptions, setHeader, value)
    const onChangeSearch = value => {
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    useEffect(() => {
        isMounted.current = true
        if (location.search.slice(0, 4) === '?id=') {
            getGroudStats(`${location.search.slice(4)}`, signal).then(d => {
                setGroup(d.headers['Marking Group'])
                setcomp(d.headers.Competition)
                setcontries(d.headers.Countries)
                console.log(d)
                setLoadingTable(true)
                let max = 0;
                let rewardsarr = []
                let remainingheader = []
               
                d.data.grades.length>0&&d.data.grades.map((grade)=>{
                    let awards = makeSureIsArray(grade.awards)
                    if((awards.length)>max){
                        max = awards.length
                        rewardsarr = makeSureIsArray(grade.awards);
                    }
                })
                for(let i=0;i<3;i++){
               rewardsarr.map(reward=>{
                if(i==0){
                    remainingheader.push({'id':`${reward.award}topleast`,'label':`&${reward.award}`})
                }else if(i==1){
                    remainingheader.push({'id':`${reward.award}number`,'label':`#${reward.award}`})
                }else{
                    remainingheader.push({'id':`${reward.award}percent`,'label':`%${reward.award}`})
                }
               })
            }
            setHeader([...header,...remainingheader])
            let dataarr=[]
            d.data.grades.length>0&&d.data.grades.map(grade=>{
                let gradeobj={
                    grade:grade.grade,
                    totalParticipants:grade.totalParticipants
                }
                grade.awards&&makeSureIsArray(grade.awards).map(award=>{
                    gradeobj[`${award.award}topleast`] = `${award.topPoints}\u00A0\u00A0\u00A0\u00A0\u00A0${award.leastPoints}`
                    gradeobj[`${award.award}number`] = `${award.participantsCount}`
                    gradeobj[`${award.award}percent`] = `${award.awardPercentage}%`
                })
                dataarr.push(gradeobj)
            })
            
                setSchoolTable({current_page: 1,
                    first_page_url: "https://api.dev.simccms.org/api/competition?page=1",
                    data:dataarr,
                        from: 1,
                        last_page: 1,
                        last_page_url: "https://api.dev.simccms.org/api/competition?page=1",
                        links: [ ],
                        next_page_url: "https://api.dev.simccms.org/api/competition?page=1",
                        path: "https://api.dev.simccms.org/api/competition",
                        per_page: 10,
                        prev_page_url: null,
                        to: 10,
                        total: 14
                    })    
                setLoadingTable(false)
             }).catch(e => {
                if (!signal.aborted && isMounted.current) {
                   setFirstLoaded(true)
                   setLoadingTable(false)
                }
             })
        }
        // getData(initialParams, signal)
       
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller, signal])
    console.log(group , contries , comp)
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Awards Statistics' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
            </Grid>
            <Grid className='tableContainer' container>
                <NunitoText value='Awards Statistics' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                </Grid>
                <Grid>
                    <Grid container style={{padding:30}}>
                <Widget title='Competition' content={comp} marginRight={28} />
                <Widget title='Group' content={group} marginRight={28} />
                </Grid>
                <Grid container style={{padding:30}}>
                <Widget title='Countries' content={contries} marginRight={28}  />
                </Grid>
                {/* <NunitoText value={`Competition Name:${comp}`} fontSize={25} fontWeight={700} italic color='#144A94' align={'left'} /> */}
              
             
                    <h2 style={{
                        fontSize:'20px',
                        fontWeight:'400',
                        fontStyle:'italic',
                        color:'#144A94'
                    }}><span>&: </span>Top Vs Least Scores</h2>
                     <h2 style={{
                        fontSize:'20px',
                        fontWeight:'400',
                        fontStyle:'italic',
                        color:'red'
                    }}><span>#: </span>Number Of Participants</h2>
                      <h2 style={{
                        fontSize:'20px',
                        fontWeight:'400',
                        fontStyle:'italic',
                        color:'#000000'
                    }}><span>%: </span>Percentages</h2>
                    </Grid>
                {loadingTable && <Loader height={600} />}
                {!loadingTable && schoolTable && makeSureIsArray(schoolTable).length &&
                    <HeavyTable headers={header} list={schoolTable} title='awardStats' rowsPerPage={rowsPerPage}
                         onChangePage={onChangePage} fixed={['name', 'country_name']}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                        setId={setId}  
                      
                    />}
                {!loadingTable && firstLoaded && schoolTable && !makeSureIsArray(schoolTable.length) && <NoData height={600} />}
            </Grid>
        </Box>
    )
}