import { createStore } from 'redux'
import allReducers from './index'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
export const persistConfig = {
   key: 'root',
   storage,
   blacklist: ['competition', 'collection', 'task'],
   // stateReconciler: hardSet
}
const persistedReducer = persistReducer(persistConfig, allReducers)
const store = createStore(
   persistedReducer,
   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() //For development purpose
)
export const persistor = persistStore(store)
export default store