import React, { useEffect } from 'react'
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import { useState } from 'react'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableTextField from '../general/ReusableTextField'
import { getIntegrityListStats, getParticipantData } from '../../functions/getData'
import LightTable from '../table/LightTable'
import LoadingBackdrop from '../general/LoadingBackdrop'

export default function IntegrityCheckSummaryModal({title , id}) {
    const [data ,setData] = useState()
    const [header, setHeader] = useState([ { label: "Cheating Percentage", state: '', id: 'cheating_percentage', header: 'Cheating Percentage' },
    { label: 'No. of same incorrect answers', state: [], id: 'number_of_same_incorrect_answers', header: 'number of same incorrect answers' },
    { label: 'No. of integrity cases', state: [], id: 'participants_count', header: 'No. of integrity cases' },
    {label:'countries' , state:[] ,id:'countries' , header:'countries' },
    {label:"Computed Grades" , state:[],id:"computed_grades",header:"Computed Grades"},
    {label:"Remaining Grades" , state:[],id:"remaining_grades",header:"Remaining Grades"},
    {label:'Generated Date/Time (UTC)' , state:[],id:'updated_at' , header:'Time Stamp'},
    {label:'run by' , state:[],id:'run_by' , header:'Run By'}

])
  useEffect(()=>{
    getIntegrityListStats(id).then(res=>{
      const allData = res.data;
      allData.map((el)=>{
        el.computed_grades = el.computed_grades==null?el.computed_grades:el.computed_grades.join(', ')
        el.remaining_grades = el.remaining_grades==null?el.remaining_grades:el.remaining_grades.join(', ')
      })
      console.log(res.data)
        setData(allData)
    })
  },[])
   
   
    return  <>
    <LoadingBackdrop loading={data===undefined}/>
    {data!==undefined&&<Box className='popUpModal horizontalScrollable ' overflowX={'scroll'} style={{ width: 1400, minHeight: 400, maxHeight: '90%' }}>
    <NunitoText value={title} fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
    { data.length>0&&<Grid className='tableContainer' container position={'relative'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        <LightTable unsorted={true} insideModal={true} hasPagination={true}  hasSelect={false} selected={[]} selecting={[]} setSelecting={()=>{}} setSelected={()=>{}}  fixed={[]} data={data} headers={header}/>
       
    </Grid>}
    

</Box>}
</>
}