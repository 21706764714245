import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { patchDomain } from '../../functions/patchData'
import { invalidName, makeSureIsArray } from '../../functions/general'
import { showWarningSwal } from '../../functions/alert'
import { showNotification } from '../../functions/snackbar'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
export default function EditDomainModal({ table, id, afterEditingDomain, cancelEdit }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const domain = makeSureIsArray(table.data).find(u => u.id === id)
    const [name, setName] = useState(domain.name)
    const placeholder = domain.is_tag ? 'Tag*' : domain.domain_name ? 'Topic*' : 'Domain*'
    const onSubmit = () => {
        if (domain.name === name) return showWarningSwal('Please make some changes before submitting')
        if (invalidName(name)) return showWarningSwal('Please enter something valid for the field')
        let payload = { id, name }
        patchDomain(payload).then(d => {
            if (d.status === 200) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                afterEditingDomain()
                cancelEdit()
            }
        }).catch(e => console.log(e))
    }
    return (
        <Box className='popUpModal' style={{ width: 900, minHeight: 400 }}>
            <div>
                <NunitoText value='EDIT DOMAIN / TAG' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                    <ReusableTextField type='withLabel' width={530} height={60} bgColor='#F2F2F2'
                        placeholder={placeholder} state={name} setState={setName} required />
                </Grid>
            </div>
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelEdit()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}