//Task
export const addTask = (count) => {
    return {
        type: 'ADD_TASK',
        count: count
    }
}
export const deleteTask = (count) => {
    return {
        type: 'DELETE_TASK',
        count: count
    }
}
export const duplicateTask = (count, task) => {
    return {
        type: 'DUPLICATE_TASK',
        count: count,
        task: task
    }
}
export const updateTask = (count, head, target, payload, e) => {
    return {
        type: 'UPDATE_TASK',
        count: count,
        head: head,
        target: target,
        payload: payload,
        event: e
    }
}
export const resetTask = () => {
    return {
        type: 'RESET_TASK'
    }
}
//Collection
export const addCollection = (count) => {
    return {
        type: 'ADD_COLLECTION',
        count: count
    }
}
export const deleteCollection = (count) => {
    return {
        type: 'DELETE_COLLECTION',
        count: count
    }
}
export const duplicateCollection = (count, collection) => {
    return {
        type: 'DUPLICATE_COLLECTION',
        count: count,
        collection: collection
    }
}
export const updateCollection = (count, head, target, payload, e) => {
    return {
        type: 'UPDATE_COLLECTION',
        count: count,
        head: head,
        target: target,
        payload: payload,
        event: e
    }
}
export const resetCollection = () => {
    return {
        type: 'RESET_COLLECTIONS'
    }
}
//Competition
export const dupeCompetitionFrmDB = (payload) => {
    return {
        type: 'DUPE_FROM_DB',
        payload: payload
    }
}
export const addCompetition = (count) => {
    return {
        type: 'ADD_COMPETITION',
        count: count
    }
}
export const deleteCompetition = (count) => {
    return {
        type: 'DELETE_COMPETITION',
        count: count
    }
}
export const updateCompetition = (count, head, target, payload, e) => {
    return {
        type: 'UPDATE_COMPETITION',
        count: count,
        head: head,
        target: target,
        payload: payload,
        event: e
    }
}
export const resetCompetition = () => {
    return {
        type: 'RESET_COMPETITIONS'
    }
}
//User
export const userLogin = (loggedIn, username, role_id, user_id, country_id, school_id, parent_id, isPrivate, token) => {
    return {
        type: 'LOG_IN',
        loggedIn: loggedIn,
        username: username,
        role_id: role_id,
        user_id: user_id,
        country_id: country_id,
        school_id: school_id,
        parent_id: parent_id,
        isPrivate: isPrivate,
        token: token
    }
}
export const userLogout = () => {
    return {
        type: 'LOG_OUT'
    }
}
//Options
export const setCountryOptions = (options) => {
    return {
        type: 'SET_COUNTRY_OPTIONS',
        options: options
    }
}
export const setRoleOptions = (options) => {
    return {
        type: 'SET_ROLE_OPTIONS',
        options: options
    }
}
export const setLanguageOptions = (options) => {
    return {
        type: 'SET_LANGUAGE_OPTIONS',
        options: options
    }
}