import React, { useState, useEffect, useMemo } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import AddOrganizationModal from '../components/Organization/AddOrganizationModal'
import EditOrganizationModal from '../components/Organization/EditOrganizationModal'
import DeleteModal from '../components/modal/DeleteModal'
import HeavyTable from '../components/table/HeavyTable'
import {
    makeSureIsArray, makeOptions, checkIfAnySelectedRowIs, resetAll,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getOrganizations } from '../functions/getData'
import { deleteOrganization } from '../functions/deleteData'
import { showNotification } from '../functions/snackbar'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
import { isAdmin } from '../functions/checkrole'
const organizationHeader = [
    { id: 'name', label: 'Organization Name' },
    { id: 'country', label: 'Country' },
    { id: 'email', label: 'Email' },
    { id: 'status', label: 'Status' },
    { id: 'address', label: 'Address' },
    { id: 'phone', label: 'Phone Number' },
    { id: 'users', label: 'No. of Partners' },
    { id: 'person_incharge', label: 'Person-in-charge' },
    { id: 'billing_address', label: 'Billing Address' },
    { id: 'created_by', label: 'Created By' },
    { id: 'last_modified_by', label: 'Last Modified By' },
]
const initialParams = '?limits=10'
export default function Organization() {
    document.title = 'Organization'
    const [header, setHeader] = useState(organizationHeader)
    const [organizationTable, setOrganizationTable] = useState()
    const [filtered, setFiltered] = useState(isAdmin() ? [
        { label: 'Country', state: '', key: 'country_id', header: 'countries' }
    ] : [])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Status", state: true, key: 'status' },
        { label: "Address", state: true, key: 'address' },
        { label: "Phone Number", state: true, key: 'phone' },
        { label: 'No. of Partners', state: true, key: 'users' },
        { label: 'Person-in-charge', state: true, key: 'person_incharge' },
        { label: 'Billing Address', state: true, key: 'billing_address' },
        { label: "Created By", state: true, key: 'created_by' },
        { label: 'Last Modified By', state: true, key: 'last_modified_by' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddOrganization, setOnAddOrganization] = useState(false)
    const [onEditOrganization, setOnEditOrganization] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setId)
    const cancelEdit = () => cancelAction(setOnEditOrganization, setId)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const afterFunction = (cancel, data) => {
        if (data.status === 200) {
            resetAll(initialParams, filtered, setParams, setSearch, setFiltered)
            getData(initialParams, signal)
            cancel()
            showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
            setSelected([])
            setSelecting([])
        }
    }

    const deleteFunction = () => {
        deleteOrganization({ id: id === '' ? selected : [id] }).then(d => {
            afterFunction(cancelDelete, d)
        }).catch(e => console.log(e))
    }
    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        loadData(setLoadingTable, setFirstLoaded, getOrganizations, params, setOrganizationTable, setFilterOptions, false, signal)
    }
    const onChangeViewOptions = value => changeViewOptions(organizationHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        changeFiltered(value, filtered, search, rowsPerPage, signal, setFiltered, setParams, getData)
    }
    const onChangeSearch = value => {
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    useEffect(() => {
        getData(initialParams, signal)
        return () => controller.abort()
    }, [controller, signal])
    const onClickMassDelete = () => {
        return organizationTable && checkIfAnySelectedRowIs(selected, organizationTable.data, 'deleted')
            ? showWarningSwal('Please select organizations that aren\'t already deleted to delete')
            : setOnDelete(true)
    }
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Organizations' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div>
            </Grid>
            <Grid className='tableContainer' container>
                <NunitoText value='Organizations' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                    {isAdmin() && <div className='endBtnDiv'>
                        <ReusableButton text='Add Organization' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            onClick={() => setOnAddOrganization(true)} iconType='add' />
                        <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46} marginRight={20}
                            onClick={() => onClickMassDelete()} iconType='delete' />
                    </div>}
                </Grid>
                <NunitoText value='Search only in "Name", "Email", "Address", "Person In Charge" and "Phone Number" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loadingTable && <Loader height={600} />}
                {!loadingTable && organizationTable && makeSureIsArray(organizationTable.data).length &&
                    <HeavyTable headers={header} list={organizationTable} title='organization' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} fixed={['name', 'country', 'email']}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected} setId={setId}
                        setOnEdit={setOnEditOrganization}
                    />}
                {!loadingTable && firstLoaded && organizationTable && !makeSureIsArray(organizationTable.data).length && <NoData height={600} />}
            </Grid>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <>
                    {Boolean(organizationTable) && <DeleteModal table={organizationTable} id={id} selected={selected}
                        deleteFunction={deleteFunction} cancelDelete={cancelDelete} />}
                </>
            </Modal>
            <Modal open={onAddOrganization} onClose={() => setOnAddOrganization(false)}>
                <>
                    <AddOrganizationModal setLoading={setLoading} setOnAddOrganization={setOnAddOrganization}
                        afterAddingOrganization={afterFunction} />
                </>
            </Modal>
            <Modal open={onEditOrganization} onClose={() => cancelEdit()}>
                <>
                    {organizationTable && <EditOrganizationModal setLoading={setLoading} table={organizationTable} id={id}
                        afterEditingOrganization={afterFunction} cancelEdit={cancelEdit} />}
                </>
            </Modal>
        </Box>
    )
}