import { styled, Grid } from '@mui/material'
// import MuiAppBar from '@mui/material/AppBar'
import MuiDrawer from '@mui/material/Drawer'
const drawerWidth = 300
// const commonAppBarStyle = (theme, open) => ({
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(['width', 'margin'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...(open && {
//         marginLeft: drawerWidth,
//         width: `calc(100% - ${drawerWidth}px)`,
//         transition: theme.transitions.create(['width', 'margin'], {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//     }),
// })
// export const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
//     ({ theme, open }) => ({
//         height: 64,
//         backgroundColor: '#5E75C3',
//         ...commonAppBarStyle(theme, open),
//     }))
// export const BottomAppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
//     ({ theme, open }) => ({
//         top: 'auto',
//         bottom: 0,
//         backgroundColor: '#144A94',
//         ...commonAppBarStyle(theme, open),
//     }))
const commonAppBarStyle = open => ({
    zIndex: 1300, position: 'fixed',
    width: open && `calc(100% - ${drawerWidth}px)`,
    marginLeft: open && drawerWidth,
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
})
export const AppBar = ({ open, children }) => {
    const appBarStyle = { ...commonAppBarStyle(open), backgroundColor: '#5E75C3', height: 64, paddingInline: 24 }
    return (
        <Grid container alignItems='center' justifyContent='space-between' style={appBarStyle}>
            {children}
        </Grid>
    )
}
export const BottomAppBar = ({ open, children }) => {
    const bottomAppBarStyle = { ...commonAppBarStyle(open), backgroundColor: '#144A94', height: 48, top: 'auto', bottom: 0 }
    return (
        <Grid container alignItems='center' justifyContent='center' style={bottomAppBarStyle}>
            {children}
        </Grid>
    )
}
const commonMixin = theme => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
    }),
    overflowX: 'hidden',
    backgroundColor: '#5E75C3',
    color: '#FFFFFF'
})
const openedMixin = theme => ({
    ...commonMixin(theme),
    width: drawerWidth
})
const closedMixin = theme => ({
    ...commonMixin(theme),
    width: 60,
    [theme.breakpoints.up('sm')]: {
        width: 60
    },
})
export const DrawerHeader = styled('div')
    (({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }))
export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        })
    }),
)