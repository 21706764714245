import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import { makeSureIsArray } from '../../functions/general'
export default function ReasonModal({ table, id}) {
    const selectedRow = makeSureIsArray(table.data).find(s => s.id === id)
    console.log(selectedRow.reject_reasons)
    return (
        <Box className='popUpModal'  style={{ width: 880, minHeight: 630 ,display:'block'}}>
            <NunitoText value='Rejection Reasons' fontSize={30} fontWeight={600} color='#F16774' marginTop={20} marginBottom={20} />
           {selectedRow.reject_reasons.map(el=>{
            return <NunitoText value={el.reason} fontSize={25} fontWeight={600} marginTop={10}  />
           })}
        </Box>
    )
}