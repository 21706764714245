import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateTask } from '../../actions'
import NunitoText from '../general/NunitoText'
import ReusableToggle from '../general/ReusableToggle'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import Answer from './Answer'
const containerStyle = { marginTop: 20 }
const uploadImageContainerStyle = { marginTop: 30 }
const answersContainerStyle = { margin: '10px 30px 30px 30px' }
export default function AnswerKey({ count, task }) {
    const [expand, setExpand] = useState(true)
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
    const { uploadImage, answers } = task
    return (
        <Grid container style={containerStyle}>
            <TitleBar title='Answer Key' state={expand} setState={setExpand} />

            {expand && <AddPageWrapper>
                <Grid container justifyContent='center' alignItems='center' style={uploadImageContainerStyle}>
              

                    <NunitoText value='Upload Answers as Image' fontSize={22} fontWeight={600} marginRight={10} />
                    <ReusableToggle type='reduxIcon' width={124} state={uploadImage} setState={update}
                        count={count} head='taskBoolean' target='uploadImage' marginRight={20} />
                </Grid>
                <Grid container style={answersContainerStyle}>
                    {/* {answers.map((a, i) => <Grid container key={i}>{JSON.stringify(a)}</Grid>)} */}
                    {answers.map((answer, index) => (
                        <Answer key={index} count={count} answerCount={index} task={task} answer={answer} />
                    ))}
                </Grid>
            </AddPageWrapper>}
        </Grid >
    )
}