import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"
import { List, ListItem, ListItemIcon, ListItemText, IconButton, Collapse } from '@mui/material'
import { useSelector } from 'react-redux'
import { isAdmin, isAdminOrPartnerOrAssistantOrManager, isAdminOrPartnerOrAssistant } from '../functions/checkrole'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
// import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
    FaTools, FaUsers, FaNewspaper,
    // FaDatabase,
    // FaGraduationCap
} from 'react-icons/fa'
import {GiCheckedShield} from 'react-icons/gi'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { DrawerHeader, Drawer } from './DrawerAndAppBar'
const faIconStyle = { fontSize: 28 }
// const muiIconStyle = { fontSize: 30 }
// const ioIconStyle = { fontSize: 32 }
const nested = () => ([
    [
        isAdmin() && 'Organizations',
        // 'Translations',
        isAdmin() && 'Difficulty Group',
        isAdmin() && 'Domain',
        // 'Award',
        // 'Countries',
        // 'Grades',
        // 'Topics',
        // 'Currencies',
        isAdmin() && 'Task',
        // 'Task Moderation',
        isAdmin() && 'Collection'
    ],
    [
        
        'Collections',
        'Tasks'
    ],
    [
        'Users',
        // 'Administrators',
        // 'Country Partners',
        // 'Teacher Super Admins',
        // 'Role',
        // 'Add Role'
    ],
    [
        'Students',
        'Merge Student'
    ],
    [
        isAdminOrPartnerOrAssistant() && 'Competitions',
        'Schools',
        // isAdmin() && 'Pending Competition Partners',
        'Participants',
        // 'Competition Sessions'
    ],
    [
        'Books',
        'Announcements',
        'Advertisements'
    ],
    [
        'Competition Participation',
        'Competition Session Status'
    ]
].map(n => n.filter(Boolean)))
const allBtns = () => ([
    // { title: 'Dashboard', icon: <HomeIcon style={muiIconStyle} /> },
    { title: 'Setup', icon: <FaTools style={faIconStyle} />, child: nested()[0], openLinks: false },
    //  {title:'Moderation' , icon:<GiCheckedShield style={faIconStyle}/>,child: nested()[1], openLinks: false },
    // isAdminOrPartner() && { title: 'Manage Users', icon: <FaUsers style={faIconStyle} />, child: nested()[1], openLinks: false },
    isAdminOrPartnerOrAssistantOrManager() && { title: 'Users', icon: <FaUsers style={faIconStyle} /> },
    // { title: 'Manage Students', icon: <FaGraduationCap style={faIconStyle} />, child: nested()[2], openLinks: false },
    { title: 'Manage Competitions', icon: <FaNewspaper style={faIconStyle} />, child: nested()[4], openLinks: false },
    // { title: 'Administrations', icon: <FaDatabase style={faIconStyle} />, child: nested()[4], openLinks: false },
    // { title: 'Reports', icon: <IoIosDocument style={ioIconStyle} />, child: nested()[5], openLinks: false },
    // { title: 'Mail Templates', icon: <MailIcon style={muiIconStyle} /> },
    // { title: 'Settings', icon: <SettingsIcon style={muiIconStyle} /> }
])
export default function SideBar({ open, toggleDrawer, hoverDrawer, unhoverDrawer }) {
    const user = useSelector(state => state.user)
    const [btns, setBtns] = useState(allBtns())
    useEffect(() => setBtns(allBtns()), [user])
    let location = useLocation()
    //Open one sub list from the drawer at a time(Will close other sub list if any is open at the moment)
    const openNested = title => {
        let newBtns = [...btns]
        newBtns.filter(b => b.child !== undefined).forEach(btn => {
            if (btn.title === title) btn.openLinks = !btn.openLinks
            else btn.openLinks = false
        })
        setBtns(newBtns)
    }
    const headListItemStyle = child => ({
        color: child.some(el => location.pathname.slice(1) === el.replace(/\s+/g, '').toLowerCase()) ? '#454E6F' : '#FFF'
    })
    const listItemStyle = link => ({
        color: location.pathname.slice(1) === link.replace(/\s+/g, '').toLowerCase() ? '#454E6F' : '#FFF'
    })
    const CollapsibleLink = ({ title, icon, child, openLinks }) => (
        <>
            <ListItem button key={title} onClick={() => openNested(title)}>
                <ListItemIcon style={headListItemStyle(child)}>{icon}</ListItemIcon>
                <ListItemText primary={title} style={headListItemStyle(child)} />
                {openLinks ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openLinks && open} timeout='auto'>
                {child.map((link, i) => (
                    <List component="div" disablePadding key={i}>
                        <Link to={`/${link.replace(/\s+/g, '').toLowerCase()}`} style={{ textDecoration: 'none' }}>
                            <ListItem button style={{ ...listItemStyle(link), paddingLeft: 80 }}>
                                <ListItemText primary={link} />
                            </ListItem>
                        </Link>
                    </List>
                ))}
            </Collapse>
        </>
    )
    const NormalLink = ({ title, icon }) => (
        <Link to={`/${title.replace(/\s+/g, '').toLowerCase()}`} style={{ textDecoration: 'none' }}>
            <ListItem button style={listItemStyle(title)}>
                <ListItemIcon style={listItemStyle(title)}>{icon}</ListItemIcon>
                <ListItemText primary={title} />
            </ListItem>
        </Link>
    )
    return (
        <>
            <Drawer variant="permanent" open={open} onMouseEnter={() => hoverDrawer()} onMouseLeave={() => unhoverDrawer()}>
                <DrawerHeader>
                    <IconButton onClick={() => toggleDrawer(false)} style={{ color: '#FFF' }}>
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>
                <List>
                    {btns.filter(Boolean).map(({ title, icon, child, openLinks }) => (
                        <div key={title}>
                            {child === undefined ? NormalLink({ title, icon }) :
                                child.length ? CollapsibleLink({ title, icon, child, openLinks }) : null}
                        </div>
                    ))}
                </List>
            </Drawer>
            <DrawerHeader />
        </>
    )
}