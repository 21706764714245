import React from "react";
import { Divider } from "@mui/material";
import NunitoText from "./NunitoText";
export default function InputContainer({
  label,
  field,
  first,
  last,
  labelColor,
}) {
  const inputContainerStyle = {
    marginBottom: last && 30,
    marginInline: 36,
    paddingTop: first && 30,
    display: "flex",
    alignItems: "center",
    width: "100%",
  };
  const labelDivStyle = { marginTop: !first && 30, width: "17%" };
  const dividerDivStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    width: "8%",
  };
  const dividerStyle = { width: 2, backgroundColor: "#000" };
  const fieldDivStyle = { marginTop: !first && 30, width: "75%" };
  return (
    <div style={inputContainerStyle}>
      <div style={labelDivStyle}>
        <NunitoText
          value={label}
          fontSize={20}
          fontWeight={600}
          align="left"
          color={labelColor ? labelColor : "black"}
        />
      </div>
      <div style={dividerDivStyle}>
        <Divider orientation="vertical" flexItem style={dividerStyle} />
      </div>
      <div style={fieldDivStyle}>{field}</div>
    </div>
  );
}
