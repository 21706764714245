import { Grid } from "@mui/material"
import EnhancedFilter from "../EnhancedFilter"
import ReusableButton from "./ReusableButton"

 const FilterWrapper = ({filters , adjustEndpoint})=>{
    const resetFilters = ()=>{
        filters.map((filter)=>{
            filter.setValue('')
        })
      }
    return   <Grid margin={'2%'} flexDirection={'column'}>
      { filters.map((filter)=>{   return <EnhancedFilter
        label={filter.label}
        endpoint={adjustEndpoint(filter.endpoint,filter.value)}
        value={filter.value}
        onChange={filter.onChange}
      />})}
       
      <br/>
    <ReusableButton
      marginTop={'2%'}
      text="Reset filter"
      bgColor="#F16774"
      fontSize={16}
      br={8}
      onClick={resetFilters}
      iconType="reset"
    />
      </Grid>
    
}
export default FilterWrapper