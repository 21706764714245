import React, { useState, useEffect } from 'react'
import { Grid, Box, Select, MenuItem } from '@mui/material'
import { useLocation } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FaInfoCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { getCompetitions } from '../functions/getData'
import { isAdmin, isPartner } from '../functions/checkrole'
import { showWarningSwal } from '../functions/alert'
import LightTooltip from '../components/general/LightTooltip'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableDatePicker from '../components/general/ReusableDatePicker'
import ReusableToggle from '../components/general/ReusableToggle'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import TitleBar from '../components/general/TitleBar'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import InputContainer from '../components/general/InputContainer'
const competitionContainerStyle = { backgroundColor: '#F3F0F4', borderRadius: '0 0 12px 12px' }
const dateContainerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }
const selectWrapperStyle = { display: 'flex', alignItems: 'center' }
const infoStyle = { color: '#000', fontSize: 14 }
const iconStyle = { color: '#67A8F3', fontSize: 32, marginLeft: 20 }
const populate = list => {
    let foundCompetition = list
    if (foundCompetition === undefined) return showWarningSwal('Competition not found')
    if (foundCompetition.status !== 'active') return showWarningSwal('Invalid competition')
    foundCompetition.allowed_grades = foundCompetition.allowed_grades.map(g => Number(g))
    foundCompetition.global_registration_date = new Date(foundCompetition.global_registration_date).getTime()
    foundCompetition.global_registration_end_date = new Date(foundCompetition.global_registration_end_date).getTime()
    foundCompetition.competition_start_date = new Date(foundCompetition.competition_start_date).getTime()
    foundCompetition.competition_end_date = new Date(foundCompetition.competition_end_date).getTime()
    foundCompetition.competition_organization.forEach(o => {
        o.registration_open_date = new Date(o.registration_open_date).getTime()
        o.originalDates = o.dates.map(d => new Date(d).getTime())
        o.dates = o.dates.map(d => new Date(d).getTime())
        o.type = o.competition_mode.toString()
        o.translate = ['', '[]'].includes(o.translate) ? [] : JSON.parse(o.translate)
        o.translating = Boolean((o.translate).length)
        o.allowEdit = false
        o.languagesToView = []
        o.languageToTranslate = []
        o.originalStatus = o.status
    })
    return foundCompetition
}
const StatusAndInfo = ({ state, originalStatus }) => {
    let color = ''
    switch (state) {
        case 'active': color = '#6FC9B6'; break;
        case 'ready': color = '#8E7DD0'; break;
        case 'lock': color = '#F3A867'; break;
        case 'added': color = 'grey'; break;
        default: color = '#000';
    }
    const selectStyle = {
        height: 50, width: 210, backgroundColor: '#FFF',
        border: `1px solid ${color}`, color, outline: 'none',
        pointerEvents: 'none'
    }
    const selectAttributes = {
        value: state,
        style: selectStyle,
        IconComponent: ExpandMoreIcon,
        SelectDisplayProps: {
            style: { outline: 'none', cursor: isPartner() && state === 'lock' && 'not-allowed' }
        },
        readOnly: isPartner() && state === 'lock'
    }
    return (
        <>
            <Select {...selectAttributes}>
                <MenuItem value='added' style={{ display: originalStatus !== 'added' && 'none' }}>Added</MenuItem>
                <MenuItem value='active'>Active</MenuItem>
                <MenuItem value='ready'>Ready</MenuItem>
                <MenuItem value='lock' style={{ display: !isAdmin() && 'none' }}>Lock</MenuItem>
            </Select>
            <LightTooltip title={<Info />} rollover={1} topic={0} hide={0}>
                <div><FaInfoCircle style={iconStyle} /></div>
            </LightTooltip>
        </>
    )
}
const Info = () => <div>
    <p style={infoStyle}><span style={{ color: '#6FC9B6' }}>Active</span> - Teachers and Country Partners can upload</p>
    <p style={infoStyle}><span style={{ color: '#8E7DD0' }}>Ready</span> - Only Country Partners can upload</p>
    <p style={infoStyle}><span style={{ color: '#F3A867' }}>Locked</span> - Only Admins can upload</p>
</div>
const Competition = ({ competition }) => {
    let count = 0
    let foundOrganization = competition.competition_organization[count]
    const { name, global_registration_date, global_registration_end_date,
        competition_start_date, competition_end_date, format } = competition
    const { registration_open_date, dates, status, originalStatus, type,
        translate, translating } = foundOrganization
    const languageOptions = useSelector(state => state.languageOptions)
    const modes = ['Paper Based', 'Online', 'Both']
    return (
        <Grid container style={{ marginBottom: 20 }}>
            <TitleBar title={name} state={true} main fixed />
            {/* loading ? <Loader height={280} /> : */}
            <Grid container style={competitionContainerStyle}>
                <InputContainer label='Competition Name' first
                    field={<ReusableTextField type='default' placeholder='Competition Name' state={name}
                        borderColor='#000' required readOnly disabled fullWidth />} />
                <InputContainer label={`Global Registration Start ${format ? 'and End ' : ''}Date`}
                    field={<div style={dateContainerStyle}>
                        <ReusableDatePicker width={250} state={global_registration_date}
                            required disabled />
                        {Boolean(format) && <>
                            <NunitoText value='TO' fontSize={20} fontWeight={600} italic />
                            <ReusableDatePicker width={250} state={global_registration_end_date}
                                required disabled />
                        </>}
                    </div>} />
                <InputContainer label='Global Competition Start and End Date'
                    field={<div style={dateContainerStyle}>
                        <ReusableDatePicker width={250} state={competition_start_date} required disabled />
                        <NunitoText value='TO' fontSize={20} fontWeight={600} italic />
                        <ReusableDatePicker width={250} state={competition_end_date} required disabled />
                    </div>} />
                <InputContainer label='Country Registration Start Date*'
                    field={<ReusableDatePicker width={250} state={registration_open_date} required disabled />} />
                <InputContainer label='Country Competition Dates*'
                    field={<ReusableDatePicker width={250} state={dates} multiple required disabled />} />
                <InputContainer label='Status*'
                    field={<div style={selectWrapperStyle}>
                        <StatusAndInfo state={status} originalStatus={originalStatus} />
                    </div>} />
                <InputContainer label='Mode of Competition' field={modes[type]} />
                <InputContainer last label='Translation'
                    field={<div style={{ display: 'flex' }}>
                        <ReusableToggle type='text' width={160} falseValue='No' trueValue='Yes'
                            state={translating} target='translating' disabled grayedOut />
                        <ReusableAutocompleteWithID type='default' placeholder='Languages' width={460} height={55} marginLeft={30}
                            bgColor='#FFF' btnBgColor='#144A94' btnWidth={64} state={translate} multiple
                            borderColor='#707070' options={languageOptions.map(l => ({ id: l.id, option: l.name }))}
                            grayedOut readOnly />
                    </div>} />
            </Grid>
        </Grid >
    )
}
export default function CompetitionDetails() {
    document.title = 'Competition Details'
    let location = useLocation()
    const [loading, setLoading] = useState(true)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [competition, setCompetition] = useState()
    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        if (location.search.slice(0, 4) === '?id=') {
            getCompetitions(`/${location.search.slice(4)}`, signal).then(c => {
                console.log(c)
                let foundCompetition = populate(c)
                setCompetition(foundCompetition)
            }).catch(e => console.log(e)).finally(() => {
                if (!signal.aborted) {
                    setLoading(false)
                    setFirstLoaded(true)
                }
            })
        } else {
            showWarningSwal('Wrong query')
            if (!signal.aborted) {
                setLoading(false)
                setFirstLoaded(true)
            }
        }
        return () => controller.abort()
    }, [location.search])
    return (
        <Box className='wrapperBox'>
            <div className='firstRowContainer dashboardAndSelfBtnDiv'>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                <ChevronRightIcon />
                <ReusableButton text='Competitions' fontSize={14} bgColor='#F16774' height={36} br={18} to='/competitions' />
            </div>
            <div className='formContainer'>
                <NunitoText value='Competition Details' fontSize={40} fontWeight={700} italic color='#144A94' align='left' />
                {loading ? <Loader height={600} /> : competition ? <Competition competition={competition} /> :
                    firstLoaded && <NoData height={600} />}
            </div>
        </Box>
    )
}