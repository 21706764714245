import React, { useState, useEffect, useRef } from 'react'
import { Box, Grid, Tabs, Tab, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { showWarningSwal, showInfoSwal } from '../../functions/alert'
import { addCompetitionRound } from '../../functions/postData'
import CloseIcon from '@mui/icons-material/Close'
import AddedLevel from './AddedLevel'
import InputContainer from '../general/InputContainer'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import ReusableToggle from '../general/ReusableToggle'
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
const radioStyle = { color: '#000' }
const radioGroupContainerStyle = {
   marginTop: -180, height: 150, marginLeft: 750
}
const radioGroupStyle = {
   display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: 30,
   backgroundColor: '#FFF', width: 450, border: '1px solid', borderRadius: 16, marginLeft: 36
}
const levelsContainerStyle = { marginTop: 40, paddingInline: 36 }
function EachForm({ forms, setForms, count, collectionOptions, competition }) {
   const { label, roundType, teamSetting, individualPoints, levels } = forms[count]
   const updateForm = (count, identifier, value) => {
      let newForms = [...forms]
      switch (identifier) {
         case 'addLevel':
            newForms[count].levels.push({
               name: '',
               collection: '',
               grades: []
            })
            break;
         case 'deleteLevel':
            newForms[count[0]].levels.splice(count[1], 1)
            break;
         default:
            if (typeof count === 'object') newForms[count[0]].levels[count[1]][identifier] = value
            else {
               newForms[count][identifier] = value
               if (identifier === 'roundType' && !value) {
                  newForms[count].individualPoints = false
                  newForms[count].teamSetting = ''
               }
            }
      }
      setForms(newForms)
   }
   return (
      <Grid container>
         <InputContainer first label='Round Label*'
            field={<ReusableTextField type='default' placeholder='Round Label*' state={label} count={count} target='label'
               setState={updateForm} bgColor='#F2F2F2' borderColor='#707070' required onBlur fullWidth />} />
         {roundType ? <>
            <InputContainer label='Competition Configuration'
               field={<ReusableToggle type='text' width={270} falseValue='Individual' trueValue='Team'
                  state={roundType} count={count} target='roundType' setState={updateForm} />} />
            <InputContainer last label='Contribute to individual score?'
               field={<ReusableToggle type='icon' width={124} state={individualPoints} count={count} target='individualPoints'
                  setState={updateForm} />} />
         </> : <InputContainer label='Competition Configuration'
            field={<ReusableToggle type='text' width={270} falseValue='Individual' trueValue='Team'
               state={roundType} count={count} target='roundType' setState={updateForm} />} />}
         {!!roundType && <Grid container style={radioGroupContainerStyle}>
            <RadioGroup style={radioGroupStyle} value={teamSetting} onChange={e => updateForm(count, 'teamSetting', e.target.value)}>
               <FormControlLabel value="0" control={<Radio style={radioStyle} />}
                  label="1 Account answers tasks" />
               <FormControlLabel value="1" control={<Radio style={radioStyle} />}
                  label="Tasks assigned by leader" />
               <FormControlLabel value="2" control={<Radio style={radioStyle} />}
                  label="Free For All" />
            </RadioGroup>
         </Grid>}
         <Grid container style={levelsContainerStyle}>
            <Grid container>
               <NunitoText value='Levels' fontSize={20} fontWeight={600} marginBottom={10} align='left' />
            </Grid>
            {levels.map((level, index) => (
               <AddedLevel key={index} count={count} level={level} levels={levels} levelCount={index}
                  updateForm={updateForm} allowedGrades={competition.allowed_grades} collectionOptions={collectionOptions} />
            ))}
         </Grid>
         <Grid container justifyContent='flex-end' style={buttonsContainerStyle}>
            <ReusableButton text='Add Level' bgColor='#5E75C3' fontSize={15} allowSpam
               onClick={() => updateForm(count, 'addLevel')} iconType='add' />
         </Grid>
      </Grid>
   )
}
const boxStyle = { width: 1500, minHeight: 400, maxHeight: '90%' }
const addRoundButtonDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -45, marginRight: 20 }
const divStyle = {
   borderRadius: 10, margin: '20px 20px 0 20px',
   boxShadow: '0px 1px 6px rgb(0,0,0,0.2), 0 1px 6px rgb(0,0,0,0.2)'
}
const tabPropsStyle = { height: 0 }
const tabStyle = (value, i) => ({
   color: value !== i && '#707070',
   backgroundColor: value !== i && '#E7E6E6',
   border: value === i && '1px solid #F0F0F0',
   boxShadow: value === i && '0px 1px 6px rgb(0,0,0,0.2), 0px 1px 6px rgb(0,0,0,0.2)',
   borderRadius: '10px 10px 0 0',
   textTransform: 'none',
   marginRight: 10, height: 40
})
const iconStyle = { position: 'relative', left: 12, height: 18, width: 18, color: '#707070' }
const buttonsContainerStyle = { paddingInline: 60, marginBlock: 30 }
export default function AddRoundModal({ setLoading, collectionOptions, competition, cancelAddRound, afterAddingRound }) {
   const isMounted = useRef(false)
   const [forms, setForms] = useState([{
      id: 1, label: '', roundType: false, teamSetting: '', individualPoints: false,
      levels: [{ name: '', collection: '', grades: [] }]
   }])
   const [value, setValue] = useState(0)
   const addForm = () => {
      if (forms.length >= 5) return showInfoSwal('Maximum number of tab is 5')
      setForms([...forms, {
         id: forms.map(f => f.id).reduce((a, b) => Math.max(a, b), 0) + 1,
         label: '', roundType: false, teamSetting: '', individualPoints: false,
         levels: [{ name: '', collection: '', grades: [] }]
      }])
      setValue(forms.length)
   }
   const deleteForm = (e, id) => {
      e.stopPropagation()
      let newForms = forms.filter(f => f.id !== id)
      if (!newForms.length) return cancelAddRound()
      let index = forms.findIndex(f => f.id === id)
      if (value === forms.length - 1 || index < value) setValue(value - 1)
      setForms(newForms)
   }
   const handleChange = (e, newValue) => setValue(newValue)
   const onSubmit = () => {
      let payload = { competition_id: competition.id, rounds: [] ,      }
      let msg = ''
      for (let i = 0; i < forms.length; i++) {
         const { label, roundType, teamSetting, individualPoints, levels } = forms[i]
         let warningMessage = ''
         if (label === '') warningMessage += `Round ${i + 1}'s name is empty.<br>`
         if (levels.length) {
            levels.forEach((level, j) => {
               if (level.name === '') warningMessage += `Round ${i + 1} Level ${j + 1}'s name is empty.<br>`
               if (level.collection === '') warningMessage += `Round ${i + 1} Level ${j + 1}'s collection is empty.<br>`
               if (level.grades.length === 0) warningMessage += `Round ${i + 1} Level ${j + 1}'s grades are empty.<br>`
            })
         } else warningMessage += `Round ${i + 1} must have at least one level.<br>`
         if (warningMessage) {
            msg += '<div>'
            msg += `<div style="text-align:center; font-weight: 800">Error in Round ${i + 1}</div>`
            msg += `<div style="text-align:left">${warningMessage}</div>`
            msg += '</div>'
         } else {
            let obj = {
               name: label,
               round_type: Number(roundType),
               award_type : 0,
               assign_award_points : 0,
               default_award_name : "Participation",
               levels: levels.map(level => ({
                  name: level.name,
                  collection_id: level.collection,
                  grades: level.grades
                
               }))
            }
            if (roundType) {
               obj.team_setting = teamSetting
               obj.individual_points = Number(individualPoints)
            }
            payload.rounds.push(obj)
         }
      }
      if (msg) return showWarningSwal(msg)
      setLoading(true)
      console.log(payload)
      addCompetitionRound(payload).then(d => {
         console.log(d)
         isMounted.current && setLoading(false)
         afterAddingRound(cancelAddRound, d, payload.rounds)
         window.location.reload()
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   useEffect(() => {
      isMounted.current = true
      return () => isMounted.current = false
   }, [])
   return (
      <Box className='popUpModal horizontalScrollable' style={boxStyle}>
         <div>
            <NunitoText value='ADD ROUNDS' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
            <div style={addRoundButtonDivStyle}>
               <ReusableButton text='Add another award' fontSize={15} height={40} bgColor='#5E75C3'
                  iconType='add' onClick={() => addForm()} allowSpam />
            </div>
         </div>
         <div style={divStyle}>
            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: tabPropsStyle }}>
               {forms.map((f, i) => <Tab key={f.id} label={<div style={centerFlexStyle}>
                  Round {i + 1}
                  <CloseIcon style={iconStyle} onClick={e => deleteForm(e, f.id)} />
               </div>} style={tabStyle(value, i)} />)}
            </Tabs>
            <EachForm forms={forms} setForms={setForms} count={value}
               collectionOptions={collectionOptions} competition={competition} />
         </div>
         <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
            <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
               onClick={() => cancelAddRound()} />
            <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
               onClick={() => onSubmit()} />
         </Grid>
         {/* <Grid container>
            <InputContainer first label='Round Label'
               field={<ReusableTextField type='default' placeholder='Round Label' state={label}
                  setState={setLabel} bgColor='#F2F2F2' borderColor='#707070' onBlur fullWidth />} />
            {roundType ? <>
               <InputContainer label='Competition Configuration'
                  field={<ReusableToggle type='text' width={270} falseValue='Individual' trueValue='Team'
                     state={roundType} setState={setRoundType} />} />
               <InputContainer last label='Contribute to individual score?'
                  field={<ReusableToggle type='icon' width={124} state={individualPoints}
                     setState={setIndividualPoints} />} />
            </> : <InputContainer label='Competition Configuration'
               field={<ReusableToggle type='text' width={270} falseValue='Individual' trueValue='Team'
                  state={roundType} setState={setRoundType} />} />}
            {!!roundType && <Grid container style={radioGroupContainerStyle}>
               <RadioGroup style={radioGroupStyle} value={teamSetting} onChange={e => setTeamSetting(e.target.value)}>
                  <FormControlLabel value="0" control={<Radio style={radioStyle} />}
                     label="1 Account answers tasks" />
                  <FormControlLabel value="1" control={<Radio style={radioStyle} />}
                     label="Tasks assigned by leader" />
                  <FormControlLabel value="2" control={<Radio style={radioStyle} />}
                     label="Free For All" />
               </RadioGroup>
            </Grid>}
            <Grid container style={levelsContainerStyle}>
               <Grid container>
                  <NunitoText value='Levels' fontSize={20} fontWeight={600} marginBottom={10} align='left' />
               </Grid>
               {levels.map((level, index) => (
                  <AddedLevel key={index} count={index} level={level} levels={levels}
                     setLevels={setLevels} allowedGrades={competition.allowed_grades} collectionOptions={collectionOptions} />
               ))}
            </Grid>
            <Grid container justifyContent='space-between' style={buttonsContainerStyle}>
               <ReusableButton text='Add Level' bgColor='#5E75C3' fontSize={15} allowSpam
                  onClick={() => addLevel()} iconType='add' />
               <ReusableButton text='Save' bgColor='#5E75C3' fontSize={15}
                  onClick={() => onSubmit()} iconType='save' />
            </Grid>
         </Grid> */}
      </Box>
   )
}