import React, { useState, useEffect, useRef } from 'react'
import { Grid, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { gradeOptions, formatDateTime } from '../../functions/general'
import { showNotification } from '../../functions/snackbar'
import { showWarningSwal, isEmpty } from '../../functions/alert'
import { deepCopy, isUnchanged } from '../../functions/edit'
import { patchCompetition } from '../../functions/patchData'
import ParticipatingCountries from './ParticipatingCountries'
import Rounds from './Rounds'
import Awards from './Awards'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import InputContainer from '../general/InputContainer'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableDatePicker from '../general/ReusableDatePicker'
import ReusableToggle from '../general/ReusableToggle'
import ReusableButton from '../general/ReusableButton'
import NunitoText from '../general/NunitoText'
import DescriptionEditor from '../DescriptionEditor'
import LoadingBackdrop from '../general/LoadingBackdrop'
import { useSnackbar } from 'notistack'
const competitionContainerStyle = { backgroundColor: '#F3F0F4', borderRadius: '0 0 12px 12px' }
const radioStyle = { color: '#000' }
const dateContainerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }
export default function Competition({ original, setOriginal, competition, setCompetition,
    difficultyGroupOptions, competitionOptions, collectionOptions , tagOptions }) {
    const isMounted = useRef(false)
    const [expand, setExpand] = useState(true)
    const [expandSetting, setExpandSetting] = useState(true)
    const [loading, setLoading] = useState(false)
    const [instructions, setInstructions] = useState('')
    const updateCompetition = (identifier, value) => {
        console.log(identifier , value)
        let newCompetition = { ...competition }
        newCompetition[identifier] = value
        console.log(newCompetition)
        setCompetition(newCompetition)
    }
    const {
        id, name, alias, global_registration_date, global_registration_end_date, competition_start_date, competition_end_date,
        allowed_grades, rerun, format, difficulty_group_id, competition_mode , tags
    } = competition
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const validateAlias = (alias)=>{
        let result=''
        for(let i =0;i<alias.length;i++){
            if((alias[i].charCodeAt(0)>=65 &&alias[i].charCodeAt(0)<=90)||(alias[i].charCodeAt(0)>=97 &&alias[i].charCodeAt(0)<=122)||(alias[i].charCodeAt(0)==32&&(i!==0&&i!==alias.length-1))||(alias[i].charCodeAt(0)>=48 &&alias[i].charCodeAt(0)<=57)||alias[i].charCodeAt(0)==95){
                if(alias[i].charCodeAt(0)==32){
                    result+='_'
                }else{
                result+=alias[i]}
            }
        }
        return result
    }
    const onSubmit = () => {
        const settingsKeyArr = ['name', 'alias','tags', 'global_registration_date', 'global_registration_end_date', 'competition_start_date',
            'competition_end_date', 'allowed_grades', 'rerun', 'format', 'difficulty_group_id', 'competition_mode']
        const originalSettings = settingsKeyArr.reduce((obj, header) => ({ ...obj, [header]: original[header] }), {})
        const competitionSettings = settingsKeyArr.reduce((obj, header) => ({ ...obj, [header]: competition[header] }), {})
        if (isUnchanged(originalSettings, competitionSettings)) {
            return showWarningSwal('Please make some changes before submitting')
        }
        let msg = ''
        let arr = [
            { name: 'Competition Name', state: name },
            { name: 'Alias', state: validateAlias(alias) },
            { name: 'Global Competition Start Date', state: competition_start_date },
            { name: 'Global Competition End Date', state: competition_end_date },
            { name: 'Difficulty Group', state: difficulty_group_id }
        ]
        if (isEmpty(arr.map(a => a.state))) {
            arr.filter(a => a.state === '').forEach(a => msg += `Please fill in ${a.name}.<br>`)
        }
        if (format) {
            if (global_registration_date > global_registration_end_date) {
                msg += 'Global Registration End Date must be after or equal to Global Registration Start Date.<br>'
            }
            if (global_registration_end_date >= competition_start_date) {
                msg += 'Global Registration End Date must be before Competition Start Date if Competition Format is Global.<br>'
            }
        } else {
            if (global_registration_date > competition_start_date) {
                msg += 'Global Registration Start Date must be before or equal Competition Start Date if Competition Format is Local.<br>'
            }
        }
        if (competition_start_date > competition_end_date) {
            msg += 'Global Competition End Date must be after Global Competition Start Date.<br>'
        }
        if (!allowed_grades.length) {
            msg += 'Please add at least one grades participating.<br>'
        }
        if (msg) return showWarningSwal(msg)
        actuallySubmit()
    }
    const actuallySubmit = () => {
        let payload = {
            name:name,
            tags:tags?.map(t=> (t.id||t)),
            alias:validateAlias(alias),
            global_registration_date: formatDateTime(global_registration_date, '/'),
            competition_start_date: formatDateTime(competition_start_date, '/'),
            competition_end_date: formatDateTime(competition_end_date, '/'),
            allowed_grades: allowed_grades,
            competition_mode: Number(competition_mode),
            difficulty_group_id: difficulty_group_id,
            format: Number(format),
            "re-run": 0,
            parent_competition_id: null
        }
        let globalpayload={
            name:name,
            tags:tags?.map(t=> (t.id||t)),
            alias:validateAlias(alias),
            global_registration_date: formatDateTime(global_registration_date, '/'),
            competition_start_date: formatDateTime(competition_start_date, '/'),
            competition_end_date: formatDateTime(competition_end_date, '/'),
            allowed_grades: allowed_grades,
            competition_mode: Number(competition_mode),
            global_registration_end_date:formatDateTime(global_registration_end_date,'/'),
            difficulty_group_id: difficulty_group_id,
            format: Number(format),
            "re-run": 0,
            parent_competition_id: null
        }
        if (name !== original.name) payload.name = name
        if (alias !== original.alias) payload.alias = validateAlias(alias)
        setLoading(true)
        console.log("payload patch" ,payload)
        patchCompetition(id ,  format?globalpayload:payload).then(d => {
            if (d.status === 200) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                if (isMounted.current) {
                    let newCompetition = window.structuredClone(competition)
                    setCompetition(newCompetition)
                    setOriginal(deepCopy(newCompetition))
                }
            }
        }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
    }
    useEffect(() => {
        isMounted.current = true
        return () => isMounted.current = false
    }, [])
    return (
        <Grid container>
            <LoadingBackdrop loading={loading} />
            <TitleBar title={name} state={expand} setState={setExpand} main />
            {expand && <Grid container style={competitionContainerStyle}>
                <TitleBar title='Setting' state={expandSetting} setState={setExpandSetting} />
                {expandSetting && <AddPageWrapper>
                    <InputContainer label='Competition Name*' first
                        field={<ReusableTextField type='default' placeholder='Competition Name' state={name} target='name'
                            setState={updateCompetition} borderColor='#000' required fullWidth />} />
                    <InputContainer label='Alias*'
                        field={<ReusableTextField type='default' placeholder='Alias' state={validateAlias(alias)} target='alias'
                            setState={updateCompetition} borderColor='#000' required fullWidth />} />
                    <InputContainer label='Competition Format*'
                        field={<ReusableToggle type='text' width={250} falseValue='Local' trueValue='Global'
                            disabled grayedOut state={format} target='format' setState={updateCompetition} />} />
                    <InputContainer label={`Global Registration Start ${format ? 'and End ' : ''}Date*`}
                        field={<div style={dateContainerStyle}>
                            <ReusableDatePicker width={250} state={global_registration_date}
                                target='global_registration_date' setState={updateCompetition} required />
                            {Boolean(format) && <>
                                <NunitoText value='TO' fontSize={20} fontWeight={600} italic />
                                <ReusableDatePicker width={250} state={global_registration_end_date}
                                    target='global_registration_end_date' setState={updateCompetition} required />
                            </>}
                        </div>} />
                    <InputContainer label='Global Competition Start and End Date*'
                        field={<div style={dateContainerStyle}>
                            <ReusableDatePicker width={250} state={competition_start_date}
                                target='competition_start_date' setState={updateCompetition} required />
                            <NunitoText value='TO' fontSize={20} fontWeight={600} italic />
                            <ReusableDatePicker width={250} state={competition_end_date}
                                target='competition_end_date' setState={updateCompetition} required />
                        </div>} />
                    <InputContainer label='Competition Mode'
                        field={<Grid container alignItems='center'>
                            <RadioGroup value={competition_mode}
                                onChange={e => updateCompetition('competition_mode', e.target.value)} row>
                                <FormControlLabel value='0' control={<Radio style={radioStyle} />} label="Paper Based" />
                                <FormControlLabel value='1' control={<Radio style={radioStyle} />} label="Online" />
                                <FormControlLabel value='2' control={<Radio style={radioStyle} />} label="Both" />
                            </RadioGroup>
                            <ReusableButton text='Clear Selection' color='#000' borderColor='#000' fontSize={16} marginRight={20}
                                onClick={() => updateCompetition('competition_mode', '')} />
                        </Grid>} />
                        <InputContainer label='Tags'
                        field={ <ReusableAutocompleteWithID type='default' placeholder='Tags' width={590} multiple
                        bgColor='#FFF' borderColor='#707070' state={tags?.map(t=> (t?.id||t))}
                        setState={updateCompetition} target='tags' marginTop={48} border='#707070'
                        options={tagOptions.map(t => ({ id: t.id, option: t.name }))}  />} />
                    <InputContainer label='Difficulty Group*'
                        field={<ReusableAutocompleteWithID type='default' placeholder='Difficulty Group'
                            width={590} bgColor='#FFF' borderColor='#707070' state={difficulty_group_id} required
                            setState={updateCompetition} target='difficulty_group_id'
                            options={difficultyGroupOptions.map(d => ({ id: d.id, option: d.name }))} />} />
                    <InputContainer label='Grades Participating*'
                        field={<ReusableAutocompleteWithID type='default' placeholder='Grades Participating' width={590}
                            height={55} btnBgColor='#144A94' btnWidth={64} state={allowed_grades} multiple
                            target='allowed_grades' setState={updateCompetition} bgColor='#FFF' borderColor='#707070'
                            options={gradeOptions.map(g => ({ id: g.id, option: g.name }))} />} />
                    <InputContainer last label='Competition Instructions'
                        field={<DescriptionEditor state={instructions} setState={setInstructions} height={400}
                            placeholder='Competition Instructions' target='instructions' readOnly={rerun} />} />
                    <Grid container justifyContent="flex-end" style={{ marginRight: 30, marginBottom: 20 }}>
                        <ReusableButton text='Save' bgColor='#5E75C3' fontSize={16} onClick={() => onSubmit()} iconType='save' />
                    </Grid>
                    {/* <div style={{ marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <ReusableToggle type='text' width={160} falseValue='No' trueValue='Yes'
                                    state={rerun} setState={onChangeReRun} />
                                {rerun && <ReusableAutocompleteWithID type='default' placeholder='Marking Preference'
                                    width={450} height={55} bgColor='#FFF' btnBgColor='#144A94' btnWidth={64}
                                    state={rerunMarkingPreference} setState={setReRunMarkingPreference} borderColor='#707070'
                                    options={competitionOptions.map(c => ({ id: c.id, option: c.name }))} />}
                            </div> */}
                </AddPageWrapper>}
                <ParticipatingCountries original={original} setOriginal={setOriginal}
                    competition={competition} setCompetition={setCompetition} />
                <Rounds original={original} setOriginal={setOriginal}
                    competition={competition} setCompetition={setCompetition} collectionOptions={collectionOptions} />
              { competition.rounds.length>1&& <Awards original={original} setOriginal={setOriginal}
                    competition={competition} setCompetition={setCompetition} collectionOptions={collectionOptions} />}
            </Grid>}
        </Grid>
    )
}