import React from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { makeSureIsArray } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'

export default function DuplicateTask({canceldub,duplicate,id , table}){
    const getSinglename = (id)=>{
        let single = makeSureIsArray(table.data).filter(s => s.id === id)
            return single[0].identifier
        }
console.log(table)
    return(
        <Box className='popUpModal' style={{ width: 705, minHeight: 365 }}>
        <NunitoText value='CONFIRM Duplicate?' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={30} />
        <NunitoText value='Are you sure you want to Duplicate' fontSize={20} fontWeight={600} />
        <NunitoText value={getSinglename(id)} fontSize={20} fontWeight={700} />
        <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
            <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                onClick={() => canceldub()} />
            <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63} width={280}
                onClick={() => duplicate()} />
        </Grid>
    </Box>
    )
}