import React from 'react'
import { Grid } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { gradeOptions } from '../../functions/general'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
const containerStyle = { paddingBottom: 20 }
const iconStyle = { fontSize: 36, cursor: 'pointer', marginRight: 30 }
export default function AddedLevel({ count, level, levels, levelCount, updateForm, allowedGrades, collectionOptions }) {
   const { name, collection, grades } = level
   let takenCollections = levels.filter((l, i) => levelCount !== i).map(l => l.collection)
   let filteredCollectionOptions = collectionOptions.filter(c => !takenCollections.includes(c.id) || collection === c.id)

   let availableGrades = gradeOptions.filter(g => allowedGrades.includes(g.id))
   let takenGrades = levels.filter((l, i) => levelCount !== i).map(l => l.grades).flat()
   let filteredGradeOptions = availableGrades.filter(g => !takenGrades.includes(g.id) || grades.includes(g.id))
   return (
      <Grid container alignItems='center' style={containerStyle}>
         <ClearIcon style={iconStyle} onClick={() => updateForm([count, levelCount], 'deleteLevel')} />
         <ReusableTextField type='default' width={240} placeholder='Level*' state={name} bgColor='#F2F2F2' marginRight={30}
            borderColor='#707070' setState={updateForm} count={[count, levelCount]} target='name' required onBlur />
         <ReusableAutocompleteWithID type='default' placeholder='Collection*' width={320} bgColor='#FFF'
            btnBgColor='#144A94' state={collection} setState={updateForm} count={[count, levelCount]} target='collection' required
            borderColor='#707070' marginRight={30} options={filteredCollectionOptions.map(c => ({ id: c.id, option: c.name }))} />
         {/* <ReusableButton text='Preview Collection' height={48} bgColor='#144A94' fontSize={15} marginRight={30} /> */}
         <ReusableAutocompleteWithID type='default' placeholder='Grades*' width={320} bgColor='#FFF' borderColor='#707070'
            state={grades} setState={updateForm} multiple marginRight={30} count={[count, levelCount]} target='grades' required
            options={filteredGradeOptions.map(g => ({ id: g.id, option: g.name }))} />
      </Grid>
   )
}