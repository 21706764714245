import { Grid } from "@mui/material"
import NunitoText from "./general/NunitoText"
import { useEffect } from "react"
import { getCheaters } from "../functions/getData"
import NoData from "./general/NoData"

export default function CheatingBar({percentage ,setPercentage , err ,msg}){
    useEffect(()=>{

    },[setPercentage])
    const ProgressBar = ({ percentage,width , margin }) => <div style={{
        height: 40, width: width, borderRadius: 6,margin:'0 auto',
        background: `linear-gradient(90deg, #5E75C3 ${percentage}%, #D7D7D7 0%)`
     }} />
    
return   <Grid container>
    <Grid container>
{<NunitoText width={'100%'} value={msg==='Generating cheating list is not started'?'':`Computing Progress: ${percentage}%`} fontSize={40} fontWeight={700} italic color='#144A94'   />}
{err!==''&&<NunitoText width={'100%'} value={err} fontSize={40} fontWeight={700} italic color='#E83042'   />}

</Grid>
{msg==='Generating cheating list is not started'?<NoData text={'Please click on "Compute" button to start your integrity check'}  fontSize={20} fontWeight={700}  italic color='#144A94'/>:<ProgressBar  percentage={percentage}  width={'80%'} ></ProgressBar>}
</Grid>
}