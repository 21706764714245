import store from "../reducers/store"
export const isLoggedIn = () => store.getState().user.loggedIn && store.getState().user.token !== null
export const isAdmin = () => [0, 1].includes(store.getState().user.role_id)
export const isPartner = () => store.getState().user.role_id === 2
export const isAssistant = () => store.getState().user.role_id === 4
export const isManager = () => store.getState().user.role_id === 5
export const isTeacher = () => store.getState().user.role_id === 3
export const isPrivate = ()=>store.getState().user.isPrivate===1
export const isAdminOrPartner = () => [0, 1, 2].includes(store.getState().user.role_id)
export const isAdminOrPartnerOrAssistantOrManager = () => [0, 1, 2, 5, 4].includes(store.getState().user.role_id)
export const isAdminOrPartnerOrAssistant = () => [0, 1, 2, 4].includes(store.getState().user.role_id)
export const isPartnerOrAssistant = () => [2, 4].includes(store.getState().user.role_id)
export const isManagerOrTeacher = () => [3, 5].includes(store.getState().user.role_id)

export const isFromTuitionCentre = () => store.getState().user.isPrivate === 1