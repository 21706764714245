import React from 'react'
import { Grid } from '@mui/material'
// import { BottomAppBar } from './DrawerAndAppBar'
export default function Copyright({ open }) {
    // return (
    //     <BottomAppBar open={open}>
    //         <p style={{ color: '#FFF', fontFamily: 'Roboto', fontSize: '1.17em', fontWeight: 'bolder', margin: 0 }}>
    //             Copyright @SIMCC 2021
    //         </p>
    //     </BottomAppBar>
    // )
    return (
        <Grid container style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1300,
            backgroundColor: '#144A94', height: 48, color: '#FFF', fontFamily: 'Roboto', fontSize: '1.17em', fontWeight: 'bolder',
            width: open && `calc(100% - 300px)`,
            marginLeft: open && 300, 
            // position: 'sticky', bottom: 0, right: 0,
            transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
        }}>
            Copyright @SIMCC 2021
        </Grid>
    )
}