import React from 'react'
import { Tooltip, styled, tooltipClasses } from '@mui/material'
const popperProps = {
    popperOptions: {
        modifiers: [
            {
                name: "flip",
                enabled: false
            }
        ],
    },
}
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement='bottom-start' PopperProps={popperProps} />
))(({ theme, ...props }) => {
    let obj = {
        backgroundColor: '#FFF',
        color: '#144A94',
        border: '1px solid #707070',
        borderRadius: 6,
        maxWidth: 950
    }
    if (!props.fixPosition) obj.position = 'relative'
    if (props.rollover) {
        obj.borderRadius = 16
        obj.boxShadow = '5px 5px 12px rgba(0, 0, 0, 16%)'
        obj.bottom = props.topic ? 15 : 40
    } else {
        obj.bottom = props.type === 'Button' ? 15 : 90
        obj.left = props.type === 'Button' ? 0 : 10
    }
    if (props.hide) obj.display = 'none'
    return ({
        [`& .${tooltipClasses.tooltip}`]: obj
    })
})
export default LightTooltip