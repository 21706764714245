import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import {
    makeSureIsArray, makeOptions, resetAll,
    changeViewOptions, changeRowsPerPage, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getTasks } from '../functions/getData'
import { deleteTask } from '../functions/deleteData'
import { showNotification } from '../functions/snackbar'
import ApproveModal from '../components/modal/ApproveModal'
import RejectModal from '../components/modal/RejectModal'
import AddTaskToCompetitionAndCollectionModal from '../components/modal/AddTaskToCompetitionAndCollectionModal'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
import { approveTask, rejectTask } from '../functions/postData'
import { useLocation } from 'react-router-dom'

import ReasonModal from '../components/modal/ReasonModal'
const taskHeader = [
    { id: 'identifier', label: 'Task Identifier' },
    { id: 'title', label: 'Task Title' },
    { id: 'status', label: 'Status' },
    { id: 'description', label: 'Description' },
    { id: 'languages', label: 'No. of Language(s)' },
    { id: 'domain', label: 'Domain' },
    { id: 'tags', label: 'Tags' },
    { id: 'grade_difficulty', label: 'Recommended Difficulty' },
    { id: 'answer_type', label: 'Answer Type' },
    { id: 'answer_structure', label: 'Answer Structure' },
    { id: 'answer_sorting', label: 'Display Order' },
    { id: 'task_answers', label: 'No. of Correct Answers' },
    // { id: 'collections', label: 'Collections' },
    // { id: 'competitions', label: 'Competitions' },
    { id: 'created_by', label: 'Created By' },
    { id: 'last_modified_by', label: 'Last Modified By' },
    { id: 'modulation', label: 'Last Verified By (last 5)' }
]
const initialParams = '?currentPage=moderation'
export default function TasksModeration() {
    document.title = 'Moderate Tasks'
    const isMounted = useRef(false)
    const [header, setHeader] = useState(taskHeader)
    const [taskTable, setTaskTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: 'Language', state: '', key: 'lang_id', header: 'lang' },
        { label: 'Domain', state: '', key: 'tag_id', header: 'domains' },
        { label: 'Tags', state: [], key: 'tag_id', header: 'tags' },
        // { label: 'Collection', state: '', key: 'collections', header: '' },
        // { label: 'Competition', state: '', key: 'competitions', header: '' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: 'No. of Langauge(s)', state: true, key: 'task_content' },
        { label: 'Domain', state: true, key: 'domain' },
        { label: 'Tags', state: true, key: 'tags' },
        { label: 'Recommended Difficulty', state: true, key: 'recommended_difficulty' },
        { label: 'Answer Type', state: true, key: 'answer_type' },
        { label: 'Answer Structure', state: true, key: 'answer_structure' },
        { label: 'Display Order', state: true, key: 'display_order' },
        { label: 'No. of answers', state: true, key: 'answers' },
        { label: 'Collections', state: true, key: 'collections' },
        { label: 'Competitions', state: true, key: 'competitions' },
        { label: 'Created By', state: true, key: 'created_by_username' },
        { label: 'Last Modified By', state: true, key: 'modified_by_username' },
        { label: 'Last Verified By(Last 5)', state: true, key: 'verified_by_username' },
        { label: 'Status', state: true, key: 'status' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onReject , setOnReject] = useState(false)
    const [onApprove, setOnApprove] = useState(false)
    const [isSingle , setIssingle] = useState(false)
    const [onAddTask, setOnAddTask] = useState(false)
    const [onReasons , setOnReasons] = useState(false)

    const [onAssignTaskPermission, setOnAssignTaskPermission] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setId)
    const cancelAddTask = () => cancelAction(setOnAddTask)
    const cancelReason = ()=> cancelAction(setOnReasons , setId)

    const cancelApprove = () => cancelAction(setOnApprove, setId)
    const cancelReject = () => cancelAction(setOnReject, setId)
    const cancelAssignTaskPermission = () => cancelAction(setOnAssignTaskPermission)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const afterFunction = (cancel, data) => {
        if ([200, 201].includes(data.status)) {
            resetAll(initialParams, filtered, setParams, setSearch, setFiltered)
            getData(initialParams, signal)
            cancel()
            showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
            setSelected([])
            setSelecting([])
        }
    }
   
   
   
    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        loadData(setLoadingTable, setFirstLoaded, getTasks, params, setTaskTable, setFilterOptions, false, signal)
    }
    console.log(taskTable)
    const onChangeViewOptions = value => changeViewOptions(taskHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        setFiltered(value)
        if (JSON.stringify(value) === JSON.stringify(filtered)) return console.log('Filtered is unchanged')
        // changeFiltered(initialParams, search, rowsPerPage, value, filtered, setFiltered , setParams, getData)
        // Need write different functions to handle filtered for Tasks because of the way domain and
        // tag are both under ?tag_id
        let newParams =location.search.slice(0, 4) === '?id='? `?limits=${rowsPerPage}&currentPage=moderation&collection_id=${location.search.slice(4)}`:`?limits=${rowsPerPage}&currentPage=moderation`
        let tag_id = []
        value.forEach(({ key, state, header }) => {
            if (key === 'tag_id') {
                if (header === 'tags') state.forEach(s => tag_id.push(s))
                else {
                    if (state.toString().length) tag_id.push(state)
                }
            }
            else {
                if (state.toString().length) newParams += `&${key}=${state}`
            }
        })
        if (tag_id.length) newParams += `&tag_id=${tag_id.join(',')}`
        if (search.length) newParams += `&search=${search}`
        setParams(newParams)
        getData(newParams, signal)
    }
    const onChangeSearch = value => {
        let isComp = false;
        if(location.search.slice(0, 4) === '?id='){
            isComp = true
        }
        console.log(location.search.slice(4))
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData  , true,location.search.slice(4))
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    let location = useLocation()

    useEffect(() => {
        isMounted.current = true
        if(location.search.slice(0, 4) === '?id='){
            getData(initialParams+`&collection_id=${location.search.slice(4)}`)
        }else{
        getData(initialParams, signal)}
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller, signal])
    const onClickAddTaskToCompetitionAndCollection = () => {
        return selected.length ? setOnAddTask(true) : showWarningSwal('Please select a task to add to collection')
    }
    const onClickMassAssignTaskPermissions = () => {
        return selected.length ? setOnAssignTaskPermission(true) : showWarningSwal('Please select a task to assign permissions for')
    }
    const onClickMassDelete = () => {
        if(selected.length>1){
            setIssingle(false)
            setOnDelete(true)
           }else{
            showWarningSwal('you must select atleast 2 tasks')
           }
    }
    const approveFunction = () => {
        setLoading(true)
        let payload = []
        if(isSingle){
            payload = {ids:[id]}
        }else{
            payload = {ids:selected}
        }
        approveTask(payload).then(res=>{
            if(res.status===200){
                getData(initialParams)
                showNotification('success', res.message, enqueueSnackbar, closeSnackbar)
              
            }
        })
        setLoading(false)
        setOnApprove(false)
       
    }
    const rejectFunction = (id , reason)=>{
        if(reason===''){
            showWarningSwal('reason field cannot be empty')
        }else{
        setLoading(true)
        rejectTask(id , {reason:reason}).then(res=>{
            if(res.status===200){
                setLoading(false)
                showNotification('success', res.message, enqueueSnackbar, closeSnackbar)
                getData(initialParams , signal)
                }else{
                setLoading(false)
            }
        })
        setOnReject(false)
    }
    }

    const deleteFunction = () => {
        console.log({ id: id === '' ? selected : [id] })
        setLoading(true)
        deleteTask({ id: id === '' ? selected : [id] }).then(d => {
            if(d.status===200){
                getData(params)
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)

            }
       
        cancelDelete()
        setLoading(false)
        })
    }
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Moderate Tasks' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div>
            </Grid>
            <Grid className='tableContainer' container>
                <NunitoText value='Moderate Tasks' fontSize={40} fontWeight={700} italic color='#144A94' />
                {/* {filtered.find(f => f.key === 'competitions').state !== '' &&
                    <Widget title='Competition' content={filtered.find(f => f.key === 'competition').state}
                        caption='Individual Competition Name' marginBottom={40} />}
                {filtered.find(f => f.key === 'collections').state !== '' &&
                    <Widget title='Collection' content={filtered.find(f => f.key === 'collection').state}
                        caption='Collection used for competition' marginBottom={40} />} */}
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                    <div className='endBtnDiv'>
                        <ReusableButton text='mass approve' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                           onClick={()=>{
                            if(selected.length>1){
                            setOnApprove(true)
                            setIssingle(false)
                            }else{
                                showWarningSwal('you must select atleast two task')
                            }
                           }} iconType='add' />
                             <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46} marginRight={20}
                            onClick={() => onClickMassDelete()} iconType='delete' />
                        {/* <ReusableButton text='Add Task To Competition and Collection' fontSize={15} bgColor='#144A94' height={46} marginRight={20}
                            onClick={() => onClickAddTaskToCompetitionAndCollection()} iconType='add' /> */}
                        {/* <ReusableButton text='Mass Assign Task Permissions' fontSize={15} bgColor='#144A94' height={46} marginRight={20}
                            onClick={() => onClickMassAssignTaskPermissions()} iconType='add' /> */}
                    
                    </div>
                </Grid>
                <NunitoText value='Search only in "Task Identifier" and "Task Title" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loadingTable && <Loader height={600} />}
                {!loadingTable && taskTable && makeSureIsArray(taskTable.data).length &&
                    <HeavyTable setOnReasons={setOnReasons} headers={header} list={taskTable} title='tasks' rowsPerPage={rowsPerPage} setOnApprove={setOnApprove}  setIssingle={setIssingle} setOnReject={setOnReject}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} fixed={['identifier', 'title']}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected} setId={setId}
                    />}
                {!loadingTable && firstLoaded && taskTable && !makeSureIsArray(taskTable.data).length && <NoData height={600} />}
            </Grid>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <>
                    {Boolean(taskTable) && <DeleteModal table={taskTable} id={id} selected={selected}
                        deleteFunction={deleteFunction} cancelDelete={cancelDelete} />}
                </>
            </Modal>
            <Modal open={onAddTask} onClose={() => cancelAddTask()}>
                <>
                    {Boolean(taskTable) && <AddTaskToCompetitionAndCollectionModal afterAddTask={() => { }} tasks={taskTable.data}
                        selected={selected} setSelected={setSelected} cancelAddTask={cancelAddTask} />}
                </>
            </Modal>
            <Modal open={onApprove} onClose={() => cancelApprove()}>
                <>
                    {Boolean(taskTable) && <ApproveModal table={taskTable} id={id} selected={selected} singleApprove={isSingle}
                        approveFunction={approveFunction} cancelApprove={cancelApprove} object='task' />}
                </>
            </Modal>
            <Modal open={onReject} onClose={() => cancelReject()}>
                <>
                    {Boolean(taskTable) && <RejectModal table={taskTable} id={id} selected={selected} 
                        rejectFunction={rejectFunction} cancelReject={cancelReject} object='task' />}
                </>
            </Modal>
            <Modal open={onAssignTaskPermission} onClose={() => cancelAssignTaskPermission()}>
                <div className='popUpModal horizontalScrollable'>
                    <h1>Placeholder modal for assigning task permissions</h1>
                </div>
            </Modal>
            <Modal open={onReasons} onClose={() => cancelReason()}>
                <>
                    {Boolean(taskTable) && <ReasonModal table={taskTable} id={id} selected={selected} 
                         object='task' />}
                </>
            </Modal>
        </Box>
    )
}