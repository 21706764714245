import React from 'react'
import { Grid } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { gradeOptions } from '../../functions/general'
import ReusableButton from '../general/ReusableButton'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
const containerStyle = { paddingBottom: 20 }
const iconStyle = { fontSize: 36, cursor: 'pointer', marginRight: 30 }
export default function Level({ count, levelCount, competition, setCompetition, updateRounds, collectionOptions }) {
   const round = competition.rounds[count]
   const level = round.levels[levelCount]
   const { id, name, collection_id, grades } = level
   const updateLevel = (identifier, value) => {
      let newCompetition = { ...competition }
      newCompetition.rounds[count].levels[levelCount][identifier] = value
      setCompetition(newCompetition)
   }
   let takenCollections = round.levels.filter(l => id !== l.id).map(l => l.collection_id)
   let filteredCollectionOptions = collectionOptions

   let availableGrades = gradeOptions.filter(g => competition.allowed_grades.includes(g.id))
   let takenGrades = round.levels.filter(l => id !== l.id).map(l => l.grades).flat()
   let filteredGradeOptions = availableGrades.filter(g => !takenGrades.includes(g.id) || grades.includes(g.id))
   return (
      <Grid container alignItems='center' style={containerStyle}>
         <ClearIcon style={iconStyle} onClick={() => updateRounds('deleteLevel', levelCount)} />
         <ReusableTextField type='default' width={240} placeholder='Level*' state={name} bgColor='#F2F2F2' marginRight={30}
            borderColor='#707070' setState={updateLevel} target='name' required onBlur />
         <ReusableAutocompleteWithID type='default' placeholder='Collection*' width={320} bgColor='#FFF'
            btnBgColor='#144A94' state={collection_id} setState={updateLevel} target='collection_id' required borderColor='#707070'
            marginRight={30} options={filteredCollectionOptions.map(c => ({ id: c.id, option: c.name }))} />
         {/* <ReusableButton text='Preview Collection' height={48} bgColor='#144A94' fontSize={15} marginRight={30} /> */}
         {competition.difficulty_group_id!==0&&<ReusableButton text='Assign Difficulty and Points' height={48} bgColor='#008ECC' fontSize={15} marginRight={30}
            // to={level.collection && `/assignDifficulty/${competition.competitionID}/${roundCount}/${levelCount}/${level.collection}`}
            // blank 
            to={`/assignDifficulty?competition_id=${competition.id}&round_id=${round.id}&level_id=${id}`}
            blank
         />}
         <ReusableAutocompleteWithID type='default' placeholder='Grades*' width={320} bgColor='#FFF' borderColor='#707070'
            state={grades} setState={updateLevel} multiple marginRight={30} target='grades' required
            options={filteredGradeOptions.map(g => ({ id: g.id, option: g.name }))} />
         {/* {JSON.stringify(level)} */}
      </Grid>
   )
}