import React, { useState } from 'react'
import { Autocomplete, TextField, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
export default function ReusableAutocomplete({ type, width, height = 60, bgColor, borderColor,
    marginLeft, marginRight, marginTop, marginBottom,
    placeholder, state, setState, required, readOnly, grayedOut, disabled, errorFunction,
    count, head, target,
    // For ReusableAutocompleteWith
    options, freeSolo }) {
    const [input, setInput] = useState(state)
    const [open, setOpen] = useState(false)
    const expand = () => !readOnly && setOpen(true)
    const collapse = () => !readOnly && setOpen(false)
    const toggle = () => !readOnly && setOpen(!open)
    const checkError = () => errorFunction && errorFunction(state)
    let defaultBorder = borderColor ? `1px solid ${borderColor}` : bgColor ? `1px solid ${bgColor}` : 'none'
    let border = (checkError() || (required && state === '')) ? '1px solid #E83D4D' : defaultBorder
    const searchIconStyle = {
        fontSize: 30,
        color: 'rgb(112, 112, 112, 0.5)',
        marginLeft: 12
    }
    const withLabelDivStyle = {
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
        marginLeft, marginRight, marginTop, marginBottom, width,
    }
    const autoCompleteMargin = type === 'withLabel' ? {} : { marginLeft, marginRight, marginTop, marginBottom }
    const autoCompleteStyle = {
        ...autoCompleteMargin, width, height, pointerEvents: readOnly && 'none'
    }
    const textFieldStyle = {
        height,
        border, borderRadius: 12,
        backgroundColor: grayedOut ? '#707070' : bgColor
    }
    const inputStyle = {
        marginLeft: ['table'].includes(type) && 20,
        textAlign: ['default', 'withLabel'].includes(type) && 'center',
        color: grayedOut && '#FFF',
        cursor: readOnly && 'context-menu'
    }
    const btnStyle = {
        color: '#FFF',
        backgroundColor: '#144A94',
        borderRadius: '0 10px 10px 0',
        height: height - 2, width: 64,
        opacity: grayedOut && 0.5
    }
    const iconStyle = { fontSize: 42 }
    const labelStyle = { color: '#000', fontSize: 16, fontFamily: 'Nunito', fontWeight: 600, fontStyle: 'italic' }
    const onChangeState = value => {
        if (count !== undefined && head !== undefined && target !== undefined) {
            // For redux autocompletes
            return setState(count, head, target, value)
        }
        if (count !== undefined && target !== undefined) {
            // For states in an array of objects
            return setState(count, target, value)
        }
        // For states in an array only, object only or by itself
        if (count !== undefined) setState(count, value)
        else if (target !== undefined) setState(target, value)
        else setState(value)
    }
    const autoCompleteAttributes = {
        value: state,
        onChange: (e, n) => onChangeState(n),
        inputValue: input,
        onInputChange: (e, n) => {
            setInput(n)
            if (n === '') onChangeState(n)
        },
        options: options.map(o => o.option),
        open: open, onClose: () => collapse(), onOpen: () => expand(),
        selectOnFocus: true, disableClearable: true, forcePopupIcon: false,
        style: autoCompleteStyle, freeSolo, disabled, clearOnBlur: !freeSolo
    }
    if (freeSolo) {
        autoCompleteAttributes.inputValue = state
        autoCompleteAttributes.onInputChange = (e, n) => onChangeState(n)
        // autoCompleteAttributes.filterOptions = (options) => {
        //     if (options.find(o => o.toLowerCase() === state.toLowerCase())) return options
        //     else return options.filter(o => o.toLowerCase().includes(state.toLowerCase()))
        // }
    }
    const textFieldAttributes = {
        variant: 'standard', placeholder
    }
    const textFieldInputProps = {
        disableUnderline: true, style: textFieldStyle,
        startAdornment: <SearchIcon style={searchIconStyle} />,
        endAdornment: <IconButton onClick={() => toggle()} style={btnStyle}>
            {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
        </IconButton>
    }
    switch (type) {
        case 'table':
        case 'default':
            return (
                <Autocomplete {...autoCompleteAttributes}
                    renderInput={params => <TextField {...params} {...textFieldAttributes}
                        InputProps={{
                            ...params.InputProps,
                            ...textFieldInputProps
                        }}
                        inputProps={{ ...params.inputProps, style: inputStyle }}
                    />}
                />
            )
        case 'withLabel':
            return (
                <div style={withLabelDivStyle}>
                    <label style={labelStyle}>{placeholder}</label>
                    <Autocomplete {...autoCompleteAttributes}
                        renderInput={params => <TextField {...params} {...textFieldAttributes}
                            InputProps={{
                                ...params.InputProps,
                                ...textFieldInputProps
                            }}
                            inputProps={{ ...params.inputProps, style: inputStyle }}
                        />}
                    />
                </div>
            )
        default: return null
    }
}