import React, { useState, useEffect, useMemo } from 'react'
import { Box, Grid, Divider } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import { getSchools } from '../../functions/getData'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
export default function ApproveSchoolModal({ table, id, selected, approveFunction, cancelApprove, object }) {
    const [similarSchools, setSimilarSchools] = useState([])
    const selectedRows = useMemo(() => {
        return makeSureIsArray(table.data).filter(s => id === '' ? selected.includes(s.id) : s.id === id)
    }, [id, selected, table])
    const equalize = str => str.replace(/\s/g, "").toLowerCase()
    useEffect(() => {
        console.log('once')
        const controller = new AbortController()
        const signal = controller.signal
        getSchools(`?limits=0&status=active`, signal).then(s => {
            let schools = s.SchoolLists.data
            let arr = []
            schools.forEach(school => {
                if (selectedRows.map(r => equalize(r.name)).includes(equalize(school.name))) {
                    let schl = selectedRows.find(r => equalize(r.name) === equalize(school.name))
                    arr.push(schl.name)
                }
            })
            setSimilarSchools(arr)
        }).catch(e => console.log(e))
        return () => controller.abort()
    }, [selectedRows])
    return (
        <Box className='popUpModal horizontalScrollable' style={{ width: 705, minHeight: 365, maxHeight: 800 }}>
            <NunitoText value='CONFIRM APPROVE' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={30} />
            <NunitoText value={`Are you sure you want to approve these ${object}`} fontSize={20} fontWeight={600} />
            {selectedRows.map((s, i) => (
                <NunitoText key={i} value={`${s.name || s.competition_name}${i < selectedRows.length - 1 && ', '}`} fontSize={20} fontWeight={700} />
            ))}
            {Boolean(similarSchools.length) && <div style={{ paddingInline: 30 }}>
                <Divider style={{ backgroundColor: '#000', height: 2, marginBlock: 20 }} />
                <NunitoText value='Similar school names found' fontSize={20} fontWeight={600} color='#E83042' />
                {similarSchools.map((s, i) => (
                    <NunitoText key={i} value={s} fontSize={16} color='#000' />
                ))}
            </div>}
            <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                    onClick={() => cancelApprove()} />
                <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63} width={280}
                    onClick={() => approveFunction()} />
            </Grid>
        </Box>
    )
}