import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateCompetition } from '../../actions'
import { gradeOptions } from '../../functions/general'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
const containerStyle = { paddingBottom: 20 }
const iconStyle = { fontSize: 36, cursor: 'pointer', marginRight: 30 }
export default function Level({ count, competition, round, roundCount, level, levelCount, collectionOptions }) {
   const dispatch = useDispatch()
   const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
   let takenCollections = round.levels.filter((l, i) => i !== levelCount).map(l => l.collection)
   let filteredCollectionOptions = collectionOptions

   let availableGrades = gradeOptions.filter(g => competition.gradesParticipating.includes(g.id))
   let takenGrades = round.levels.filter((l, i) => i !== levelCount).map(l => l.grade).flat()
   let filteredGradeOptions = availableGrades.filter(g => !takenGrades.includes(g.id) || level.grade.includes(g.id))
   return (
      <Grid container alignItems='center' style={containerStyle}>
         <ClearIcon style={iconStyle} onClick={() => update([count, roundCount, levelCount], 'deleteLevel', 'levels')} />
         <ReusableTextField type='default' width={240} placeholder='Level*' state={level.level} bgColor='#F2F2F2' marginRight={30} required
            borderColor='#707070' setState={update} count={[count, roundCount, levelCount]} head='levelText' target='level' onBlur />
         <ReusableAutocompleteWithID type='default' placeholder='Collection*' width={320} bgColor='#FFF'
            btnBgColor='#144A94' state={level.collection} setState={update} count={[count, roundCount, levelCount]}
            head='levelText' target='collection' borderColor='#707070' marginRight={30} required
            options={filteredCollectionOptions.map(c => ({ id: c.id, option: c.name }))} />
         {/* <ReusableButton text='Preview Collection' height={48} bgColor='#144A94' fontSize={15} marginRight={30} /> */}
         {/* <ReusableButton text='Assign Difficulty and Points' height={48} bgColor='#008ECC' fontSize={15}
                to={level.collection && `/assignDifficulty/${competition.competitionID}/${roundCount}/${levelCount}/${level.collection}`}
                blank /> */}
         <ReusableAutocompleteWithID type='default' placeholder='Grades*' width={320} bgColor='#FFF' borderColor='#707070'
            state={level.grade} setState={update} multiple marginRight={30} required
            count={[count, roundCount, levelCount]} head='levelText' target='grade'
            options={filteredGradeOptions.map(g => ({ id: g.id, option: g.name }))} />
      </Grid>
   )
}
