import React, { useRef } from 'react'
import JoditEditor from "jodit-react"
export default function DescriptionEditor({ height, marginLeft, marginRight, marginTop, marginBottom, placeholder,
    state, setState, count, head, target, readOnly, grayedOut, disabled }) {
    const editor = useRef(null)
    const config = {
        readonly: readOnly, // all options from https://xdsoft.net/jodit/docs/
        disabled,
        placeholder,
        editorCssClass: 'editorCss',
        height,
        toolbarAdaptive: false,
        uploader: {
            insertImageAsBase64URI: true
        },
        theme: grayedOut && 'dark'
    }
    const containerStyle = { marginLeft, marginRight, marginTop, marginBottom, width:'100%' }
    const onChangeState = value => {
        if (count !== undefined && head !== undefined && target !== undefined) {
            // For redux textfields
            return setState(count, head, target, value)
        }
        if (count !== undefined && target !== undefined) {
            // For states in an array of objects
            return setState(count, target, value)
        }
        // For states in an array only, object only or by itself
        if (count !== undefined) setState(count, value)
        else if (target !== undefined) setState(target, value)
        else setState(value)
    }
    return (
        <div style={containerStyle}>
            <JoditEditor ref={editor} config={config} tabIndex={1} // tabIndex of textarea
                value={state} onBlur={newContent => onChangeState(newContent)} />
        </div>
    )
}