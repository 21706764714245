import React from 'react'
import { Box, Grid } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import { approveTask } from '../../functions/postData'
import { showNotification } from '../../functions/snackbar'
import { useSnackbar } from 'notistack'

export default function ApproveModal({ table, id, selected, approveFunction, cancelApprove, object , singleApprove }) {
    const selectedRows = makeSureIsArray(table.data).filter(s => id === '' ? selected.includes(s.id) : s.id === id)
    const getSinglename = (id)=>{
    let single = makeSureIsArray(table.data).filter(s => s.id === id)
        return single[0].name ||single[0].identifier || single[0].competition_name
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    console.log(selectedRows)
   
    return (
        <Box className='popUpModal' style={{ width: 705, minHeight: 365 }}>
            <NunitoText value='CONFIRM APPROVE' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={30} />
            <NunitoText value={`Are you sure you want to approve ${singleApprove?`this ${object}`:`these ${object}s`}`} fontSize={20} fontWeight={600} />
           {singleApprove?<NunitoText value={getSinglename(id)} fontSize={20} fontWeight={700} />: selectedRows.map((s, i) => (
                <NunitoText key={i} value={`${s.name || s.competition_name||s.identifier}${i < selectedRows.length - 1 && ', '}`} fontSize={20} fontWeight={700} />
            ))}
            <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                    onClick={() => cancelApprove()} />
                <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63} width={280}
                    onClick={() => approveFunction()} />
            </Grid>
        </Box>
    )
}