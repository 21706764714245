import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import NunitoText from "../general/NunitoText";
import ReusableTextField from "../general/ReusableTextField";
import ReusableButton from "../general/ReusableButton";
import { makeSureIsArray } from "../../functions/general";
import { IoMdCheckbox } from "react-icons/io";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { FiSave } from "react-icons/fi";
import { IoWarningOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { RiDropdownList } from "react-icons/ri";

export default function InstructionsModal({ setModalState }) {
  return (
    <Box
      className="popUpModal"
      style={{
        width: "60%",
        minHeight: 200,
        position: "fixed",
        top: "50%",
        right: "50%",
        zIndex: 15,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 20px ",
        }}
      >
        <div style={{ width: "25px" }}></div>
        <NunitoText
          value="Instructions"
          fontSize={30}
          fontWeight={600}
          color="#5E75C3"
          marginTop={20}
          marginBottom={15}
        />
        <IoClose
          style={{
            color: "gray",
            fontSize: "25px",
            textAlign: "center",
            margin: "0 2",
            cursor: "pointer",
          }}
          onClick={() => setModalState(false)}
        />
      </div>
      <div
        style={{
          textAlign: "left",
          padding: "0 30px 10px 30px",
        }}
      >
        <h4
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          -
          <RiDropdownList
            style={{
              color: "#144A94",
              fontSize: "25px",
              textAlign: "center",
              margin: "0 2",
            }}
          />
          Choose the level you want to identify answers for from first dropdown
          list
        </h4>
        <h4
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          -
          <RiDropdownList
            style={{
              color: "#144A94",
              fontSize: "25px",
              textAlign: "center",
              margin: "0 2",
            }}
          />
          Choose the question you want to identify its answer from second
          dropdown list
        </h4>

        <h4
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          -
          <IoMdCheckbox
            style={{
              color: "green",
              fontSize: "25px",
              textAlign: "center",
              margin: "0 2",
            }}
          />
          Check the answers that you want to identify as correct
        </h4>

        <h4
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          -
          <MdOutlineCheckBoxOutlineBlank
            style={{
              color: "orange",
              fontSize: "25px",
              textAlign: "center",
              margin: "0 2",
            }}
          />
          Uncheck the answers that you want to identify as wrong
        </h4>

        <h4
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          -
          <FiSave
            style={{
              color: "#5E75C3",
              fontSize: "22px",
              textAlign: "center",
              margin: "0 3",
            }}
          />
          To save your updates, click the submit Identifications button
        </h4>

        <h4
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          -
          <IoWarningOutline
            style={{
              color: "red",
              fontSize: "25px",
              textAlign: "center",
              margin: "0 2",
            }}
          />
          NOTE: If you change level or question without submitting, your updates
          will not be saved
        </h4>

        <h4
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          -
          <IoWarningOutline
            style={{
              color: "red",
              fontSize: "25px",
              textAlign: "center",
              margin: "0 2",
            }}
          />
          NOTE: question name is generated automatically and not related to the
          task identifier name
        </h4>
      </div>
    </Box>
  );
}
