import React from 'react'
import { Box, Grid } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
export default function RestoreSchoolModal({ table, id, restoreFunction, cancelRestore, object }) {
    const selectedRow = makeSureIsArray(table.data).find(s => s.id === id)
    return (
        <Box className='popUpModal' style={{ width: 705, minHeight: 365 }}>
            <NunitoText value='CONFIRM RESTORE' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={30} />
            <NunitoText value={`Are you sure you want to restore these ${object}`} fontSize={20} fontWeight={600} />
            <NunitoText value={selectedRow.name} fontSize={20} fontWeight={500} marginTop={20} />
            <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                    onClick={() => cancelRestore()} />
                <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63} width={280}
                    onClick={() => restoreFunction()} />
            </Grid>
        </Box>
    )
}