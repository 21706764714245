import React, { useState } from "react";
import { Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { gradeOptions } from "../../functions/general";
import { getTasks } from "../../functions/getData";
import { patchTaskRecommendations } from "../../functions/patchData";
import { showWarningSwal } from "../../functions/alert";
import { showNotification } from "../../functions/snackbar";
import { afterEdit, isUnchanged, populateTask } from "../../functions/edit";
import TitleBar from "../general/TitleBar";
import AddPageWrapper from "../general/AddPageWrapper";
import NunitoText from "../general/NunitoText";
import ReusableAutocompleteWithID from "../general/ReusableAutocompleteWithID";
import ReusableButton from "../general/ReusableButton";
import Loader from "../general/Loader";
import { useSnackbar } from "notistack";
const difficultyOptions = ["easy", "intermediate", "hard", "advanced"].map(
  (d) => ({ id: d, name: d })
);
const rowContainerStyle = (index) => ({
  marginTop: index ? 10 : 40,
  marginBottom: 10,
});
const iconWrapperStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const iconStyle = { fontSize: 36, color: "#000", cursor: "pointer" };
const recommendationContainerStyle = {
  backgroundColor: "#FFF",
  border: "1px solid #707070",
  borderRadius: 16,
  paddingBlock: 20,
};
const gradeOrDifficultyWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingInline: "30px 60px",
};
const buttonContainerStyle = { margin: "10px 20px 20px 0" };
export default function Recommendations({
  original,
  setOriginal,
  task,
  setTask,
  IsReadOnly,
}) {
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id, grade_difficulty } = task;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const updateRecommendation = (count, target, value) => {
    let newTask = window.structuredClone(task);
    if (target === "add") {
      newTask.grade_difficulty.splice(count + 1, 0, {
        grade: "",
        difficulty: "",
      });
    } else if (target === "remove") {
      newTask.grade_difficulty.splice(count, 1);
    } else {
      newTask.grade_difficulty[count][target] = value;
    }
    setTask(newTask);
  };
  const onSubmit = () => {
    if (isUnchanged(original.grade_difficulty, grade_difficulty)) {
      return showWarningSwal("Please make some changes before submitting");
    }
    let warningMessage = "";
    // if (grade_difficulty.map(r => r.grade).includes('')) {
    //     warningMessage += 'Please fill in all grades fields or remove recommended grade.<br>'
    // }
    // if (grade_difficulty.map(r => r.difficulty).includes('')) {
    //     warningMessage += 'Please fill in all difficulty fields or remove recommended difficulty.<br>'
    // }
    if (warningMessage) return showWarningSwal(warningMessage);
    setLoading(true);
    let payload = {
      id: id,
      recommended_grade: grade_difficulty.map((r) => r.grade),
      recommended_difficulty: grade_difficulty.map((r) => r.difficulty),
    };
    patchTaskRecommendations(payload)
      .then((d) => {
        if (d.status === 200)
          showNotification(
            "success",
            d.message,
            enqueueSnackbar,
            closeSnackbar
          );
      })
      .catch((e) => console.log(e))
      .finally(() => {
        afterEdit(getTasks, populateTask, setOriginal, task, setTask);
        setLoading(false);
      });
  };
  return (
    <>
      <TitleBar title="Recomendations" state={expand} setState={setExpand}>
        {!IsReadOnly && (
          <ReusableButton
            text="Add Recommendation"
            fontSize={16}
            bgColor="#5E75C3"
            marginRight={40}
            onClick={() => updateRecommendation(grade_difficulty.length, "add")}
            iconType="add"
          />
        )}
      </TitleBar>
      {expand && (
        <AddPageWrapper>
          {loading ? (
            <Loader height={600} />
          ) : (
            grade_difficulty.map(({ grade, difficulty }, index) => (
              <Grid
                key={index}
                container
                alignItems="center"
                style={rowContainerStyle(index)}
              >
                <Grid item xs={0.5} style={iconWrapperStyle}>
                  {!IsReadOnly && (
                    <IconButton
                      onClick={() => updateRecommendation(index, "remove")}
                    >
                      <CloseIcon style={iconStyle} />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={11}>
                  <Grid container style={recommendationContainerStyle}>
                    <Grid item xs={6} style={gradeOrDifficultyWrapperStyle}>
                      <NunitoText
                        value="Recommended Grade:"
                        fontSize={20}
                        marginBottom={10}
                        fontWeight={600}
                        italic
                      />
                      <ReusableAutocompleteWithID
                        type="default"
                        placeholder="Grade"
                        width="100%"
                        bgColor="#F2F2F2"
                        disabled={IsReadOnly}
                        grayedOut={IsReadOnly}
                        state={grade}
                        setState={updateRecommendation}
                        count={index}
                        // required
                        target={IsReadOnly ? "" : "grade"}
                        options={gradeOptions.map((g) => ({
                          id: g.id,
                          option: g.name,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6} style={gradeOrDifficultyWrapperStyle}>
                      <NunitoText
                        value="Recommended Difficulty:"
                        fontSize={20}
                        marginBottom={10}
                        fontWeight={600}
                        italic
                      />
                      <ReusableAutocompleteWithID
                        type="default"
                        placeholder="Difficulty"
                        width="100%"
                        bgColor="#F2F2F2"
                        disabled={IsReadOnly}
                        grayedOut={IsReadOnly}
                        state={difficulty}
                        setState={updateRecommendation}
                        count={index}
                        // required
                        target={IsReadOnly ? "" : "difficulty"}
                        options={difficultyOptions.map((d) => ({
                          id: d.id,
                          option: d.name,
                        }))}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!IsReadOnly && (
                  <Grid item xs={0.5} style={iconWrapperStyle}>
                    <IconButton
                      onClick={() => updateRecommendation(index, "add")}
                    >
                      <AddIcon style={iconStyle} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))
          )}
          {!IsReadOnly && (
            <Grid
              container
              justifyContent="flex-end"
              style={buttonContainerStyle}
            >
              <ReusableButton
                text="Save"
                bgColor="#5E75C3"
                width={140}
                height={50}
                fontSize={16}
                onClick={() => onSubmit()}
              />
            </Grid>
          )}
        </AddPageWrapper>
      )}
    </>
  );
}
