import React, { useState } from 'react'
import { Box, Grid, FormControlLabel, RadioGroup, Radio } from '@mui/material'
import { showWarningSwal } from '../../functions/alert'
import { addDomain } from '../../functions/postData'
import { showNotification } from '../../functions/snackbar'
import ClearIcon from '@mui/icons-material/Clear'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocomplete from '../general/ReusableAutocomplete'
import ReusableButton from '../general/ReusableButton'
import ReusableCheckbox from '../general/ReusableCheckbox'
import { useSnackbar } from 'notistack'
import { invalidName } from '../../functions/general'
export default function AddDomainModal({ setOnAddDomain, afterAddingDomain, domainOptions }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [add, setAdd] = useState(1)
    const [addDomainOnly, setAddDomainOnly] = useState(false)
    const [domainName, setDomainName] = useState('')
    const [topicNames, setTopicNames] = useState([''])
    const [tagName, setTagName] = useState('')
    const onChangeTopicNames = (count, value) => {
        let newTopicNames = [...topicNames]
        newTopicNames[count] = value
        setTopicNames(newTopicNames)
    }
    console.log(domainOptions , domainName)
    const onDeleteTopicName = count => {
        let newTopicNames = [...topicNames]
        newTopicNames.splice(count, 1)
        setTopicNames(newTopicNames)
        if (!newTopicNames.length) setAddDomainOnly(true)
    }
    const allInArrayIsEmpty = arr => {
        if (!arr.length) return true
        return arr.every((val, i, arr) => val === arr[0]) && arr[0] === ''
    }
    const onSubmit = () => {
        let warningMessage = ''
        if (add === 1) {
            if (invalidName(domainName)) warningMessage += 'Please fill in something valid for Domain Name.<br>'
            if (addDomainOnly) {
                if (domainOptions.map(d => d.name).includes(domainName)) warningMessage += 'Domain name already exists.<br>'
            } else {
                if (allInArrayIsEmpty(topicNames)) {
                    warningMessage += 'If adding topics to an existing domain, please fill in at least one topic.<br>'
                }
                if (topicNames.includes('')) {
                    warningMessage += 'Please fill in all empty topic fields or remove them.<br>'
                }
            }
        } else {
            if (invalidName(tagName)) warningMessage += 'Please fill in something valid for Tag Name.'
        }
        if (warningMessage) return showWarningSwal(warningMessage)
        let payload = [{ is_tag: Number(tagName !== '') }]
        if (add === 2) payload[0].name = [tagName]
        else {
            if (addDomainOnly) {
                payload[0].name = [domainName]
            } else {
                if (domainOptions.map(d => d.name).includes(domainName)) {
                    payload[0].domain_id = domainOptions.find(d => d.name === domainName).id
                    payload[0].name = topicNames
                } else {
                    payload[0].name = [domainName, topicNames].flat()
                }
            }
        }
        console.log(payload)
        addDomain(payload).then(d => {
            console.log(d)
            if (d.status === 201) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                afterAddingDomain()
                setOnAddDomain(false)
            }
        }).catch(e => console.log(e))
    }
    const topicGridStyle = {
        display: 'flex', alignItems: 'center', marginBottom: 20
    }
    const iconStyle = { fontSize: 32, marginTop: 20, cursor: 'pointer' }
    const addOptions = [{ id: 1, option: 'Add Domain and Topic' }, { id: 2, option: 'Add Tag' }]
    const handleChange = e => {
        setDomainName('')
        setTopicNames([''])
        setTagName('')
        setAdd(Number(e.target.value))
    }
    const onChangeAddDomainOnly = value => {
        setTopicNames(value ? [] : [''])
        setAddDomainOnly(value)
    }
    return (
        <Box className='popUpModal horizontalScrollable' style={{ width: 1200, height: 600 }}>
            <div>
                <NunitoText value={add === 1 ? addDomainOnly ? 'ADD DOMAIN' : 'ADD DOMAIN AND TOPIC' : 'ADD TAG'}
                    fontSize={30} fontWeight={600} marginTop={20} color='#5E75C3' />
                {add === 1 && !addDomainOnly && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -45, marginRight: 20 }}>
                    <ReusableButton text='Add Topic' fontSize={14} bgColor='#5E75C3'
                        onClick={() => setTopicNames([...topicNames, ''])} iconType='add' />
                </div>}
                <Grid container justifyContent='space-between' style={{ paddingInline: '40px 30px', marginTop: 10 }}>
                    <RadioGroup value={add} onChange={handleChange} row>
                        {addOptions.map(({ id, option }, i) => (
                            <FormControlLabel key={i} value={id} control={<Radio style={{ color: '#000' }} />} label={option} />
                        ))}
                    </RadioGroup>
                    {add === 1 && <ReusableCheckbox type='modal' label='Add Domain Only'
                        state={addDomainOnly} setState={onChangeAddDomainOnly} fontSize={16} />}
                </Grid>
                {add === 1 && !addDomainOnly ? <Grid container justifyContent="space-between" style={{ marginTop: 20, paddingInline: '40px 30px' }}>
                    <ReusableAutocomplete type='withLabel' width={530} height={60} placeholder='Domain Name*' freeSolo
                        state={domainName} setState={setDomainName} btnBgColor='#144A94' borderColor='#707070'
                        options={domainOptions?.map(d => ({ id: d.id, option: d.name }))}
                        readOnly={tagName} grayedOut={tagName} required={domainName === ''} />
                    <div>
                        {topicNames.map((name, i) => (
                            <div key={i} style={topicGridStyle}>
                                <ReusableTextField type='withLabel' width={530} height={60} bgColor='#F2F2F2' placeholder='Topic*'
                                    state={name} setState={onChangeTopicNames} count={i}
                                    readOnly={addDomainOnly} grayedOut={addDomainOnly} required={name === '' && !addDomainOnly} />
                                <ClearIcon style={iconStyle} onClick={() => onDeleteTopicName(i)} />
                            </div>
                        ))}
                    </div>
                </Grid> : <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                    {add === 1 ? <ReusableTextField type='withLabel' width={530} height={60} bgColor='#F2F2F2' placeholder='Domain Name*'
                        state={domainName} setState={setDomainName} required />
                        : <ReusableTextField type='withLabel' width={530} height={60} bgColor='#F2F2F2' placeholder='Tag*'
                            state={tagName} setState={setTagName} readOnly={domainName} grayedOut={domainName} required />}
                </Grid>}
            </div>
            {/* {JSON.stringify({ domainName, tagName, topicNames, add, addDomainOnly })} */}
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30, paddingInline: 100 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => setOnAddDomain(false)} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}