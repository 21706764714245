import React from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { makeSureIsArray } from "../../functions/general";
import NunitoText from "../general/NunitoText";
import ReusableButton from "../general/ReusableButton";
import { Save } from "@mui/icons-material";

export default function ModerationInsetructions() {
  return (
    <Box
      className="popUpModal"
      style={{ width: 1300, minHeight: 365 }}
      justifyContent={"flex-start !important"}
    >
      <NunitoText
        value="Moderation Instruction"
        fontSize={30}
        fontWeight={600}
        color="#5E75C3"
        marginTop={30}
        marginBottom={15}
      />
      <Grid
        container
        display={"flex"}
        flexDirection={"column"}
        alignContent={"center"}
        justifyContent={"space-between"}
      >
        <Grid
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          width={"100%"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <ReusableButton
            text={"Confirm Award Range"}
            fontSize={15}
            width={"20%"}
            bgColor="#5E75C3"
            height={46}
          />
          <p style={{ fontSize: "15px", margin: "2%" }}>
            Once you have adjusted the minimum and maximum values for a specific
            award, it is necessary to select "Save Award Range" prior to moving
            on to another award. If this step is skipped, any adjustments made
            will not be retained. This action temporarily preserves the
            modifications, allowing for a review of the impact on participant
            awards without permanently committing the changes to the system.
          </p>
        </Grid>

        <Grid
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <ReusableButton
            text={"Save draft"}
            iconType={"save"}
            fontSize={15}
            width={"20%"}
            bgColor="#5E75C3"
            height={46}
          />
          <p style={{ margin: "2%", fontSize: "15px", marginLeft: "5px" }}>
            Upon clicking the "Save draft" button, the changes will be
            permanently updated to the system.{" "}
          </p>
        </Grid>

        <Grid
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
          alignContent={"center"}
        >
          <ReusableButton
            text="Reset Default"
            fontSize={15}
            bgColor="#E83042"
            width={"20%"}
            height={46}
            iconType="reset"
          />
          <p style={{ margin: "2%", fontSize: "15px", marginLeft: "5px" }}>
            Clicking the "Reset to Default" button will revert all modifications
            made to the award ranges for all awards back to their original
            values in the system.
            <br />
            Please note: The reset function will not be effective if the changes
            have already been saved to the system.
          </p>
        </Grid>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
          alignContent={"center"}
        >
          <Box width={"100%"}>
            <ReusableButton
              text="Complete Moderation"
              fontSize={15}
              bgColor="green"
              width={"20%"}
              height={46}
              iconType="check"
              marginRight={10}
            />
            <ReusableButton
              text="Moderation in Progress"
              fontSize={15}
              bgColor="#E83042"
              width={"20%"}
              height={46}
              iconType="close"
              marginLeft={10}
            />
          </Box>
          <p style={{ margin: "2%", fontSize: "15px", marginLeft: "5px" }}>
            clicking "Complete Moderation" will update the status of the
            competition level and the button to moderation in progress.
            <br />
            Clicking "Moderation in Progress" will update the status of the
            competition level and the button to complete moderation which means
            that the moderation process has been finalized.
            <br /> Note: the button text reflects the status of the moderation
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}
