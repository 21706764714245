import React from 'react'
import { Box, Grid } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'

export default function CheatingListInstructionsModal(){
   
    return(
        <Box className='popUpModal' style={{ width: 1300, overflow:'scroll' , height:700 }} justifyContent={'flex-start !important'}>
     <NunitoText value='Integrity Check Instruction' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={30} marginBottom={15} />
     <Grid container display={'flex'} flexDirection={'column'} alignContent={'center'} justifyContent={'space-between'}  >
        
        <Grid display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} width={'100%'} alignItems={'center'} alignContent={'center'} >
            <Grid>
        <ReusableButton text={"Unmark Participants as IAC"} fontSize={15} bgColor={"#5E75C3"}height={46} marginRight={30}/>
        <ReusableButton text={"Mark Participants as IAC"} fontSize={15} bgColor={"#5E75C3"}height={46} marginRight={30}/>
            </Grid>
                        
     <p style={{  fontSize:'15px' , margin:'2%'}}>when selecting a number of particiapnts via the checkbox next to them it Toggles the Participants IAC state to yes or no according to the word pressed.</p>
     </Grid>

     <Grid display={'flex'} flexDirection={'column'}  width={'100%'} alignItems={'center'} alignContent={'center'}  >
     <ReusableButton text={"Compute"}fontSize={15} bgColor={"#5E75C3"} height={46} marginRight={30} iconType="compute"/>
    <p style={{margin:'2%' , fontSize:'15px' , marginLeft:'5px'}}>Upon clicking the "Compute / re-compute" button, it will open a modal for you to determine the pecentage of matching answer , the minimum number of incorrect answers and the countires you wish to compute , if no countries are selected it will compute all countries by default. </p>
     </Grid>


     <Grid display={'flex'} flexDirection={'column'}  alignItems={'center'}  width={'100%'} alignContent={'center'} >
     <ReusableButton text={"Confirm Countries"} bgColor={"#5E75C3"}  height={46} fontSize={15}   />
     <p style={{margin:'2%' , fontSize:'15px' , marginLeft:'5px'}}>
     Clicking the "Confirm Countries" button will open a modal containing all countries with a checkbox next to them , checking the checkbox confirms that this country has passed the Integrity check</p>
     </Grid>

     <Grid display={'flex'} flexDirection={'column'}  alignItems={'center'}  width={'100%'} alignContent={'center'} >
     <ReusableButton text={"Download Csv"} bgColor={"#5E75C3"}  height={46} fontSize={15}   iconType={'download'} />
     <p style={{margin:'2%' , fontSize:'15px' , marginLeft:'5px'}}>
     Clicking the "Download csv" button will open a modal that allows you to download data for chosen countries as well as chosing the name of the downloaded file ,if no countries are selected it will download all countries by default. </p>
     </Grid>

     <Grid display={'flex'} flexDirection={'column'}  alignItems={'center'}  width={'100%'} alignContent={'center'} >
     <Grid>
        <ReusableButton text={"Multiple Attempts Participants"} fontSize={15} bgColor={"#5E75C3"}height={46} marginRight={30}/>
        <ReusableButton text={"Main Integrity List"} fontSize={15} bgColor={"#5E75C3"} height={46} marginRight={30}/>
            </Grid>
     <p style={{margin:'2%' , fontSize:'15px' , marginLeft:'5px'}}>
     Clicking the "Multiple Attempts Participants" button will display the data of Participants who has matching name, school, Country and Grade but with diffrent index.
     <br/>
     Clicking the "Main Integrity List" button will Revert the data back to the main list.
     </p>
     
     </Grid>
     <Grid display={'flex'} flexDirection={'column'}  alignItems={'center'}  width={'100%'} alignContent={'center'} >
     <ReusableButton text={"Mark IAC Incident"} fontSize={15} bgColor={ "#5E75C3" }height={46} marginRight={30} />
     <p style={{margin:'2%' , fontSize:'15px' , marginLeft:'5px'}}>
     Clicking the "Mark IAC Incident" button will open a modal that allows you to Mark a participant who doesnt exsist in the integrity check list.<br/> by using his index the user can check the participant's data to make sure its indeed the required student, after checking the user can specify the reason for elimination and mark him as IAC upon pressing confirm  </p>
     </Grid>

     <Grid display={'flex'} flexDirection={'column'}  alignItems={'center'}  width={'100%'} alignContent={'center'} >
     <ReusableButton text={"IAC Incident list"} fontSize={15} bgColor={ "#5E75C3" }height={46} marginRight={30} />
     <p style={{margin:'2%' , fontSize:'15px' , marginLeft:'5px'}}>
     Clicking the "IAC Incident list" button will open a modal that displays the data of the participants marked as IAC using the "Mark IAC Incident" button, upon selecting atleast one participant from the table using the checkbox next to each participants, a button will apear to allow the user to unmark the selected participants  </p>
     </Grid>

     </Grid>
    </Box>
    )
}