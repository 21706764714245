import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import AddDomainModal from '../components/Domain/AddDomainModal'
import EditDomainModal from '../components/Domain/EditDomainModal'
import DeleteModal from '../components/modal/DeleteModal'
import ApproveModal from '../components/modal/ApproveModal'
import {
    makeSureIsArray, makeOptions, checkIfAllSelectedRowsMatchStatus, resetAll,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getDomains } from '../functions/getData'
import { approveDomain } from '../functions/patchData'
import { deleteDomain } from '../functions/deleteData'
import { showNotification } from '../functions/snackbar'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
import FilterWrapper from '../components/general/FiltersWrapper'
const domainHeader = [
    { id: 'topic_domain', label: 'Domain' },
    { id: 'name', label: 'Topic' },
    { id: 'tag', label: 'Tag' },
    { id: 'created_by', label: 'Created By' },
    { id: 'last_modified_by', label: 'Last Modified By' },
    { id: 'status', label: 'Status' },
]
const initialParams = '?'
export default function EnhancedDomains() {
    document.title = 'Domains and Tags'
    const isMounted = useRef(false)
    const [header, setHeader] = useState(domainHeader)
    const [domainTable, setDomainTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "Domain", state: '', key: 'domain_id', header: 'Domain' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [viewOptions, setViewOptions] = useState([
        { label: "Created By", state: true, key: 'created_by_username' },
        { label: 'Last Modified By', state: true, key: 'modified_by_username' },
        { label: "Status", state: true, key: 'status' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [domain,setDomain] = useState('')
    const [status,setStatus]=useState('')
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddDomain, setOnAddDomain] = useState(false)
    const [domainOptions, setDomainOptions] = useState([])
    const [onEditDomain, setOnEditDomain] = useState(false)
    const [onApprove, setOnApprove] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setId)
    const cancelEdit = () => cancelAction(setOnEditDomain, setId)
    const cancelApprove = () => cancelAction(setOnApprove, setId)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const afterFunction = (cancel, data) => {
        if ([200, 201].includes(data.status)) {
            resetAll(initialParams, filtered, setParams, setSearch, setFiltered)
            getData(initialParams, signal)
            cancel()
            showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
            setSelected([])
            setSelecting([])
        }
    }
    
    const filters =[{
        label:"Domain",
        endpoint:"tag/list?get_filter=domains",
        value:domain,
        onChange:setDomain,
        key:'domains',
        setValue:setDomain
      },
      {
        label:"Status",
        endpoint:"tag/list?get_filter=status",
        value:status,
        onChange:setStatus,
        key:'status',
        setValue:setStatus
      }]

      const adjustEndpoint = (endpoint , value)=>{
        let initalEndpoint = endpoint
        if(value!==domain&&domain!==''){
          initalEndpoint+=`&domains=${domain}`
        }
        if(value!==status&&status!==''){
          initalEndpoint+=`&status=${status}`
        }
       return initalEndpoint
      }

    const deleteFunction = () => {
        setLoading(true)
        deleteDomain({ id: id === '' ? selected : [id] }).then(d => {
            getDomains('?status=active&get_filter=domains', signal).then(d => {
                const formattedDomains = d.map(domain=>{
                    return {id:domain.filter_id , name:domain.filter_name}
                })
                setDomainOptions(formattedDomains)
            }).catch(e => console.log(e))
            afterFunction(cancelDelete, d)
        }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
    }
    const approveFunction = () => {
        setLoading(true)
        approveDomain({ id: id === '' ? selected : [id] }).then(d => {
            afterFunction(cancelApprove, d)
        }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
    }
    useEffect(()=>{
        getData(params, signal)
          },[ domain,status])

    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        setLoading(true);
        let param=params+''
        filters.map((filter)=>{
            if(filter.value!=='') param+=`&${filter.key}=${filter.value}`
        })
        getDomains(`${param}` , signal).then(res=>{
            console.log(res)
           setDomainTable(res)
           setLoading(false)
        })
    }
    const onChangeViewOptions = value => changeViewOptions(domainHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
  
    const onChangeSearch = (value) => {
        setSearch(value)
        let param = params
        param = param.replace(/&search=[^&]*/g, '');
        if (JSON.stringify(value) === JSON.stringify(search)) return console.log('Search is unchanged')
            if(value!==''){  
              param+=`&search=${value}`
              console.log(value)
       }
            getData(param , signal)
            setParams(param)
      };

    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    const loadTableAndOptions = useCallback(() => {
        getData(initialParams, signal)
        getDomains('?status=active&get_filter=domains', signal).then(d => {
            const formattedDomains = d.map(domain=>{
                return {id:domain.filter_id , name:domain.filter_name}
            })
            setDomainOptions(formattedDomains)
        }).catch(e => console.log(e))
    }, [signal])

    useEffect(() => {
        isMounted.current = true
        loadTableAndOptions()
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller, signal, loadTableAndOptions])
    const onClickMassApprove = () => {
        return domainTable && checkIfAllSelectedRowsMatchStatus(selected, domainTable.data, ['pending'])
            ? setOnApprove(true)
            : showWarningSwal('Please select pending domains / tags only to mass approve')
    }
    const onClickMassDelete = () => {
        return selected.length ? setOnDelete(true) : showWarningSwal('Please select at least one domain to mass delete')
    }
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            <Collapse in={filtering}>
            <FilterWrapper
                filters={filters}
                adjustEndpoint={adjustEndpoint}
                />
            </Collapse>
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Domain' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div>
            </Grid>
            <Grid className='tableContainer' container>
                <NunitoText value='Domains and Tags' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                    <div className='endBtnDiv'>
                        <ReusableButton text='Add Domain / Tag' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => setOnAddDomain(true)} iconType='add' />
                        <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46}
                            marginRight={20} onClick={() => onClickMassDelete()} iconType='delete' />
                        <ReusableButton text='Mass Approve' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => onClickMassApprove()} iconType='check' />
                    </div>
                </Grid>
                <NunitoText value='Search only in "Domain", "Topic", and "Tag" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loadingTable && <Loader height={600} />}
                {!loadingTable && domainTable && makeSureIsArray(domainTable?.data).length &&
                    <HeavyTable headers={header} list={domainTable} title='domain' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} fixed={['topic_domain', 'name', 'tag']}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected} setId={setId}
                        setOnApprove={setOnApprove} setOnEdit={setOnEditDomain} />}
                {!loadingTable  && domainTable && !makeSureIsArray(domainTable?.data).length && <NoData height={600} />}
                <Modal open={onDelete} onClose={() => cancelDelete()}>
                    <>
                        {Boolean(domainTable) && <DeleteModal table={domainTable} id={id} selected={selected}
                            deleteFunction={deleteFunction} cancelDelete={cancelDelete} />}
                    </>
                </Modal>
                <Modal open={onApprove} onClose={() => cancelApprove()}>
                    <>
                        {Boolean(domainTable) && <ApproveModal table={domainTable} id={id} selected={selected}
                            approveFunction={approveFunction} cancelApprove={cancelApprove} object='domain/tags' />}
                    </>
                </Modal>
                <Modal open={onAddDomain} onClose={() => setOnAddDomain(false)}>
                    <>
                        <AddDomainModal setOnAddDomain={setOnAddDomain} afterAddingDomain={() => loadTableAndOptions()}
                            domainOptions={domainOptions} />
                    </>
                </Modal>
                <Modal open={onEditDomain} onClose={() => cancelEdit()}>
                    <>
                        {domainTable && <EditDomainModal table={domainTable} id={id}
                            afterEditingDomain={() => loadTableAndOptions()} cancelEdit={cancelEdit} />}
                    </>
                </Modal>
            </Grid>
        </Box>
    )
}