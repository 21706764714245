import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Grid, Collapse, Modal } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Filter from "../components/Filter";
import HeavyTable from "../components/table/HeavyTable";
import DeleteModal from "../components/modal/DeleteModal";
import {
  makeSureIsArray,
  makeOptions,
  resetAll,
  changeViewOptions,
  changeRowsPerPage,
  changeFiltered,
  changeSearch,
  loadData,
  controlView,
  controlFilter,
  cancelAction,
} from "../functions/general";
import { showWarningSwal } from "../functions/alert";
import { useDispatch } from "react-redux";
import { getCompReportData, getModerateReport, getReport } from "../functions/getData";
import { deleteCompetition } from "../functions/deleteData";
import { showNotification } from "../functions/snackbar";
import {
  isAdmin,
  isPartner,
  isPartnerOrAssistant,
} from "../functions/checkrole";
import NunitoText from "../components/general/NunitoText";
import ReusableTextField from "../components/general/ReusableTextField";
import ReusableButton from "../components/general/ReusableButton";
import ViewAndFilterButton from "../components/ViewAndFilterButton";
import LoadingBackdrop from "../components/general/LoadingBackdrop";
import NoData from "../components/general/NoData";
import Loader from "../components/general/Loader";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import EditParticipantRankingModal from "../components/modal/EditParticipantRankingModal";
import { patchEditParticipantRanking } from "../functions/patchData";
import ConfirmModal from "../components/modal/ConfirmModal";
import { MarkParticipant } from "../functions/postData";
import FilterWrapper from "../components/general/FiltersWrapper";

const competitionHeader = () => [
  { id: "name", label: "participant" },
  { id: "index_no", label: "index" },
  { id: "iac_status", label: "status" },
  { id: "certificate_no", label: "certificate number" },
  { id: "competition", label: "Competition" },
  { id: "organization", label: "organization" },
  { id: "country", label: "country" },
  { id: "level", label: "level" },
  { id: "grade", label: "grade" },
  { id: "school", label: "school" },
  { id: "tuition_centre", label: "tuition" },
  { id: "points", label: "points" },
  { id: "award", label: "award" },
  { id: "school_rank", label: "school rank" },
  { id: "country_rank", label: "country rank" },
  { id: "global_rank", label: "global rank" },
];
const initialParams = "?";
export default function EnahncedCompReport() {
  document.title = "Competition Report";
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const [header, setHeader] = useState(competitionHeader());
  const [award , setAward] = useState('')
  const [grade , setGrade] = useState('')
  const [country , setCountry] = useState('')
  const [status , setStatus] = useState('')
  const [competitionTable, setCompetitionTable] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [params, setParams] = useState(initialParams);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [viewing, setViewing] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [id, setId] = useState("");
  const [selected, setSelected] = useState([]);
  const [selecting, setSelecting] = useState([]);
  const [confirmMarking , setConfirmMarking] = useState(false)
  const [onEditParticipantRanking, setOnEditParticipantRanking] =
    useState(false);
  const [participant, setParticipant] = useState("");
  const [onDelete, setOnDelete] = useState(false);
  const [onAddPartner, setOnAddPartner] = useState(false);
  const [exportLoading  , setExportLoading] = useState(false)
  const [onUpload, setOnUpload] = useState(false);
  const handleView = () => controlView(viewing, setViewing, setFiltering);
  const handleFilter = () => controlFilter(filtering, setFiltering, setViewing);
  const cancelDelete = () => cancelAction(setOnDelete, setId);
  const cancelAddPartner = () => cancelAction(setOnAddPartner, setId);
  const cancelUpload = () => cancelAction(setOnUpload, setId);
  const cancelEditParticipantRanking = () =>
    cancelAction(setOnEditParticipantRanking);

  const cancelConfirmMarking = ()=>{
    cancelAction(setConfirmMarking)
  }
  let location = useLocation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(()=>{
    getData(params, signal)
      },[country, status,grade , award])

const adjustEndpoint = (endpoint , value)=>{
    let initalEndpoint = endpoint
    if(value!==grade&&grade!==''){
      initalEndpoint+=`&grade=${grade}`
    }
    if(value!==country&&country!==''){
      initalEndpoint+=`&country=${country}`
    }
    if(value!==status&&status!==''){
      initalEndpoint+=`&status=${status}`
    }
    if(value!==award&&award!==''){
      initalEndpoint+=`&award=${award}`
    }
   return initalEndpoint

  }

  const filters =[{
    label:"Award",
    endpoint:`competition/report/${location.search.slice(4)}?get_filter=award`,
    value:award,
    onChange:setAward,
    key:'award',
    setValue:setAward
  },{
    label:"country",
    endpoint:`competition/report/${location.search.slice(4)}?get_filter=country`,
    value:country,
    onChange:setCountry,
    key:'country',
    setValue:setCountry
  },
  {
    label:"Status",
    endpoint:`competition/report/${location.search.slice(4)}?get_filter=status`,
    value:status,
    onChange:setStatus,
    key:'status',
    setValue:setStatus
  },
  {
    label:"Grade",
    endpoint:`competition/report/${location.search.slice(4)}?get_filter=grade`,
    value:grade,
    onChange:setGrade,
    key:'grade',
    setValue:setGrade
  }]
  
  const onChangeRowsPerPage = value => {
    changeRowsPerPage(value, rowsPerPage, '', search, signal, setRowsPerPage, setParams, getData)
}

const formatTableData = (data)=>{
  let formattedData = data?.map(el=>{
     return  { 
       name:el.name,
       index_no:el.index_no,
       iac_status:el?.iac_status,
       certificate_no:el?.certificate_no,
       competition:el.competition,
       organization:el.organization,
       country:el.country,
       level:el.level,
       grade:el.grade,
       school:el.school,
       tuition_centre:el.tuition_centre,
       points:el.points,
       award:el.award,
       school_rank:el.school_rank,
       country_rank:el.country_rank,
       global_rank:el.global_rank
     }
   })
   return formattedData
 }

  
const onChangeSearch = (value) => {
  setSearch(value)
  let param = params
  param = param.replace(/&search=[^&]*/g, '');
  if (JSON.stringify(value) === JSON.stringify(search)) return console.log('Search is unchanged')
      if(value!==''){  
        param+=`&search=${value}`
        console.log(value)
 }
      getData(param , signal)
      setParams(param)
};

  const onConfirmEditParticipantRanking = (participantIndex, payload) => { 
    console.log(payload , 'payload goes here'); 
    showNotification(
      "success",
      "calculating ...",
      enqueueSnackbar,
      closeSnackbar
    );
    patchEditParticipantRanking(participantIndex, payload)
      .then((res) => {
        // setCanbeComputed(false);
        // setCounter(!counter);
        if (res.error) {
          showNotification(
            "warning",
            res.error,
            enqueueSnackbar,
            closeSnackbar
          );
        } else {
          showNotification(
            "success",
            res.message,
            enqueueSnackbar,
            closeSnackbar,
            setOnEditParticipantRanking(false),
            getData(
              `${params}&page=${competitionTable.data.current_page}`,
              signal
            )
          );
        }
      })
      .catch((err) => {});
  };

  const ConfirmMarkingPostRequest = ()=>{
    MarkParticipant(participant.index_no).then(res=>{
      if (res.error) {
        showNotification(
          "warning",
          res.error,
          enqueueSnackbar,
          closeSnackbar
        );
      } else {
        showNotification(
          "success",
          res.message,
          enqueueSnackbar,
          closeSnackbar,
          cancelConfirmMarking(),
          getData(
            `${params}&page=${competitionTable.data.current_page}`,
            signal
          )
        );
      }
    })
  }
  const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
  const controller = useMemo(() => new AbortController(), []);
  const signal = controller.signal;
  const getData = (params, signal) => {
    setLoadingTable(true);
    let param=params+''
   
    filters.map((filter)=>{
        if(filter.value!=='') param+=`&${filter.key}=${filter.value}`
    })
    if (location.search.slice(0, 4) === "?id=") {
      getCompReportData(location.search.slice(4), param, signal)
        .then((res) => {
          res.data= formatTableData(res.data)        
          setCompetitionTable(res);
          console.log(res)

          setLoadingTable(false);
        })
        .catch((e) => console.log(e));
    } else {
      showWarningSwal("Wrong query");
      if (!signal.aborted) {
        setLoading(false);
        setFirstLoaded(true);
      }
    }
  };

  const exportcsv = (e)=>{ 
    console.log('here')
  let param=params+''
 param = param+="&mode=csv"
  filters.map((filter)=>{
      if(filter.value!=='') param+=`&${filter.key}=${filter.value}`
  })
  if (location.search.slice(0, 4) === "?id=") {
  
    getCompReportData(location.search.slice(4), param, signal)
      .then((res) => {
       exportToCsv(e , res , 'competion-report')
        
      })
      .catch((e) => console.log(e));
     
  } else {
    showWarningSwal("Wrong query");
    if (!signal.aborted) {
      setLoading(false);
      setFirstLoaded(true);
    }
  }
   setExportLoading(false)
}

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const exportToCsv = (e,data , compname) => {
    // Headers for each column
    let headers = [
      "competition,organization,status,country,grade,school,tuition,index,participant,certificate number,points,award,country_rank,school_rank,global_rank",
    ];
    // Convert users data to a csv
    data.shift()
    let usersCsv = data.reduce((acc, user) => {
     
      const {
        competition,
        organization,
        status,
        country,
        grade,
        school,
        tuition_centre,
        index_no,
        name,
        certificate_no,
        points,
        award,
        country_rank,
        school_rank,
        global_rank,
      } = user;
      acc.push(
        [
          competition,
          organization,
          status,
          country,
          grade,
          school,
          tuition_centre,
          index_no,
          name,
          certificate_no,
          points,
          award,
          country_rank,
          school_rank,
          global_rank,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: `${compname}.csv`,
      fileType: "text/csv",
    });
  };

  useEffect(() => {
    isMounted.current = true
    getData(initialParams, signal)
   
    return () => {
        controller.abort()
        isMounted.current = false
    }
}, [controller, signal])

  return (
    <Box className="wrapperBox">
      <LoadingBackdrop loading={loading} />

      <Collapse in={filtering}>
      <FilterWrapper
        filters={filters}
        adjustEndpoint={adjustEndpoint}
        />
      </Collapse>
      <Grid
        className="firstRowContainer"
        container
        justifyContent="space-between"
      >
        <div className="dashboardAndSelfBtnDiv">
          <ReusableButton
            text="Dashboard"
            fontSize={14}
            bgColor="#F16774"
            height={36}
            width={125}
            br={18}
            to="/dashboard"
            iconType="home"
          />
          <ChevronRightIcon />
          <ReusableButton
            text="Competition Report"
            fontSize={14}
            bgColor="#F16774"
            height={36}
            br={18}
          />
        </div>
        <div className="viewAndFilterBtnDiv">
          <ViewAndFilterButton
            text="Filter"
            state={filtering}
            fontSize={14}
            height={42}
            onClick={handleFilter}
          />
        </div>
      </Grid>
      <Grid className="tableContainer" container>
        <NunitoText
          value="Competition Report"
          fontSize={40}
          fontWeight={700}
          italic
          color="#144A94"
        />
        <Grid
          className="searchAndBtnContainer"
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <ReusableTextField
            type="search"
            width={500}
            height={60}
            bgColor="#F2F2F2"
            placeholder="Search using keyword"
            state={search}
            setState={onChangeSearch}
            onBlur
          />
          {
            <div className="endBtnDiv">
              <ReusableButton
                loading={exportLoading}
                text="Export Csv"
                fontSize={15}
                bgColor="#5E75C3"
                height={46}
                marginRight={20}
                iconType="export"
                onClick={(e) => {
                setExportLoading(true)
                exportcsv(e)
                }}
              />
            </div>
          }
        </Grid>
        <NunitoText
          value='Search only in "Competition" column'
          fontSize={20}
          fontWeight={400}
          italic
          color="#144A94"
        />
        {loadingTable && <Loader height={600} />}
        {!loadingTable &&
          competitionTable?.data &&
          makeSureIsArray(competitionTable?.data).length && (
            <HeavyTable
              onChangeRowsPerPage={onChangeRowsPerPage}
              headers={header}
              list={competitionTable}
              title="competition Report"
              rowsPerPage={rowsPerPage}
              setOnEditParticipantRanking={setOnEditParticipantRanking}
              setOnDelete={setOnDelete}
              onChangePage={onChangePage}
              fixed={[]}
              isSelecting={true}
              selecting={selecting}
              setSelecting={setSelecting}
              selected={selected}
              setSelected={() => {}}
              setId={setParticipant}
              setConfirmMarking={setConfirmMarking}
            />
          )}
        {!loadingTable &&
          competitionTable &&
          competitionTable.data.length === 0 && <NoData height={600} />}
      </Grid>
      <Modal
        open={onEditParticipantRanking}
        onClose={() => cancelEditParticipantRanking()}
      >
        {/* <Box>
          <ReusableButton text="sdsda" />
        </Box> */}
        <EditParticipantRankingModal
          participant={participant}
          confirmFunction={onConfirmEditParticipantRanking}
          cancelConfirm={cancelEditParticipantRanking}
        />
        {/* <DuplicateTask
              canceldub={canceldubs}
              id={id}
              duplicate={duplicate}
              table={taskTable}
            /> */}
      </Modal>
      {competitionTable&&competitionTable.data&&<Modal
       open={confirmMarking}
       onClose={() => cancelConfirmMarking()}
      >
            <ConfirmModal id={participant.index_no} selected={[participant]} table={competitionTable} action="Mark" confirmFunction={ConfirmMarkingPostRequest} cancelConfirm={cancelConfirmMarking}/>
      </Modal>}
    </Box>
  );
}
