import { useState, useEffect, useRef } from 'react'
// const getDimensions = ref => ({
//     width: Math.round(ref.current.getBoundingClientRect().width),
//     height: Math.round(ref.current.getBoundingClientRect().height)
// })
export default function useElementDimensions(ref) {
    const isMounted = useRef(false)
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
    useEffect(() => {
        isMounted.current = true
        const resize_ob = new ResizeObserver(entries => {
            let entry = entries[0]
            if (isMounted.current) {
                setDimensions({
                    width: Math.round(entry.contentRect.width),
                    height: Math.round(entry.contentRect.height)
                })
            }
        })
        resize_ob.observe(ref.current)
        return () => isMounted.current = false
    }, [ref])
    return dimensions
}