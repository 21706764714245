import React, { useState } from "react"
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateTask } from "../../actions"
import TitleBar from "../general/TitleBar"
import AddPageWrapper from "../general/AddPageWrapper"
import DescriptionEditor from "../DescriptionEditor"
import ReusableTextField from "../general/ReusableTextField"
const contentContainerStyle = { margin: '36px 60px' }
const fieldsContainerStyle = { marginBottom: 40 }
export default function TaskContent({ count, task }) {
    const [expand, setExpand] = useState(false)
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
    const { taskTitle, language } = task
    return (
        <Grid container>
            <TitleBar title='Task Content' state={expand} setState={setExpand} />
            {expand && <AddPageWrapper>
                <Grid container style={contentContainerStyle}>
                    <Grid container alignItems='center' style={fieldsContainerStyle}>
                        <Grid item xs={3}>
                            <ReusableTextField type='withLabel' placeholder='Language' state='English' setState={() => { }}
                                grayedOut fullWidth />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={7}>
                            <ReusableTextField type='withLabel' placeholder='Task Title' state={taskTitle} setState={update}
                                grayedOut readOnly fullWidth />
                        </Grid>
                    </Grid>
                    <DescriptionEditor state={language} setState={update} height={500}
                        placeholder='Task Content For English' count={count} head='taskText' target='language' />
                </Grid>
            </AddPageWrapper>}
        </Grid >
    )
}