const descendingComparator = (a, b, orderBy) => {
    // If type is string, make it case insensitive by lowercasing everything
    // If type if object, compare by length
    if (typeof a[orderBy] === 'object') {
        a = a[orderBy] !== undefined && a[orderBy] !== null ? a[orderBy].length : a[orderBy]
        b = b[orderBy] !== undefined && b[orderBy] !== null ? b[orderBy].length : b[orderBy]
    } else if (typeof a[orderBy] === 'string') {
        a = a[orderBy] !== undefined && a[orderBy] !== null ? a[orderBy].toLowerCase() : a[orderBy]
        b = b[orderBy] !== undefined && b[orderBy] !== null ? b[orderBy].toLowerCase() : b[orderBy]
    } else {
        a = a[orderBy]
        b = b[orderBy]
    }
    // a = typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy]
    // b = typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy]
    if (b === a) return 0
    else if (a === null || a === undefined) return 1
    else if (b === null || b === undefined) return -1
    else if (b < a) return -1
    else if (b > a) return 1
}
export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}
export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        return order !== 0 ? order : a[1] - b[1]
        // if (order !== 0) {
        //     return order
        // }
        // return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}