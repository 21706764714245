import React from 'react'
import { Grid } from '@mui/material'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import ReusableTextField from '../general/ReusableTextField'
import DescriptionEditor from '../DescriptionEditor'
const containerStyle = { paddingBottom: 20 }
const marginStyle = { margin: '36px 60px' }
const fieldsContainerStyle = { marginBottom: 40 }
export default function MainContent({ count, language, updateLanguages,isRead }) {
    const { name, task_title, content } = language
    return (
        <Grid container style={containerStyle}>
            <TitleBar title={name} fixed />
            <AddPageWrapper>
                <Grid container style={marginStyle}>
                    <Grid container alignItems='center' style={fieldsContainerStyle}>
                        <Grid item xs={3}>
                            <ReusableTextField type='withLabel' placeholder='Language' state='English' grayedOut fullWidth />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={7}>
                            <ReusableTextField type='withLabel' placeholder='Task Title*' state={task_title} setState={updateLanguages} disabled={isRead} grayedOut={isRead}
                                fullWidth count={count} target='task_title' onBlur required bgColor='#F2F2F2' />
                        </Grid>
                    </Grid>
                    <DescriptionEditor state={content} setState={updateLanguages} height={500}
                        placeholder='Task Content For English' count={count} target='content' disabled={isRead} grayedOut={isRead} />
                </Grid>
            </AddPageWrapper>
        </Grid>
    )
}