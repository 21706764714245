import React, { useState } from 'react'
import { Grid } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useSelector, useDispatch } from 'react-redux'
import { updateCompetition } from '../../actions'
import { showInfoSwal } from '../../functions/alert'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import ReusableToggle from '../general/ReusableToggle'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import { getOrganizations } from '../../functions/getData'
const startColumnFlexStyle = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }
const ParticipatingCountry = ({ count, competition, competitionOrganization, countryPartnerCount, organizationOptions , countriesOptions }) => {

   console.log(count)
   const [expand, setExpand] = useState(true)
   const { competitionOrganizations } = competition
   const { organization_id, country_id, translate, allowEdit } = competitionOrganization
   const dispatch = useDispatch()
   const languageOptions = useSelector(state => state.languageOptions)
   const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
   let takenCountries = competitionOrganizations.filter((p, i) => i !== countryPartnerCount)
  let filteredCountriesOptions = countriesOptions.filter( country => !takenCountries.some(taken=>taken.country_id===country.id))
  let filteredOgnizationsOptions = organizationOptions.filter(org => org.country_id===country_id)
   console.log(country_id , organization_id)

   // let filteredOgnizationsOptions = [...new Set(organizationOptions.find(o => o.id === organization_id)?.users.filter(u => u.role_id === 2 && u.status !== 'deleted').map(u => u.country_id))]
   return (
      <Grid container>
         <TitleBar title={`Country ${countryPartnerCount + 1}`} state={expand} setState={setExpand} />
         {expand && <AddPageWrapper>
            <Grid container style={{ paddingBottom: 20 }}>
               <Grid container justifyContent='flex-end'>
                  <ClearIcon style={{ fontSize: 36, cursor: 'pointer', marginRight: 10, marginTop: 10 }}
                     onClick={() => update([count, countryPartnerCount], 'deleteCompetitionOrganization', 'competitionOrganizations')} />
               </Grid>
               <Grid container alignItems='flex-end' justifyContent='space-between' style={{ paddingInline: 20 }}>
                  
                  <ReusableAutocompleteWithID type='withLabel' placeholder='Country*' width={320} bgColor='#FFF'
                     required state={country_id} setState={update} borderColor='#707070'
                     count={[count, countryPartnerCount]} head='competitionOrganizationText' target='country_id'
                     options={filteredCountriesOptions.map(o => ({ id: o.id, option: o.name }))} />
                     <ReusableAutocompleteWithID type='withLabel' placeholder='Organization*' width={320} bgColor='#FFF'
                     required state={organization_id} setState={update} borderColor='#707070'
                     count={[count, countryPartnerCount]} head='competitionOrganizationText' target='organization_id'
                     options={filteredOgnizationsOptions?.map(o => ({ id: o.id, option: o.name }))} />
                  <ReusableAutocompleteWithID type='withLabel' placeholder='Translation' width={460}
                     bgColor='#FFF' state={translate} setState={update} multiple
                     count={[count, countryPartnerCount]} head='competitionOrganizationMultiple' target='translate'
                     borderColor='#707070' options={languageOptions.map(l => ({ id: l.id, option: l.name }))} />
                  {/* <ReusableAutocompleteWithID type='withLabel' placeholder='Country Partner*' width={320} bgColor='#FFF'
                            required state={partner_id} setState={update} borderColor='#707070'
                            count={[count, countryPartnerCount]} head='competitionOrganizationText' target='partner_id'
                            options={filteredUserOptions.map(u => ({ id: u.id, option: u.name }))} /> */}
                  <div style={startColumnFlexStyle}>
                     <NunitoText value='Allow Session Edits By Partners' fontSize={16} fontWeight={600} italic />
                     <ReusableToggle type='reduxText' width={160} falseValue='No' trueValue='Yes'
                        state={allowEdit} setState={update} count={[count, countryPartnerCount]}
                        head='competitionOrganizationBoolean' target='allowEdit' />
                  </div>
                  {/* <Grid container alignItems='flex-end' style={{ marginTop: 20 }}>
                            <div style={centerFlexStyle}>
                                <NunitoText value='Mode of Competition:' fontSize={18} fontWeight={600} italic />
                                <RadioGroup value={competition_mode} style={{ marginLeft: 20 }}
                                    onChange={e => update([count, countryPartnerCount], 'competitionOrganizationText', 'competition_mode', e.target.value)}>
                                    <Grid container>
                                        <FormControlLabel value='0' control={<Radio style={radioStyle} />} label="Paper Based" />
                                        <FormControlLabel value='1' control={<Radio style={radioStyle} />} label="Online" />
                                        <FormControlLabel value='2' control={<Radio style={radioStyle} />} label="Both" />
                                    </Grid>
                                </RadioGroup>
                                <ReusableButton text='Clear Selection' color='#000' borderColor='#000' fontSize={16} marginRight={20}
                                    onClick={() => update([count, countryPartnerCount], 'competitionOrganizationText', 'competition_mode', '')} />
                            </div>
                        </Grid> */}
               </Grid>
            </Grid>
         </AddPageWrapper>}
      </Grid>
   )
}
export default function ParticipatingCountries({ count, competition, organizationOptions , countriesOptions}) {
   const [expand, setExpand] = useState(true)
   const { rerun, competitionOrganizations } = competition
   const dispatch = useDispatch()
   const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
   const onClick = () => {
      return rerun ? showInfoSwal('No need to fill in anything',
         'Unselect rerun if you wish to make changes') :
         update([count, competitionOrganizations.length], 'addCompetitionOrganization', 'competitionOrganizations')
   }
   return (
      <Grid container>
         <TitleBar title='Participating Countries' state={expand} setState={setExpand}>
            <ReusableButton text='Add Participating Country' bgColor={rerun ? '#8D8D8D' : '#5E75C3'} height={46}
               fontSize={16} marginRight={40} iconType='add' allowSpam onClick={onClick} />
         </TitleBar>
         {Boolean(expand && competitionOrganizations.length) && <AddPageWrapper>
            <Grid container style={{ paddingBottom: 20 }}>
               {rerun && <Grid container justifyContent='center'>
                  <NunitoText value='You cannot edit anything beyond this point for competitions that are reruns' fontSize={20} />
               </Grid>}
               {competitionOrganizations.map((competitionOrganization, index) => (
                  <ParticipatingCountry key={index} count={count} competition={competition} competitionOrganization={competitionOrganization} 
                     countryPartnerCount={index} organizationOptions={organizationOptions}  countriesOptions={countriesOptions}/>
               ))}
            </Grid>
         </AddPageWrapper>}
      </Grid>
   )
}