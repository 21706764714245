import React, { useState, useEffect, useRef } from 'react'
import { Grid, Modal } from '@mui/material'
import { cancelAction } from '../../functions/general'
import { showNotification } from '../../functions/snackbar'
import { patchCompetitionOverallAward } from '../../functions/patchData'
import { deleteCompetitionOverallAward } from '../../functions/deleteData'
import AddOverallAwardModal from './AddOverallAwardModal'
import DeleteModal from '../modal/DeleteModal'
import ClearIcon from '@mui/icons-material/Clear'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import ReusableToggle from '../general/ReusableToggle'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import LoadingBackdrop from '../general/LoadingBackdrop'
import { useSnackbar } from 'notistack'
import { deepCopy } from '../../functions/edit'
const EachCriteria = ({ count, competition, setCompetition, criteriaCount, criteria }) => {
   const criteriaOptions = i => competition.rounds[i].rounds_awards.filter(a => a.name !== '').map(a => ({ id: a.id, name: a.name }))
   const options = criteriaOptions(criteriaCount)
   const updateCriterias = value => {
      let newCompetition = window.structuredClone(competition)
      newCompetition.overall_awards_groups[count].criterias[criteriaCount] = value
      setCompetition(newCompetition)
   }
   useEffect(() => {
      if (!options.find(o => o.id === criteria) && criteria !== null) {
         updateCriterias(null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [options])
   return (
      <ReusableAutocompleteWithID type='withLabel' placeholder={`Round ${criteriaCount + 1} Criteria*`} width={320}
         bgColor='#FFF' borderColor='#707070' marginRight={30} state={criteria} setState={updateCriterias}
         options={options.map(g => ({ id: g.id, option: g.name }))} required />
   )
}
const containerStyle = {
   border: '1px solid #707070', borderRadius: 12, padding: 20,
   boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)', marginBottom: 30
}
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
const iconStyle = { fontSize: 30, cursor: 'pointer' }
const saveButtonContainerStyle = { marginBlock: 10 }
const EachOverallAward = ({ count, original, setOriginal, competition, setCompetition,
   setLoading, openDeleteModal, isMounted }) => {
   const { award_type, min_points, default_award_name } = competition
   const { id, name, percentage, criterias } = competition.overall_awards_groups[count]
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const updateOverallAward = (identifier, value) => {
      let newCompetition = window.structuredClone(competition)
      newCompetition.overall_awards_groups[count][identifier] = value
      setCompetition(newCompetition)
   }
   const onSubmit = () => {
      let payload = {
         id, award_type, min_points, default_award_name,
         award: [{
            name, percentage, round_criteria: criterias
         }]
      }
      setLoading(true)
      patchCompetitionOverallAward(payload).then(d => {
         if (d.status === 200) {
            showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
            if (isMounted.current) {
               let newCompetition = window.structuredClone(competition)
               setCompetition(newCompetition)
               setOriginal(deepCopy(newCompetition))
            }
         }
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   return (
      <Grid container style={containerStyle}>
         <Grid container justifyContent='space-between'>
            <div style={centerFlexStyle}>
               <ReusableTextField type='withLabel' placeholder='Label*' state={name} setState={updateOverallAward}
                  target='name' marginRight={30} required />
               {!award_type && <ReusableTextField type='withLabel' placeholder='percentile' state={percentage}
                  setState={updateOverallAward} target='percentage' required number />}
            </div>
            <ClearIcon style={iconStyle} onClick={() => openDeleteModal(id)} />
         </Grid>
         {criterias.map((criteria, i) => (
            <EachCriteria key={i} competition={competition} setCompetition={setCompetition} criteriaCount={i} criteria={criteria} />
         ))}
         <Grid container justifyContent='flex-end' style={saveButtonContainerStyle}>
            <ReusableButton text='Save' bgColor='#5E75C3' fontSize={15} onClick={() => onSubmit()} iconType='save' />
         </Grid>
      </Grid>
   )
}
const awardContainerStyle = { margin: 30, border: '1px solid #707070', borderRadius: 12, padding: 30 }
const optionContainerStyle = {
   border: '1px solid #707070', borderRadius: 12, padding: '10px 20px', marginBlock: 30,
   boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)'
}
const overallAwardOptionContainerStyle = { ...optionContainerStyle, display: 'flex' }
export default function OverallAward({ original, setOriginal, competition, setCompetition }) {
   const isMounted = useRef(false)
   const [loading, setLoading] = useState(false)
   const [onDelete, setOnDelete] = useState(false)
   const [id, setId] = useState('')
   const [onAddOverallAward, setOnAddOverallAward] = useState(false)
   const { award_type, min_points, default_award_name, overall_awards_groups } = competition
   const cancelAddOverallAward = () => cancelAction(setOnAddOverallAward)
   const cancelDelete = () => cancelAction(setOnDelete, setId)
   const openDeleteModal = id => {
      setId(id)
      setOnDelete(true)
   }
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const updateCompetition = (identifier, value) => {
      let newCompetition = { ...competition }
      newCompetition[identifier] = value
      setCompetition(newCompetition)
   }
   const afterAdd = (cancel, data) => {
      if ([200, 201].includes(data.status)) {
         cancel()
         showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
         if (isMounted.current) {
            let newCompetition = window.structuredClone(competition)
            for (const overallAward of data.data) {
               const { id, name, percentage, awards } = overallAward
               newCompetition.overall_awards_groups.push({
                  id: id,
                  name, percentage, criterias: awards.map(a => a.competition_rounds_awards_id)
               })
            }
            setCompetition(newCompetition)
            setOriginal(deepCopy(newCompetition))
         }
      }
   }
   const deleteFunction = () => {
      setLoading(true)
      let payload = { id: id }
      deleteCompetitionOverallAward(payload).then(d => {
         if (d.status === 200) {
            cancelDelete()
            showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
            if (isMounted.current) {
               let newCompetition = window.structuredClone(competition)
               newCompetition.overall_awards_groups = newCompetition.overall_awards_groups.filter(oa => oa.id !== id)
               setCompetition(newCompetition)
               setOriginal(deepCopy(newCompetition))
            }
         }
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   useEffect(() => {
      isMounted.current = true
      return () => isMounted.current = false
   }, [])
   return (
      <Grid container style={awardContainerStyle}>
         <LoadingBackdrop loading={loading} />
         <NunitoText value='Overall Awards' fontSize={20} fontWeight={600} italic textDecoration='underline' />
         <Grid container justifyContent='center'>
            <div style={overallAwardOptionContainerStyle}>
               <ReusableToggle type='text' width={270} falseValue='Percentage' trueValue='Position'
                  state={award_type} setState={updateCompetition} target='award_type' />
               <ReusableTextField type='default' width={340} placeholder='Minimum Points*' state={min_points}
                  setState={updateCompetition} target='min_points' marginLeft={50} required number />
            </div>
         </Grid>
         {overall_awards_groups.map((a, i) => (
            <EachOverallAward key={i} count={i} original={original} setOriginal={setOriginal}
               competition={competition} setCompetition={setCompetition}
               setLoading={setLoading} openDeleteModal={openDeleteModal} isMounted={isMounted} />
         ))}
         <Grid container alignItems='flex-end' justifyContent='space-between'>
            <div>
               <NunitoText value='Default Award*' fontSize={20} fontWeight={600} align='left' />
               <ReusableTextField type='default' width={280} placeholder='Label' state={default_award_name}
                  setState={updateCompetition} target='default_award_name*' required />
            </div>
            <ReusableButton text='Add Award' height={48} bgColor='#5E75C3' fontSize={16}
               onClick={() => setOnAddOverallAward(true)} iconType='add' />
         </Grid>
         <Modal open={onAddOverallAward} onClose={() => cancelAddOverallAward()}>
            <>
               <AddOverallAwardModal setLoading={setLoading} competition={competition}
                  cancelAddOverallAward={cancelAddOverallAward} afterAddingOverallAward={afterAdd} />
            </>
         </Modal>
         <Modal open={onDelete} onClose={() => cancelDelete()}>
            <>
               <DeleteModal table={{ data: overall_awards_groups }} id={id} selected={[]}
                  deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
            </>
         </Modal>
      </Grid>
   )
}