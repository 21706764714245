import React, { useState } from 'react'
import { Grid, Checkbox, FormControlLabel } from '@mui/material'
import NunitoText from './general/NunitoText'
import ReusableButton from './general/ReusableButton'
const containerStyle = { backgroundColor: '#F5F5F5', padding: '0 30px 15px 30px' }
const h3Style = { marginBottom: 0 }
const checkboxStyle = { color: '#000' }
const formControlLabelStyle = { position: 'relative', right: 15 }
const centerFlexStyle = { display: 'flex', alignItems: 'center', width: '100%' }
const inputStyle = { width: 52, height: 20, textAlign: 'center', marginLeft: 10 }
export default function ViewOption({ viewOptions, rowsPerPage, onChangeRowsPerPage, onChangeViewOptions }) {
    const [pageNo, setPageNo] = useState(rowsPerPage)
    const check = count => {
        let newViewOptions = [...viewOptions]
        newViewOptions[count].state = !newViewOptions[count].state
        onChangeViewOptions(newViewOptions)
    }
    const setAll = state => {
        let newViewOptions = [...viewOptions]
        newViewOptions.forEach(n => n.state = state)
        onChangeViewOptions(newViewOptions)
    }
    return (
        <Grid container style={containerStyle}>
            <div>
                <h3 style={h3Style}>Columns</h3>
                {viewOptions.map(({ label, state }, index) => (
                    <FormControlLabel
                        key={label}
                        value={state}
                        control={<Checkbox style={checkboxStyle} size="small" checked={state} />}
                        label={label}
                        labelPlacement="start"
                        style={formControlLabelStyle}
                        onChange={() => check(index)}
                    />
                ))}
            </div>
            <div style={centerFlexStyle}>
                <ReusableButton text='Show All' bgColor='#5E75C3' fontSize={16} height={44} width={120} br={8}
                    marginTop={10} marginRight={20} onClick={() => setAll(true)} />
                <ReusableButton text='Hide All' bgColor='#5E75C3' fontSize={16} height={44} width={120} br={8}
                    marginTop={10} onClick={() => setAll(false)} />
            </div>
            <div>
                <h3 style={h3Style}>Pagination</h3>
                <div style={centerFlexStyle}>
                    <p>Number of entries per page:</p>
                    <input type='number' style={inputStyle} value={pageNo}
                        onChange={e => setPageNo(e.target.value)}
                        onKeyDown={e => e.key === 'Enter' && onChangeRowsPerPage(pageNo)} />
                    {Number(pageNo) < 10 && <NunitoText value='Minimum entries per page is 10' color='#E83042' marginLeft={20} />}
                    {Number(pageNo) > 50 && <NunitoText value='Maximum entries per page is 50' color='#E83042' marginLeft={20} />}
                </div>
                <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} height={44} width={120} br={8}
                    onClick={() => onChangeRowsPerPage(pageNo)} />
            </div>
        </Grid>
    )
}