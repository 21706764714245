import React from 'react'
import { Box, Grid } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
export default function ConfirmMEndDate({endDate, confirmFunction, cancelConfirm , OrgName}) {
    return (
        <Box className='popUpModal' style={{ width: 705, minHeight: 310 }}>
            <NunitoText value='CONFIRM CHANGES' fontSize={30} fontWeight={600} color='#F16774' marginTop={30} />
            <NunitoText value={`The Date you are About To Change Exceeds the Global Competition End Date Are you sure You want To change it ?`} fontSize={20} fontWeight={600} />
            <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}  
                    onClick={() => {cancelConfirm()
                        
                    }} />
                <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63} width={280}
                    onClick={() => {
                       
                        confirmFunction()
                    }}   />
            </Grid>
        </Box>
    )
}