import React from 'react'
import { Box, Grid } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
export default function DeleteTaskFromCollectionModal({ table, id, selected, deleteFunction, cancelDelete, sectionCount }) {
    const selectedRows = makeSureIsArray(table.data).filter(s => id === '' ? selected.includes(s.id) : s.id === id)
    return (
        <Box className='popUpModal horizontalScrollable' style={{ width: 1000, height: 500 }}>
            <NunitoText value='DELETE TASK FROM COLLECTION' fontSize={30} fontWeight={600} color='#F16774' marginTop={20} />
            <NunitoText value={`Are you sure you want to delete these task(s) from Section ${sectionCount + 1}`}
                fontSize={20} fontWeight={600} />
            <div>
                {selectedRows.map((s, i) => (
                    <NunitoText key={i} value={`${s.name || s.title}${i < selectedRows.length - 1 && ', '}`} fontSize={20} fontWeight={700} />
                ))}
            </div>
            <Grid container justifyContent='center' style={{ marginBlock: '20px 40px' }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={22} width={300} marginRight={60}
                    onClick={() => cancelDelete()} />
                <ReusableButton text='Confirm' bgColor='#E83042' fontSize={22} width={300} marginLeft={60}
                    onClick={() => deleteFunction()} />
            </Grid>
        </Box>
    )
}