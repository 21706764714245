import React, { useState } from "react";
import { Grid, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateCompetition } from "../../actions";
import Level from "./Level";
import NunitoText from "../general/NunitoText";
import ReusableTextField from "../general/ReusableTextField";
import ReusableButton from "../general/ReusableButton";
import ReusableToggle from "../general/ReusableToggle";
import TitleBar from "../general/TitleBar";
import AddPageWrapper from "../general/AddPageWrapper";
import InputContainer from "../general/InputContainer";
const containerStyle = { paddingBottom: 20 };
const radioGroupContainerStyle = {
  marginTop: -180,
  height: 150,
  marginLeft: 750,
};
const radioGroupStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingLeft: 30,
  backgroundColor: "#FFF",
  width: 450,
  border: "1px solid",
  borderRadius: 16,
  marginLeft: 36,
};
const radioStyle = { color: "#000" };
const levelsAndAwardSettingsContainerStyle = {
  marginTop: 40,
  paddingInline: 36,
};
const optionContainerStyle = {
  border: "1px solid #707070",
  borderRadius: 12,
  padding: "10px 20px",
  marginBottom: 30,
  boxShadow: "5px 5px 12px rgba(0, 0, 0, 16%)",
};
const centerFlexStyle = { display: "flex", alignItems: "center" };
const defaultAwardDivStyle = { marginRight: 20 };
const buttonsContainerStyle = { paddingRight: 36 };
const Round = ({
  count,
  competition,
  round,
  roundCount,
  collectionOptions,
}) => {
  const [expand, setExpand] = useState(false);
  const {
    roundLabel,
    team,
    contributeToIndividualScore,
    teamOptions,
    levels,
    awardType,
    assignPoints,
    defaultAward,
    defaultPoints,
  } = round;
  const dispatch = useDispatch();
  const update = (count, head, target, text, event) =>
    dispatch(updateCompetition(count, head, target, text, event));
  return (
    <Grid container style={containerStyle}>
      <TitleBar
        title={`Round ${roundCount + 1}`}
        state={expand}
        setState={setExpand}
      >
        <div style={buttonsContainerStyle}>
          <ReusableButton
            text="Delete Round"
            bgColor="#E83D4D"
            fontSize={16}
            marginLeft={20}
            onClick={() => update([count, roundCount], "deleteRound", "rounds")}
            iconType="delete"
          />
          <ReusableButton
            text="Duplicate Round"
            bgColor="#F16774"
            fontSize={16}
            marginLeft={20}
            onClick={() =>
              update([count, roundCount + 1], "duplicateRound", "rounds", round)
            }
            iconType="copy"
          />
        </div>
      </TitleBar>
      {expand && (
        <AddPageWrapper>
          <Grid container>
            <InputContainer
              first
              label="Round Label*"
              field={
                <ReusableTextField
                  type="default"
                  placeholder="Round Label*"
                  state={roundLabel}
                  setState={update}
                  count={[count, roundCount]}
                  head="roundText"
                  target="roundLabel"
                  bgColor="#F2F2F2"
                  borderColor="#707070"
                  required
                  onBlur
                  fullWidth
                />
              }
            />
            {team ? (
              <>
                <InputContainer
                  label="Competition Configuration"
                  field={
                    <ReusableToggle
                      type="reduxText"
                      width={270}
                      falseValue="Individual"
                      trueValue="Team"
                      state={team}
                      setState={update}
                      count={[count, roundCount]}
                      head="roundBoolean"
                      target="team"
                    />
                  }
                />
                <InputContainer
                  last
                  label="Contribute to individual score?"
                  field={
                    <ReusableToggle
                      type="reduxIcon"
                      width={124}
                      state={contributeToIndividualScore}
                      setState={update}
                      count={[count, roundCount]}
                      head="roundBoolean"
                      target="contributeToIndividualScore"
                    />
                  }
                />
              </>
            ) : (
              <InputContainer
                last
                label="Competition Configuration"
                field={
                  <ReusableToggle
                    type="reduxText"
                    width={270}
                    falseValue="Individual"
                    trueValue="Team"
                    state={team}
                    setState={update}
                    count={[count, roundCount]}
                    head="roundBoolean"
                    target="team"
                  />
                }
              />
            )}

            {team && (
              <Grid container style={radioGroupContainerStyle}>
                <RadioGroup
                  style={radioGroupStyle}
                  value={teamOptions}
                  onChange={(e) =>
                    update(
                      [count, roundCount],
                      "roundText",
                      "teamOptions",
                      e.target.value
                    )
                  }
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio style={radioStyle} />}
                    label="1 Account answers tasks"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio style={radioStyle} />}
                    label="Tasks assigned by leader"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio style={radioStyle} />}
                    label="Free For All"
                  />
                </RadioGroup>
              </Grid>
            )}
          </Grid>
          <Grid container style={levelsAndAwardSettingsContainerStyle}>
            <Grid container>
              <NunitoText
                value="Levels"
                fontSize={20}
                fontWeight={600}
                marginBottom={10}
                align="left"
              />
            </Grid>
            {levels.map((level, index) => (
              <Level
                key={index}
                count={count}
                competition={competition}
                round={round}
                roundCount={roundCount}
                level={level}
                levelCount={index}
                collectionOptions={collectionOptions}
              />
            ))}
            <ReusableButton
              text="Add Level"
              bgColor="#5E75C3"
              fontSize={15}
              allowSpam
              marginBottom={30}
              onClick={() =>
                update([count, roundCount, levels.length], "addLevel", "levels")
              }
              iconType="add"
            />
          </Grid>
          {JSON.stringify(round)}
          <Grid container style={levelsAndAwardSettingsContainerStyle}>
            <Grid container>
              <NunitoText
                value="Award Settings"
                fontSize={20}
                fontWeight={600}
                marginBottom={10}
                align="left"
              />
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={optionContainerStyle}
            >
              <ReusableToggle
                type="reduxText"
                width={270}
                trueValue="Percentile"
                falseValue="Position"
                state={awardType}
                setState={update}
                count={[count, roundCount]}
                head="roundBoolean"
                target="awardType"
              />
              <div style={centerFlexStyle}>
                <NunitoText
                  value="Assign points manually"
                  fontSize={20}
                  marginRight={15}
                />
                <ReusableToggle
                  type="reduxIcon"
                  width={124}
                  state={assignPoints}
                  setState={update}
                  count={[count, roundCount]}
                  head="roundBoolean"
                  target="assignPoints"
                />
              </div>
              <div style={defaultAwardDivStyle}>
                <NunitoText
                  value="Default Award*"
                  fontSize={20}
                  fontWeight={600}
                  align="left"
                />
                <ReusableTextField
                  type="default"
                  width={280}
                  placeholder="Label*"
                  state={defaultAward}
                  setState={update}
                  count={[count, roundCount]}
                  head="roundText"
                  target="defaultAward"
                  required
                  onBlur
                />
              </div>
              <div style={{ visibility: !assignPoints && "hidden" }}>
                <NunitoText
                  value="Default Points*"
                  fontSize={20}
                  fontWeight={600}
                  align="left"
                />
                <ReusableTextField
                  type="default"
                  width={280}
                  placeholder="Enter default points*"
                  state={defaultPoints}
                  setState={update}
                  count={[count, roundCount]}
                  head="roundText"
                  target="defaultPoints"
                  required
                  onBlur
                  number
                />
              </div>
            </Grid>
          </Grid>
        </AddPageWrapper>
      )}
    </Grid>
  );
};
export default function Rounds({ count, competition, collectionOptions }) {
  const [expand, setExpand] = useState(false);
  const { rounds } = competition;
  const dispatch = useDispatch();
  const update = (count, head, target, text, event) =>
    dispatch(updateCompetition(count, head, target, text, event));
  return (
    <Grid container>
      <TitleBar title="Rounds" state={expand} setState={setExpand}>
        <ReusableButton
          text="New Round"
          height={42}
          bgColor="#5E75C3"
          fontSize={16}
          marginRight={40}
          onClick={() => update(count, "addRound", "rounds")}
          iconType="add"
        />
      </TitleBar>
      {expand && (
        <AddPageWrapper>
          {rounds.map((round, index) => (
            <Round
              key={index}
              count={count}
              competition={competition}
              round={round}
              roundCount={index}
              collectionOptions={collectionOptions}
            />
          ))}
        </AddPageWrapper>
      )}
    </Grid>
  );
}
