import React from 'react'
import { Box, Grid } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import { approveTask } from '../../functions/postData'
import { showNotification } from '../../functions/snackbar'
import { useSnackbar } from 'notistack'

export default function VerfiyCollectionModal({table, id, selected, approveFunction, cancelApprove}){
    const getSinglename = (id)=>{
        let single = makeSureIsArray(table.data).filter(s => s.id === id)
            return single[0].name ||single[0].identifier || single[0].competition_name
        }
        const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    return(
        <Box className='popUpModal' style={{ width: 705, minHeight: 365 }}>
        <NunitoText value='CONFIRM Verfiy' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={30} />
        <NunitoText value={`Are you sure you want to Verfiy this Collection?`} fontSize={20} fontWeight={600} />
       
       <NunitoText value={getSinglename(id)} fontSize={20} fontWeight={700} />
       <NunitoText value={`once Verfied it cannot be edited or deleted`} fontSize={20} fontWeight={600} />
        <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
            <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                onClick={() => cancelApprove()} />
            <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63} width={280}
                onClick={() => approveFunction()} />
        </Grid>
    </Box>
    )
}