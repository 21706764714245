import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import UploadStudentAnswerModal from '../components/Competitions/UploadStudentAnswerModal'
import {
    makeSureIsArray, makeOptions, resetAll,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { useDispatch } from 'react-redux'
import { resetCompetition } from '../actions'
import { getCompetitions } from '../functions/getData'
import { deleteCompetition } from '../functions/deleteData'
import { showNotification } from '../functions/snackbar'
import { isAdmin, isPartner, isPartnerOrAssistant } from '../functions/checkrole'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import AddParticipatingCountryModal from '../components/modal/AddParticipatingCountryModal'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
const competitionHeader = () => [
    { id: 'name', label: 'Competition' },
    { id: 'status', label: 'Status' },
    // { id: 'description', label: 'Description' },
    isPartner() ? { id: 'registration_open_date', label: 'Registration Date' } :
        { id: 'global_registration_date', label: 'Global Registration Date' },
    { id: 'competition_start_date', label: 'Competition Start' },
    { id: 'competition_end_date', label: 'Competition End' },
    isAdmin() && { id: 'competition_organization', label: 'Organization' },
    { id: 'format', label: 'Format' },
    // { id: 'rounds', label: 'Rounds' },
    // { id: 'tags', label: 'Tags' },
    // { id: 'levels', label: 'Levels' },
    // { id: 'awards', label: 'Awards' },
].filter(Boolean)
const initialParams = '?limits=10'
export default function Comp() {
    document.title = 'Moderate Competition'
    const isMounted = useRef(false)
    const dispatch = useDispatch()
    const [header, setHeader] = useState(competitionHeader())
    const [competitionTable, setCompetitionTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "Format", state: '', key: 'format', header: 'format' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        // { label: "Rounds", state: true, key: 'created_by_username' },
        // { label: "Levels", state: true, key: 'created_by_username' },
        // { label: "Awards", state: true, key: 'created_by_username' },
        isAdmin() && { label: "organization", state: true, key: 'competition_organization' },
        { label: "Format", state: true, key: 'format' },
        { label: "Status", state: true, key: 'status' }
    ].filter(Boolean))
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddPartner, setOnAddPartner] = useState(false)
    const [onUpload, setOnUpload] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setId)
    const cancelAddPartner = () => cancelAction(setOnAddPartner, setId)
    const cancelUpload = () => cancelAction(setOnUpload, setId)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const afterFunction = (cancel, data) => {
        if ([200, 201].includes(data.status)) {
            resetAll(initialParams, filtered, setParams, setSearch, setFiltered)
            getData(initialParams, signal)
            cancel()
            showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
            setSelected([])
            setSelecting([])
        }
    }
    const deleteFunction = () => {
        setLoading(true)
        deleteCompetition({ id: id === '' ? selected : [id] }).then(d => {
            afterFunction(cancelDelete, d)
        }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
    }
    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        loadData(setLoadingTable, setFirstLoaded, getCompetitions, params, setCompetitionTable, setFilterOptions,
            isPartnerOrAssistant() && { title: 'competition' }, signal)
    }
    const onChangeViewOptions = value => changeViewOptions(competitionHeader(), setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        changeFiltered(value, filtered, search, rowsPerPage, signal, setFiltered, setParams, getData)
    }
    const onChangeSearch = value => {
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    useEffect(() => {
        isMounted.current = true
        getData(initialParams, signal)
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller, signal])
    const onClickMassDelete = () => {
        return selected.length ? setOnDelete(true) : showWarningSwal('Please select at least one competition to mass delete')
    }
    console.log(competitionTable)
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Competitions' fontSize={14} bgColor='#F16774' height={36} br={18} onClick={() => dispatch(resetCompetition())} />
                </div>
                <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div>
            </Grid>
            <Grid className='tableContainer' container>
                <NunitoText value='Moderate Competitions' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                    {isAdmin() && <div className='endBtnDiv'>
                        <ReusableButton text='Add Competition' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            to='/addcompetition' iconType='add' />
                        {isAdmin() && <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46}
                            marginRight={20} onClick={() => onClickMassDelete()} iconType='delete' />}
                    </div>}
                </Grid>
                <NunitoText value='Search only in "Competition" column' fontSize={20} fontWeight={400} italic color='#144A94' />
                {loadingTable && <Loader height={600} />}
                {!loadingTable && competitionTable && !!makeSureIsArray(competitionTable.data).length &&
                    <HeavyTable headers={header} list={competitionTable} title='competitions' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} fixed={['name']}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                        setId={setId} setOnAddUser={setOnAddPartner} setOnUpload={setOnUpload}
                    />}
                {!loadingTable && firstLoaded && competitionTable && !makeSureIsArray(competitionTable.data).length && <NoData height={600} />}
            </Grid>
            <Modal open={onAddPartner} onClose={() => cancelAddPartner()}>
                <>
                    {competitionTable && <AddParticipatingCountryModal setLoading={setLoading} table={competitionTable} id={id}
                        cancelAddCompetitionOrganization={cancelAddPartner} afterAddingPartner={afterFunction} />}
                </>
            </Modal>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <>
                    {competitionTable && <DeleteModal table={competitionTable} id={id} selected={selected}
                        deleteFunction={deleteFunction} cancelDelete={cancelDelete} />}
                </>
            </Modal>
            <Modal open={onUpload} onClose={() => cancelUpload()}>
                <>
                    {competitionTable && <UploadStudentAnswerModal setLoading={setLoading} setOnUpload={setOnUpload}
                        table={competitionTable} id={id} afterUploadingAnswer={afterFunction} />}
                </>
            </Modal>
        </Box>
    )
}