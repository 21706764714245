import React, { useState } from 'react'
import { IconButton, Popper, ClickAwayListener } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setCountryOptions, setRoleOptions, setLanguageOptions, userLogout, resetTask, resetCollection, resetCompetition } from '../actions'
import { isTeacher } from '../functions/checkrole'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import NotificationsIcon from '@mui/icons-material/Notifications'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import NunitoText from './general/NunitoText'
import ReusableButton from './general/ReusableButton'
import { logout } from '../functions/httpMethods'
import { AppBar } from './DrawerAndAppBar'
const roles = [
    { id: 0, name: 'Super Admin' }, { id: 1, name: 'Admin' }, { id: 2, name: 'Country Partner' },
    { id: 4, name: 'Country Partner Assistant' }, { id: 5, name: 'School Manager' }, { id: 3, name: 'Teacher' }
]
const popperContainerStyle = {
    backgroundColor: '#FFF', borderRadius: 12, boxShadow: '0 0 3px #9E9E9E', padding: 15
}
const menuIconButtonStyle = { marginRight: 36, color: '#FFF' }
const notificationIconButtonStyle = { color: '#FFF' }
const arrowIconStyle = { position: 'relative', left: 6 }
const usernameStyle = { fontSize: 12, paddingLeft: 10 }
const centerStyle = { textAlign: 'center' }
const accountCircleIconStyle = { fontSize: 64 }
const buttonWrapperStyle = { marginTop: 20, display: 'flex', justifyContent: 'center' }
export default function NavBar({ open, toggleDrawer }) {
    //Open either notification or user popper, only one at a time
    const [anchorElNotif, setAnchorElNotif] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const toggleNotification = e => {
        setAnchorElNotif(anchorElNotif ? null : e.currentTarget)
        setAnchorElUser(null)
    }
    const toggleUser = e => {
        setAnchorElUser(anchorElUser ? null : e.currentTarget)
        setAnchorElNotif(null)
    }
    const logoutUser = () => {
        logout().then(d => {
            console.log(d)
            dispatch(resetTask())
            dispatch(resetCollection())
            dispatch(resetCompetition())
            dispatch(userLogout())
            dispatch(setLanguageOptions([]))
            dispatch(setCountryOptions([]))
            dispatch(setRoleOptions([]))
        })
        setAnchorElUser(null)
    }
    return (
        <AppBar open={open}>
            <IconButton onClick={() => toggleDrawer(true)} edge="start" style={{ ...menuIconButtonStyle, visibility: open && 'hidden' }}>
                <MenuIcon />
            </IconButton>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <IconButton onClick={toggleNotification} style={notificationIconButtonStyle}>
                    <NotificationsIcon />
                </IconButton>
                {Boolean(anchorElNotif) && <ClickAwayListener onClickAway={toggleNotification}>
                    <Popper open={Boolean(anchorElNotif)} anchorEl={anchorElNotif} style={popperContainerStyle}>
                        {['Test1', 'Test2', 'Test3'].map((notification, index) => (
                            <div key={index}>
                                <h4>Notification</h4>
                                <p>{notification}</p>
                            </div>
                        ))}
                        <ReusableButton text='View all notifications' bgColor='#F16774' iconType='notification' fontWeight={600} />
                    </Popper>
                </ClickAwayListener>}
                <IconButton onClick={toggleUser} style={notificationIconButtonStyle}>
                    <AccountCircleIcon />
                    <p style={usernameStyle}>{user.username}</p>
                    {Boolean(anchorElUser) ? <ArrowDropUpIcon style={arrowIconStyle} /> : <ArrowDropDownIcon style={arrowIconStyle} />}
                </IconButton>
                {Boolean(anchorElUser) && <ClickAwayListener onClickAway={toggleUser}>
                    <Popper open={Boolean(anchorElUser)} anchorEl={anchorElUser} style={popperContainerStyle}>
                        <div style={centerStyle}>
                            <AccountCircleIcon style={accountCircleIconStyle} />
                            <NunitoText value={user.username} fontSize={22} fontWeight={700} />
                            <NunitoText value={roles.find(r => r.id === user.role_id).name} fontWeight={500} marginTop={10} />
                        </div>
                        <div style={buttonWrapperStyle}>
                            <ReusableButton text='Home' bgColor='#5E75C3' width={100} height={36} iconType='home'
                                to={isTeacher() ? '/participants' : '/schools'} marginRight={10} />
                            <ReusableButton text='Profile' bgColor='#5E75C3' width={100} height={36} iconType='profile'
                                to='/profile' marginLeft={10} />
                        </div>
                        <div style={buttonWrapperStyle}>
                            <ReusableButton text='Log Out' bgColor='#E83042' width={160} height={36}
                                iconType='logout' fontWeight={600} onClick={logoutUser} />
                        </div>
                    </Popper>
                </ClickAwayListener>}
            </div>
        </AppBar>
    )
    // return (
    //     <AppBar position="fixed" open={open}>
    //         <Toolbar>
    //             {!open && <IconButton onClick={() => toggleDrawer(true)} edge="start" style={menuIconButtonStyle}>
    //                 <MenuIcon />
    //             </IconButton>}
    //             <Grid container justifyContent='flex-end' alignItems='center'>
    //                 <IconButton onClick={toggleNotification} style={notificationIconButtonStyle}>
    //                     <NotificationsIcon />
    //                 </IconButton>
    //                 {Boolean(anchorElNotif) && <ClickAwayListener onClickAway={toggleNotification}>
    //                     <Popper open={Boolean(anchorElNotif)} anchorEl={anchorElNotif} style={popperContainerStyle}>
    //                         {['Test1', 'Test2', 'Test3'].map((notification, index) => (
    //                             <div key={index}>
    //                                 <h4>Notification</h4>
    //                                 <p>{notification}</p>
    //                             </div>
    //                         ))}
    //                         <ReusableButton text='View all notifications' bgColor='#F16774' iconType='notification' fontWeight={600} />
    //                     </Popper>
    //                 </ClickAwayListener>}
    //                 <IconButton onClick={toggleUser} style={notificationIconButtonStyle}>
    //                     <AccountCircleIcon />
    //                     <p style={usernameStyle}>{user.username}</p>
    //                     {Boolean(anchorElUser) ? <ArrowDropUpIcon style={arrowIconStyle} /> : <ArrowDropDownIcon style={arrowIconStyle} />}
    //                 </IconButton>
    //                 {Boolean(anchorElUser) && <ClickAwayListener onClickAway={toggleUser}>
    //                     <Popper open={Boolean(anchorElUser)} anchorEl={anchorElUser} style={popperContainerStyle}>
    //                         <div style={centerStyle}>
    //                             <AccountCircleIcon style={accountCircleIconStyle} />
    //                             <NunitoText value={user.username} fontSize={22} fontWeight={700} />
    //                             <NunitoText value={roles.find(r => r.id === user.role_id).name} fontWeight={500} marginTop={10} />
    //                         </div>
    //                         <div style={buttonWrapperStyle}>
    //                             <ReusableButton text='Home' bgColor='#5E75C3' width={100} height={36} iconType='home'
    //                                 to={isTeacher() ? '/participants' : '/schools'} marginRight={10} />
    //                             <ReusableButton text='Profile' bgColor='#5E75C3' width={100} height={36} iconType='profile'
    //                                 to='/profile' marginLeft={10} />
    //                         </div>
    //                         <div style={buttonWrapperStyle}>
    //                             <ReusableButton text='Log Out' bgColor='#E83042' width={160} height={36}
    //                                 iconType='logout' fontWeight={600} onClick={logoutUser} />
    //                         </div>
    //                     </Popper>
    //                 </ClickAwayListener>}
    //             </Grid>
    //         </Toolbar>
    //     </AppBar>
    // )
}