import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  getCollections,
  getCompetitions,
  getDifficultyGroup,
  getTags,
} from "../functions/getData";
import { showWarningSwal } from "../functions/alert";
import { populateCompetition, deepCopy } from "../functions/edit";
import { isAdmin } from "../functions/checkrole";
import Competition from "../components/EditCompetition/Competition";
import CompetitionForPartner from "../components/EditCompetition/CompetitionForPartner";
import NunitoText from "../components/general/NunitoText";
import ReusableButton from "../components/general/ReusableButton";
import Loader from "../components/general/Loader";
import NoData from "../components/general/NoData";
import { makeSureIsArray } from "../functions/general";
export default function EditCompetition() {
  const [loading, setLoading] = useState(true);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [original, setOriginal] = useState();
  const [competition, setCompetition] = useState();
  const competitionOptions = [];
  const [collectionOptions, setCollectionOptions] = useState([]);
  // const [competitionOptions, setCompetitionOptions] = useState([])
  const [tagOptions, setTagOptions] = useState([])
  const [difficultyGroupOptions, setDifficultyGroupOptions] = useState([]);
  document.title = "Edit Competition";
  let location = useLocation();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (location.search.slice(0, 4) === "?id=") {
      getCompetitions(`/${location.search.slice(4)}`, signal)
        .then((c) => {
          //   console.log(makeSureIsArray(c).filter(el=>{
          //      console.log(el)
          //    return el.id === Number(location.search.slice(4))
          // })[0])
          let foundCompetition = populateCompetition(c);
          getTags('',signal).then(tag=>{
            setTagOptions(tag.map(t => ({ id: t.filter_id, name: t.filter_name })))
          })
          setOriginal(deepCopy(foundCompetition));
          setCompetition(foundCompetition);
          if (isAdmin()) {
            // getCollections("?status=active&limits=200", signal)
            getCollections("?get_filter=onlynameandid", signal)
              .then((c) => {
                  setCollectionOptions(c);
              })
              .catch((e) => console.log(e));
            getDifficultyGroup("?status=active&limits=100", signal)
              .then((d) => {
                setDifficultyGroupOptions(
                  d.TaskDifficultyGroupSLists.data.map((d) => ({
                    id: d.id,
                    name: d.name,
                  }))
                );
              })
              .catch((e) => console.log(e));
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          if (!signal.aborted) {
            setLoading(false);
            setFirstLoaded(true);
          }
        });
    } else {
      showWarningSwal("Wrong query");
      if (!signal.aborted) {
        setLoading(false);
        setFirstLoaded(true);
      }
    }
    return () => controller.abort();
  }, [location.search]);
  // console.log(competition, "in edit");
  return (
    <Box className="wrapperBox">
      <div className="firstRowContainer dashboardAndSelfBtnDiv">
        <ReusableButton
          text="Dashboard"
          fontSize={14}
          bgColor="#F16774"
          height={36}
          width={125}
          br={18}
          to="/dashboard"
          iconType="home"
        />
        <ChevronRightIcon />
        <ReusableButton
          text="Competitions"
          fontSize={14}
          bgColor="#F16774"
          height={36}
          br={18}
          to="/competitions"
        />
      </div>
      <div className="formContainer">
        <NunitoText
          value="Edit Competition"
          fontSize={40}
          fontWeight={700}
          italic
          color="#144A94"
          align="left"
        />
        {loading ? (
          <Loader height={600} />
        ) : competition ? (
          isAdmin() ? (
            <Competition
            tagOptions={tagOptions}
              original={original}
              setOriginal={setOriginal}
              competition={competition}
              setCompetition={setCompetition}
              difficultyGroupOptions={difficultyGroupOptions}
              competitionOptions={competitionOptions}
              collectionOptions={collectionOptions}
            />
          ) : (
            <CompetitionForPartner
              original={original}
              setOriginal={setOriginal}
              competition={competition}
              setCompetition={setCompetition}
            />
          )
        ) : (
          firstLoaded && <NoData height={600} />
        )}
      </div>
    </Box>
  );
}
