import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
// import { useSelector } from 'react-redux'
import {
    makeSureIsArray, makeOptions, resetAll,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getDifficultyGroup } from '../functions/getData'
import { deleteDifficultyGroup } from '../functions/deleteData'
import { showNotification } from '../functions/snackbar'
import AddDifficultyGroupModal from '../components/DifficultyGroup/AddDifficultyGroupModal'
import EditDifficultyGroupModal from '../components/DifficultyGroup/EditDifficultyGroupModal'
import DeleteModal from '../components/modal/DeleteModal'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
const difficultyGroupHeader = [
    { id: 'name', label: 'Name', },
    { id: 'status', label: 'Status' },
    { id: 'difficulty', label: 'No. of Difficulty' },
    { id: 'created_at', label: 'Created By' },
    { id: 'updated_at', label: 'Updated By' }
]
const initialParams = '?limits=10'
export default function DifficultyGroup() {
    document.title = 'Difficulty Group'
    const isMounted = useRef(false)
    const [header, setHeader] = useState(difficultyGroupHeader)
    const [difficultyGroupTable, setDifficultyGroupTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Created By", state: true, key: 'created_at' },
        { label: 'Updated By', state: true, key: 'updated_at' },
        { label: "Status", state: true, key: 'status' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddDifficultyGroup, setOnAddDifficultyGroup] = useState(false)
    const [onEdit, setOnEdit] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setId)
    const cancelEdit = () => cancelAction(setOnEdit, setId)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const afterFunction = (cancel, data) => {
        if ([200, 201].includes(data.status)) {
            resetAll(initialParams, filtered, setParams, setSearch, setFiltered)
            getData(initialParams, signal)
            cancel()
            showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
            setSelected([])
            setSelecting([])
        }
    }
    const deleteFunction = () => {
        setLoading(true)
        deleteDifficultyGroup({ id: id === '' ? selected : [id] }).then(d => {
            afterFunction(cancelDelete, d)
        }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
    }
    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        loadData(setLoadingTable, setFirstLoaded, getDifficultyGroup, params, setDifficultyGroupTable, setFilterOptions, false, signal)
    }
    const onChangeViewOptions = value => changeViewOptions(difficultyGroupHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        changeFiltered(value, filtered, search, rowsPerPage, signal, setFiltered, setParams, getData)
    }
    const onChangeSearch = value => {
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    useEffect(() => {
        isMounted.current = true
        getData(initialParams, signal)
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller, signal])
    const onClickMassDelete = () => {
        return selected.length ? setOnDelete(true) : showWarningSwal('Please select at least one difficulty group to mass delete')
    }
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            {Boolean(filterOptions) && <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>}
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Difficulty Group' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div>
            </Grid>
            <Grid className='tableContainer' container>
                <NunitoText value='Difficulty Groups' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                    <div className='endBtnDiv'>
                        <ReusableButton text='Add Difficulty Group' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => setOnAddDifficultyGroup(true)} iconType='add' />
                        <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46}
                            marginRight={20} onClick={() => onClickMassDelete()} iconType='delete' />
                    </div>
                </Grid>
                <NunitoText value='Search only in "Name" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loadingTable && <Loader height={600} />}
                {!loadingTable && difficultyGroupTable && makeSureIsArray(difficultyGroupTable.data).length && <HeavyTable
                    headers={header} list={difficultyGroupTable} title='difficulty' rowsPerPage={rowsPerPage}
                    setOnDelete={setOnDelete} onChangePage={onChangePage} fixed={['name']}
                    selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                    setRowsPerPage={setRowsPerPage} setId={setId} setOnEdit={setOnEdit}
                />}
                {!loadingTable && firstLoaded && difficultyGroupTable && !makeSureIsArray(difficultyGroupTable.data).length && <NoData height={600} />}
            </Grid>
            <Modal open={onAddDifficultyGroup} onClose={() => setOnAddDifficultyGroup(false)}>
                <>
                    <AddDifficultyGroupModal setLoading={setLoading} setOnAddDifficultyGroup={setOnAddDifficultyGroup}
                        afterAddingDifficultyGroup={afterFunction} />
                </>
            </Modal>
            <Modal open={onEdit} onClose={() => cancelEdit()}>
                <>
                    {difficultyGroupTable && <EditDifficultyGroupModal setLoading={setLoading} table={difficultyGroupTable} id={id}
                        afterEditingDifficultyGroup={afterFunction} cancelEdit={cancelEdit} />}
                </>
            </Modal>
            {/* {JSON.stringify({ params, loading, firstLoaded, selecting, onDelete, header, difficultyGroupTable })} */}
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <>
                    {Boolean(difficultyGroupTable) && <DeleteModal table={difficultyGroupTable} id={id} selected={selected}
                        deleteFunction={deleteFunction} cancelDelete={cancelDelete} />}
                </>
            </Modal>
        </Box >
    )
}