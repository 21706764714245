import React, { useState } from 'react'
import { IconButton, Grid, Box, TextField, Paper } from '@mui/material'
import img from '../assets/images/SignIn.png'
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import { useDispatch } from 'react-redux'
import { userLogin, resetTask, resetCollection, resetCompetition } from '../actions'
import { login } from '../functions/httpMethods'
import { isEmpty, warningMessage } from '../functions/alert'
import { showNotification } from '../functions/snackbar'
import { useSnackbar } from 'notistack'
const textFieldStyle = { backgroundColor: '#FFF', borderRadius: 12, border: '1px solid', width: '100%' }
const inputStyle = { fontSize: 22 }
export default function SignIn() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [wrongLogin, setWrongLogin] = useState(false)
    document.title = 'Sign In'
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    //Login function
    const onSubmit = () => {
        if (isEmpty([username, password])) {
            return warningMessage([{ name: 'Username', state: username }, { name: 'Password', state: password }])
        }
        login({ username, password }).then(d => {
            if (d.status !== 200) {
                showNotification('error', d.message, enqueueSnackbar, closeSnackbar)
                setWrongLogin(true)
                return
            }
            dispatch(userLogin(true, username, d.role_id, d.user_id, d.country_id, d.school_id, d.parent_id, d.private, d.token))
            dispatch(resetTask())
            dispatch(resetCollection())
            dispatch(resetCompetition())
        }).catch(e => console.log(e))
      
        
    }
    const handleUsernameChange = e => setUsername(e.target.value)
    const handlePasswordChange = e => setPassword(e.target.value)
    const onPressEnter = e => e.key === 'Enter' && onSubmit()
    const textFieldAttributes = {
        variant: "filled",
        onKeyDown: onPressEnter,
        fullWidth: true
    }
    return (
        <Box style={{ flexGrow: 1, minHeight: '95vh' }}>
            <Grid container style={{ height: '100%' }}>
                <Grid item md={6.5} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    <img src={img} alt='Logo' width='100%' height='auto' />
                </Grid>
                <Grid item xs={12} sm={12} md={5.5} component={Paper}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid container style={{ paddingInline: 120 }}>
                            <NunitoText value='LOGIN' fontSize={50} fontWeight={600} borderBottom='3px solid' />
                            <Grid container alignItems="center" style={{ marginTop: 60 }}>
                                <NunitoText value='Username or Email' fontSize={28} fontWeight={600} italic />
                                <TextField {...textFieldAttributes} placeholder='Enter username or email' onChange={handleUsernameChange}
                                    InputProps={{
                                        disableUnderline: true,
                                        hiddenLabel: true,
                                        style: textFieldStyle,
                                    }}
                                    inputProps={{ style: inputStyle }}
                                />
                            </Grid>
                            <Grid container alignItems="center" style={{ marginTop: 40 }}>
                                <NunitoText value='Password' fontSize={28} fontWeight={600} italic />
                                <TextField {...textFieldAttributes} placeholder='Enter password' onChange={handlePasswordChange}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        disableUnderline: true,
                                        hiddenLabel: true,
                                        style: textFieldStyle,
                                        endAdornment:
                                            <IconButton style={{ color: '#000' }} onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                    }}
                                    inputProps={{ style: inputStyle }}
                                />
                            </Grid>
                            <Grid container alignItems='center' justifyContent={wrongLogin ? 'space-between' : 'flex-end'}>
                                {wrongLogin && <NunitoText value='Invalid Credentials' fontSize={16} fontWeight={700} color='red' />}
                                {/* <Link to='/forgotpassword' style={{ color: '#29A5EC' }}>
                                    Forgot password?
                                </Link> */}
                            </Grid>
                            <ReusableButton text='Login' fontSize={32} bgColor='#F16774' height={70} width='100%' br={16}
                                marginTop={60} onClick={() => onSubmit()} />
                        </Grid>
                        {/* <div style={{ marginTop: 80, color: '#868484', fontSize: 20, textAlign: 'center' }}>
                            <Link style={{ color: '#29A5EC' }}>Sign Up</Link> here to begin your epic journey with us!
                        </div> */}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}