import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import ApproveModal from '../components/modal/ApproveModal'
import { useSelector } from 'react-redux'
import {
    makeSureIsArray, makeOptions, checkIfAllSelectedRowsMatchStatus,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getCollections, getCompCollection } from '../functions/getData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import ManagePermission from '../components/Collections/ManagePermissionsModal'
import { deleteCollection } from '../functions/deleteData'
import Widget from '../components/general/Widget'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
const collectionHeader = [
    { id: 'identifier', label: 'Collection Identifier' },
    { id: 'name', label: 'Collection Name' },
    { id: 'status', label: 'Status' },
    { id: 'description', label: 'Description' },
    { id: 'tags', label: 'Tags' },
    { id: 'grade_difficulty', label: 'Recommended Difficulty' },
    { id: 'sections', label: 'No. of section' },
    // { id: 'noOfQuestions', label: 'No. of questions' },
    { id: 'initial_points', label: 'Initial Points' },
    { id: 'time_to_solve', label: 'Time to solve' },
    { id: 'competitions', label: 'Competition' },
    // { id: 'permission', label: 'Permission to Verify' },
    { id: 'created_by', label: 'Created By' },
    { id: 'last_modified_by', label: 'Last Modified By' },
    // { id: 'lastVerifiedBy', label: 'Last Verified By' },
]
const initialParams = '?'
export default function CompCollections() {
    document.title = 'Collection'
    const isMounted = useRef(false)
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState(collectionHeader)
    const [collectionTable, setCollectionTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "Tags", state: [], key: 'tag_id', header: 'tags' },
        { label: 'Competition', state: '', key: 'competition_id', header: 'competition' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [viewOptions, setViewOptions] = useState([
        { label: "Tags", state: true, key: 'private' },
        { label: "Recommended Difficulty", state: true, key: 'address' },
        { label: "No. of Sections", state: true, key: 'postal' },
        { label: "No. of Questions", state: true, key: 'province' },
        { label: "Initial Points", state: true, key: 'phone' },
        { label: "Time to Solve", state: true, key: 'status' },
        { label: "Competition", state: true, key: 'created_by_username' },
        { label: 'Permission to Verify', state: true, key: 'approved_by_username' },
        { label: 'Created By', state: true, key: 'rejected_by_username' },
        { label: 'Last Modified By', state: true, key: 'modified_by_username' },
        { label: 'Last Verified By', state: true, key: 'reject_reason' },
        { label: "Status", state: true, key: 'status' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [filterOptions, setFilterOptions] = useState()
    const [compName , setCompName] = useState('')
    const [compId , setCompId] = useState()
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
  
    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        loadData(setLoadingTable, setFirstLoaded, getCollections, params, setCollectionTable, setFilterOptions, false, signal)
    }
    const onChangeViewOptions = value => changeViewOptions(collectionHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
   
    const onChangeSearch = value => {
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData)
    }
    const handleView = () => controlView(viewing, setViewing, setFiltering)

    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    let location = useLocation()
    useEffect(() => {
        isMounted.current = true
        getCompCollection(location.search.slice(4)).then(res=>{
            console.log(res)
            setCompName(res.competition_name)
            let data = []
            res.competition_data&&res.competition_data.map(el=>{
                let sampleobj = el.collection
                sampleobj['collection_verified'] = el.collection_verified
                sampleobj['difficulty_and_points_verified'] = el.difficulty_and_points_verified
                sampleobj['level_id'] = el.level_id
                sampleobj['round_id'] = el.round_id
                sampleobj['competition_id'] = res.competition_id
                data.push(sampleobj)
            })
            console.log(data)
            const tabledata = {
                current_page: 1,
                data:data,
                first_page_url: "https://api.dev.simccms.org/api/collection?page=1",
                from: 1,
                last_page: 10,
                last_page_url: "https://api.dev.simccms.org/api/collection?page=10",
                links:[],
                next_page_url: "https://api.dev.simccms.org/api/collection?page=2",
                path: "https://api.dev.simccms.org/api/collection",
                per_page: 10,
                prev_page_url: null,
                to: 10,
                total: 92
              }
              setCollectionTable(tabledata)
        })
       
        // getData(initialParams, signal)
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller, signal])
   
    console.log('data' , collectionTable)
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Collections' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
               
                <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                </div>
            </Grid>
         
            <Grid className='tableContainer' container>
                <NunitoText value='Competition Collections' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container style={{padding:'24px'}}>
                    <Widget title='Competition' content={compName} marginRight={24}   />
                </Grid>
                <Grid className='searchAndBtnContainer' container alignItems="flex-start" flexDirection={'column'}  justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                         
                    <NunitoText value='Search only in "Name"'
                        fontSize={20} fontWeight={400} italic color='#144A94' />
                </Grid>
                {loadingTable && <Loader height={600} />}
                {!loadingTable && collectionTable && makeSureIsArray(collectionTable.data).length &&
                    <HeavyTable headers={header} list={collectionTable} title='compCollection' rowsPerPage={rowsPerPage} 
                        fixed={['name', 'identifier']}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                        setId={setId} />}
                {!loadingTable && firstLoaded && collectionTable && !makeSureIsArray(collectionTable.data).length && <NoData height={600} />}
            </Grid>    
        </Box >
    )
}