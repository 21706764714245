import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import { addGroup, addMarkingPreparation } from '../../functions/postData'
import { showWarningSwal } from '../../functions/alert'
import CloseIcon from '@mui/icons-material/Close'
import NunitoText from '../general/NunitoText'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import ReusableTextField from '../general/ReusableTextField'
import { getMoreInfo } from '../../functions/getData'
import { gradeOptions } from '../../functions/general'
import { showNotification } from '../../functions/snackbar'
import { useSnackbar } from 'notistack'
const boxStyle = { width: 1200, minHeight: 400, maxHeight: '90%' , }
const closeIconDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -42 }
const closeIconStyle = { fontSize: 36, cursor: 'pointer' }
const fieldDivStyle = { display: 'flex', justifyContent: 'space-between', width:'80%' , margin:'0 auto', alignItems:'center' }
const buttonContainerStyle = { marginBlock: 20 }
export default function AddLevelModal({ setOnAddLevel, levels, setLevels, availableCountries, levelOptions,id,
   setLoading, cancelAddLevel, afterAddingLevel }) {
   const isMounted = useRef(false)
   const [level, setLevel] = useState('')
   const [data, setData] = useState(undefined)
   const [countries, setCountries] = useState([])
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()

   const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
   
   
 
   useEffect(() => {
    console.log('change in countres')
      isMounted.current = true
      if(countries.length>0){
      getMoreInfo(id ,{countries:countries} , signal ).then(res=>{
        setData(res)
        console.log(res)
			if(res.status!==200){
				setData(undefined)
			}
	
      }).catch((err)=>{
			
      })
    }else{
      setData(undefined)
    }
      
   }, [controller , countries])
   const getGradeName = (id)=>{
    const grade= gradeOptions.filter((el)=>{
      return el.id===id
    })
    return grade[0].name
     }
     const mapdataToGrade = (grade)=>{
    let obj = data.data
    let arr = []
    
    for(const prob in obj){
      console.log(obj[prob][grade])
      arr.push(obj[prob][grade])
    }
    return arr
     }
     const submit = ()=>{
      let countreisobj = {}
      countries.map((el,index)=>{
        countreisobj[index] = el
      })
    console.log(countreisobj)
      let payload = {
        name:level,
        countries:countreisobj
      }
      if(countries.length>0 && level!==''){
        console.log(payload)
      addGroup(id , payload).then(res=>{
        showNotification('success', res.message, enqueueSnackbar, closeSnackbar)
        setOnAddLevel(false)
window.location.reload()      })}else{
        showWarningSwal('select atleast one country and add a group name')
      }
     }
     let total1 = 0
     let total2 =0
     let total3 = 0;
   return (
      <Box className='popUpModal horizontalScrollable' style={boxStyle}>
         <div>
            <NunitoText value='Add Group' fontSize={30} fontWeight={600} marginTop={20} />
            <div style={closeIconDivStyle}>
               <CloseIcon style={closeIconStyle} onClick={() => setOnAddLevel(false)} />
            </div>
         </div>
       
         <div style={fieldDivStyle}>
            <ReusableTextField placeholder={'Name'}  type='withLabel' fullWidth width={'60%'} state={level} setState={setLevel}/>
            <ReusableAutocompleteWithID type='withLabel' width={400} bgColor='#FFF' borderColor='#707070'
               state={countries} setState={setCountries} placeholder='Countries*' multiple
             
               options={availableCountries.map(c => ({ id: c.id, option: c.name }))} required />
         </div>
         <p style={{marginBottom:'0px' , textAlign:'left' , marginLeft:'5%'}}><span style={{fontWeight:'bolder'}}>Appendix</span>: Total participants / <span style={{color:'green'}}>Total participants with answers uploaded</span> / <span style={{color:'red'}}>absentees</span></p>
         {data!==undefined&&<table className='table'>
	<tr>
		<th>Country/Grade</th>
		{data.countries.map(el=>{
			return	<th>{el.name}</th>
		})}
		<th>Total</th>	
	</tr>
		{data.grades.sort(function(a, b){return a - b}).map(el=>{
			const grade = data.data[el]
			const totalData = grade['total']
			
			return <><tr><th>{getGradeName(el)}</th>
			{
				data.countries.map((elem)=>{
					const grade = data.data[el]
					const countrydata = grade[elem.name]
					console.log(countrydata)
					return countrydata?<th>{<span>{countrydata.total_participants!==undefined?countrydata.total_participants:'0'}</span>} \ {<span style={{color:'green'}}>{countrydata.total_participants_with_answers!==undefined?countrydata.total_participants_with_answers:'0'}</span>} \ {<span style={{color:'red'}}>{countrydata.absentees!==undefined?countrydata.absentees:'0'}</span>}</th>:<th>0 \ 0 \ 0</th>
				})
			}
			{totalData?<th>{totalData.total_participants!==undefined?totalData.total_participants:'0'} \ {<span style={{color:'green'}}>{totalData.total_participants_with_answers!==undefined?totalData.total_participants_with_answers:'0'}</span>} \ {<span style={{color:'red'}}>{totalData.absentees!==undefined?totalData.absentees:'0'}</span>}</th>:<th>0/0/0</th>}
			</tr></>
		})}
	<tr><th>Total</th>
	{data.countries.map(el=>{
		const totalData = data.data.total[el.name]
		total1+=totalData.total_participants
		total2+=totalData.total_participants_with_answers
		total3+=totalData.absentees
		return 	totalData?<th>{totalData.total_participants!==undefined?totalData.total_participants:'0'} \ {<span style={{color:'green'}}>{totalData.total_participants_with_answers!==undefined?totalData.total_participants_with_answers:'0'}</span>} \ {<span style={{color:'red'}}>{totalData.absentees!==undefined?totalData.absentees:'0'}</span>}</th>:<th>0 \ 0 \ 0</th>
	})}
	<th>{total1} \ {<span style={{color:'green'}}>{total2}</span>} \ {<span style={{color:'red'}}>{total3}</span>}</th>
	</tr>	
</table>}
         <Grid container justifyContent='flex-end' style={buttonContainerStyle}>
            <ReusableButton text='Save' fontSize={15} bgColor='#5E75C3' height={46}
               onClick={() => submit()} iconType='save' />
         </Grid>
      </Box>
   )
}