import React, { useState, useEffect, useRef } from 'react'
import { Box, Grid, Tabs, Tab } from '@mui/material'
import { showWarningSwal, showInfoSwal } from '../../functions/alert'
import { addCompetitionRoundAward } from '../../functions/postData'
import CloseIcon from '@mui/icons-material/Close'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import ReusableTextField from '../general/ReusableTextField'
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
const formContainerStyle = { paddingInline: 46 }
function EachForm({ forms, setForms, count, round }) {
   const { label, points, minPoints, percentage } = forms[count]
   const updateForm = (identifier, value) => {
      let newForms = [...forms]
      newForms[count][identifier] = value
      setForms(newForms)
   }
   const { award_type, assign_award_points } = round
   return (
      <div style={formContainerStyle}>
         <Grid container>
            {assign_award_points ? <Grid item xs={5.8}>
               <ReusableTextField type='withLabel' placeholder='Label*' state={label} setState={updateForm}
                  target='label' fullWidth required marginTop={20} />
            </Grid> : <ReusableTextField type='withLabel' placeholder='Label*' state={label} setState={updateForm}
               target='label' fullWidth required marginTop={20} />}
            {assign_award_points && <Grid item xs={0.4} />}
            {assign_award_points && <Grid item xs={5.8}>
               <ReusableTextField type='withLabel' placeholder='Points*' state={points} setState={updateForm}
                  target='points' fullWidth required number marginTop={20} />
            </Grid>}
         </Grid>
         <Grid container>
            {!award_type ? <Grid item xs={5.8}>
               <ReusableTextField type='withLabel' placeholder='Minimum Points*' state={minPoints} setState={updateForm}
                  target='minPoints' fullWidth required number marginTop={20} />
            </Grid> : <ReusableTextField type='withLabel' placeholder='Minimum Points*' state={minPoints} setState={updateForm}
               target='minPoints' fullWidth required number marginTop={20} />}
            {!award_type && <Grid item xs={0.4} />}
            {!award_type && <Grid item xs={5.8}>
               <ReusableTextField type='withLabel' placeholder='Percentage*' state={percentage} setState={updateForm}
                  target='percentage' fullWidth required number marginTop={20} />
            </Grid>}
         </Grid>
         <Grid container justifyContent='flex-end'>
            <NunitoText value={`${count + 1}`} fontSize={20} />
         </Grid>
      </div>
   )
}
const boxStyle = { width: 1500, maxHeight: '90%' }
const addAwardButtonDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -45, marginRight: 20 }
const divStyle = {
   borderRadius: 10, margin: '20px 20px 0 20px',
   boxShadow: '0px 1px 6px rgb(0,0,0,0.2), 0 1px 6px rgb(0,0,0,0.2)'
}
const tabPropsStyle = { height: 0 }
const tabStyle = (value, i) => ({
   color: value !== i && '#707070',
   backgroundColor: value !== i && '#E7E6E6',
   border: value === i && '1px solid #F0F0F0',
   boxShadow: value === i && '0px 1px 6px rgb(0,0,0,0.2), 0px 1px 6px rgb(0,0,0,0.2)',
   borderRadius: '10px 10px 0 0',
   textTransform: 'none',
   marginRight: 10, height: 40
})
const iconStyle = { position: 'relative', left: 12, height: 18, width: 18, color: '#707070' }
const buttonsContainerStyle = { paddingInline: 60, marginBlock: 30 }
export default function AddRoundAwardsModal({ setLoading, round, cancelAddRoundAward, afterAddingRoundAward }) {
   const isMounted = useRef(false)
   const [forms, setForms] = useState([{
      id: 1, label: '', points: '', minPoints: '', percentage: ''
   }])
   const { id, name, assign_award_points, award_type } = round
   const [value, setValue] = useState(0)
   const addForm = () => {
      if (forms.length >= 12) return showInfoSwal('Maximum number of tab is 12')
      setForms([...forms, {
         id: forms.map(f => f.id).reduce((a, b) => Math.max(a, b), 0) + 1,
         label: '', points: '', minPoints: '', percentage: ''
      }])
      setValue(forms.length)
   }
   const deleteForm = (e, id) => {
      e.stopPropagation()
      let newForms = forms.filter(f => f.id !== id)
      if (!newForms.length) return cancelAddRoundAward()
      let index = forms.findIndex(f => f.id === id)
      if (value === forms.length - 1 || index < value) setValue(value - 1)
      setForms(newForms)
   }
   const handleChange = (e, newValue) => setValue(newValue)
   const onSubmit = () => {
      let payload = { round_id: id, award: [] }
      let msg = ''
      for (let i = 0; i < forms.length; i++) {
         const { label, points, minPoints, percentage } = forms[i]
         let warningMessage = ''
         if (label === '') warningMessage += 'Please fill in award label.<br>'
         if (assign_award_points && points === '') {
            warningMessage += 'Please fill in points if you are assigning points manually for this round.<br>'
         }
         if (minPoints === '') warningMessage += 'Please fill in minimum points for award.<br>'
         if (!award_type && percentage === '') {
            warningMessage += 'Please fill in percentage is you are assigning award by percentage for this round.<br>'
         }
         if (warningMessage) {
            msg += '<div>'
            msg += `<div style="text-align:center; font-weight: 800">Error in Award ${i + 1}</div>`
            msg += `<div style="text-align:left">${warningMessage}</div>`
            msg += '</div>'
         } else {
            let obj = {
               name: label, min_points: minPoints
            }
            if (assign_award_points) obj.award_points = points
            if (!award_type) obj.percentage = percentage
            payload.award.push(obj)
         }
      }
      if (msg) return showWarningSwal(msg)
      console.log(JSON.stringify(payload))
      setLoading(true)
      addCompetitionRoundAward(payload).then(d => {
         isMounted.current && setLoading(false)
         afterAddingRoundAward(cancelAddRoundAward, d)
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
      // setTimeout(() => {
      //    setLoading(false)
      //    afterAddingRoundAward(cancelAddRoundAward, { status: 201, message: 'Successfully added award(s) for round' }, payload.award)
      // }, 1500);
   }
   useEffect(() => {
      isMounted.current = true
      return () => isMounted.current = false
   }, [])
   return (
      <Box className='popUpModal horizontalScrollable' style={boxStyle}>
         <div>
            <NunitoText value={`ADD AWARDS FOR ${name.toUpperCase()}`} fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
            <div style={addAwardButtonDivStyle}>
               <ReusableButton text='Add another award' fontSize={15} height={40} bgColor='#5E75C3'
                  iconType='add' onClick={() => addForm()} allowSpam />
            </div>
         </div>
         <div style={divStyle}>
            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: tabPropsStyle }}
               variant="scrollable" scrollButtons>
               {forms.map((f, i) => <Tab key={f.id} label={<div style={centerFlexStyle}>
                  Award {i + 1}
                  <CloseIcon style={iconStyle} onClick={e => deleteForm(e, f.id)} />
               </div>} style={tabStyle(value, i)} />)}
            </Tabs>
            <EachForm forms={forms} setForms={setForms} count={value} round={round} />
         </div>
         <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
            <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
               onClick={() => cancelAddRoundAward()} />
            <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
               onClick={() => onSubmit()} />
         </Grid>
      </Box>
   )
}